import { memo, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";

import { Icon } from "@/atoms";
import { useCheckboxesContext } from "./useCheckboxesContext";

const Checkbox = ({
	name,
	children,
	required,
	onChange,
	errMsg,
	isSelectAll = false,
}) => {
	const {
		register,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();

	const { checkedState, setCheckedState, childCheckboxes } =
		useCheckboxesContext();
	const [isAllChecked, setIsAllChecked] = useState(false);
	const checkboxValue = watch(name);

	// 監聽子層更動
	useEffect(() => {
		// 如果子層全選
		if (isSelectAll && childCheckboxes.length > 0) {
			const allChecked = childCheckboxes.every(
				(childName) => checkedState[childName],
			);

			// 全選狀態真正改變才更新
			if (allChecked !== isAllChecked) {
				setIsAllChecked(allChecked);
				setValue(name, allChecked);
			}
		}
	}, [checkedState]);

	// 監聽所有更動
	const handleChange = (e, notEven) => {
		const value = e.target.checked;
		if (!notEven) {
			onChange && onChange(value);
		}

		// 如果是全選
		if (isSelectAll && childCheckboxes) {
			childCheckboxes.forEach((childName) => {
				setValue(childName, value);
				setCheckedState((prev) => ({ ...prev, [childName]: value }));
			});
			// 更新全選狀態
			setIsAllChecked(value);
		}

		// 如果是子層
		if (!isSelectAll && setCheckedState) {
			setCheckedState((prev) => ({ ...prev, [name]: value }));
		}
	};

	return (
		<div className="flex flex-col">
			<span className="text-xs text-red-600">{errors[name]?.message}</span>
			<label htmlFor={name} className="inline-flex items-center">
				<div>
					<input
						id={name}
						type="checkbox"
						className="hidden"
						{...register(name, {
							required: {
								value: required,
								message: errMsg,
							},
							onChange: handleChange,
						})}
					/>
					<div
						className={classNames(
							"flex h-5 w-5 items-center justify-center rounded border-2 border-[#00000099]",
							{ "bg-black": checkboxValue },
						)}
					>
						{/* 勾勾icon */}
						{checkboxValue && (
							<Icon
								iconName={"Check"}
								fontSize={22}
								className="text-white"
							></Icon>
						)}
					</div>
				</div>
				{children}
			</label>
		</div>
	);
};

export default memo(Checkbox);
