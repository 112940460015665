import classNames from "classnames";
import { Icon } from "@/atoms";
import { useCounter } from "@/hooks";
import { useEffect } from "react";

/**
 * Counter
 * @param {number} initNum
 * @param {number} limit
 * @returns jsx
 */
const Counter = ({ initNum, limit, onChange }) => {
	const { num, increment, decrement, isLimited } = useCounter(initNum, limit);

	useEffect(() => {
		onChange(num);
	}, [num]);

	return (
		<div className="flex items-center border border-solid border-gray-300">
			<button type="button" className="px-1.5 py-px" onClick={decrement}>
				<Icon
					iconName={"Remove"}
					fontSize={24}
					className={classNames("black", {
						"text-gray-300": num === initNum,
					})}
				></Icon>
			</button>
			<div
				className={classNames("black w-6 text-center", {
					"text-gray-300": isLimited,
				})}
			>
				{num}
			</div>
			<button type="button" className="px-1.5 py-px" onClick={increment}>
				<Icon
					iconName={"Add"}
					fontSize={24}
					className={classNames({
						"text-gray-300": isLimited,
					})}
				></Icon>
			</button>
		</div>
	);
};

export default Counter;
