import { useContext, useLayoutEffect, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { AppTool } from "@/utils/app-tool";
import { api } from "@/utils";
import { LiffNavBtn, LiffListItemBtn } from "@/components";
import { ProfileCardCarousel } from "./ProfileCardCarousel";
import { StoreConsultant } from "./StoreConsultant";
import { LiffContext } from "@/utils";
import ReactGA from "react-ga4";
import "./index.sass";
import "swiper/css";
import "swiper/css/pagination";

// img
import HomeBg from "@/assets/img/liff/home/home_bg.png";
import BtnCloss from "@/assets/img/liff/btn-closs.png";

const HomePage = () => {
	const location = useLocation();
	const [popupsImage, setPopupsImage] = useState("");
	const [bannerList, setbannerList] = useState([]);
	const [memberConsultant, setMemberConsultant] = useState(null);
	const { liffActions } = useContext(LiffContext);

	const getMemberConsultant = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api(5).get("/get_crm_member_consultant");
			const member_consultant = res.data;
			setMemberConsultant(member_consultant);
		} catch (error) {
			console.error("Get Member Line Failed", error);
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	const getPopup = async () => {
		const res = await api().get("/get_ad_popup");
		if (res.data && res.data.image) {
			setPopupsImage(res.data.image);
			AppTool.openDialog(".cHomeAdDialog");
		}
	};

	const getBanner = async () => {
		const res = await api().get("/get_ad_banner");
		if (res.data) {
			setbannerList(res.data);
		}
	};

	useLayoutEffect(() => {
		liffActions.setShowFullPageLoader(true);
	}, [location.pathname]);

	useEffect(() => {
		getMemberConsultant();
		getPopup();
		getBanner();
		ReactGA.event("page_home");
	}, []);

	return (
		<>
			<div
				role="presentation"
				className="cHomeAdDialog fixed left-0 top-0 z-[100] h-[110vh] w-[100vw] flex-col items-center justify-center duration-500"
				style={{ display: "none", marginTop: "0" }}
				onClick={() => {
					AppTool.closeDialog(".cHomeAdDialog");
				}}
			>
				<div className="cMask absolute z-[0] size-[100%] bg-[#000] opacity-[0.5]" />
				<div
					role="presentation"
					className="cDialog relative z-[1] mt-[-20vw] w-[80vw] rounded-[1vw] p-[1vw] duration-500"
					style={{ transform: "scale( 0 )" }}
				>
					<img className="w-[100%]" src={popupsImage || ""} />
					<img
						className="absolute right-[1vw] top-[1vw] size-[6vw]"
						src={BtnCloss}
					/>
				</div>
			</div>
			<div className="cHomePage h-screen w-full bg-gray-50">
				<div className="flex w-full flex-col">
					{/* 背景 */}
					<div className="absolute flex h-auto w-full">
						<img
							src={HomeBg}
							alt=""
							className="h-auto w-full object-fill object-top"
							loading="lazy"
						/>
					</div>

					<ProfileCardCarousel />

					<StoreConsultant memberConsultant={memberConsultant} />

					{/* 前往點數歷程及基本資料btn */}
					<div className="flex justify-center p-4 px-0">
						<div className="flex w-full">
							<LiffNavBtn
								iconName="PointList"
								title="點數歷程"
								linkTo="/points"
							></LiffNavBtn>
							<hr className="h-full border-l border-solid border-[#0000001f]" />
							<LiffNavBtn
								iconName="Profile"
								title="基本資料"
								linkTo="/profile"
							></LiffNavBtn>
						</div>
					</div>
					{/*Banner*/}
					<div
						className="mt-4"
						style={{ display: bannerList.length > 0 ? "block" : "none" }}
					>
						<Swiper
							initialSlide={0}
							pagination={true}
							modules={[Autoplay, Pagination]}
							loop={true}
							autoplay={{
								delay: 5000,
								disableOnInteraction: false,
							}}
							className="cSwiper"
						>
							{bannerList.map((row, index) => (
								<SwiperSlide key={index}>
									<img
										src={row.image}
										alt=""
										className="h-auto w-full"
										loading="lazy"
									/>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					{/* list item btn */}
					<div className="mt-4 bg-white p-4 py-2">
						<LiffListItemBtn
							titleIcon="Star"
							title="會員權益"
							linkUrl="https://www.thenorthface.com.tw/page/membership"
						></LiffListItemBtn>
						<hr />
						<LiffListItemBtn
							titleIcon="Store"
							title="門市資訊"
							linkUrl="https://www.thenorthface.com.tw/v2/Shop/StoreList/16525"
						></LiffListItemBtn>
						<hr />
						<LiffListItemBtn
							titleIcon="Mall"
							title="線上商城"
							linkUrl="https://www.thenorthface.com.tw/"
						></LiffListItemBtn>
						<hr />
						<LiffListItemBtn
							titleIcon="About"
							title="關於我們"
							linkUrl="https://www.thenorthface.com.tw/Shop/introduce/16525?t=1&lang=zh-TW"
						></LiffListItemBtn>
					</div>
					<div className="mt-4 h-16 flex-none"></div>
				</div>
			</div>
		</>
	);
};

export default HomePage;
