import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/atoms";
import { useGetUserExchangeList } from "@/hooks";
import { LiffContext } from "@/utils";
import ReactGA from "react-ga4";

const ExchangeDetailPage = () => {
	const [filteredExchangeDetail, setFilteredExchangeDetail] = useState(null);
	const { getUserExchangeList, exchangeData } = useGetUserExchangeList();
	// reducer
	const { liffState } = useContext(LiffContext);
	const { productId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		setFilteredExchangeDetail(
			exchangeData.find((item) => item.product_id === parseInt(productId)),
		);
	}, [exchangeData, productId]);

	useEffect(() => {
		getUserExchangeList();
		ReactGA.event('page_exchange_detail')
	}, []);

	return (
		!liffState.showFullPageLoader && (
			<div className="flex h-full w-full flex-none flex-col items-center bg-gray-100" >
				<div className="w-full bg-white text-black">
					<div className="space-y-2 border-b p-3">
						{/* 寄送狀態 */}
						<div className="flex justify-between">
							<span className="font-medium">{`#${filteredExchangeDetail?.ref_id}`}</span>
						</div>
						{/* 商品資訊 */}
						<div className="flex justify-between gap-4">
							<div className="flex gap-2">
								<div className="h-20 w-20">
									<img
										src={filteredExchangeDetail?.product_images[0].url}
										alt="商品圖"
									/>
								</div>
								<span>{filteredExchangeDetail?.product_name}</span>
							</div>
							<div className="text-sm text-gray-400">{`x ${filteredExchangeDetail?.quantity}`}</div>
						</div>
						{/* 數量 */}
						<div className="text-right font-medium">{`總計：${filteredExchangeDetail?.quantity}`}</div>
					</div>
				</div>
				<div className="mt-4 flex h-80 w-full flex-col justify-between bg-white p-3">
					<div>
						<span className="mb-2 block font-medium">寄送資料</span>
						<p className="flex flex-col text-sm">
							<span>{`收件人：${filteredExchangeDetail?.receive_name}`}</span>
							<span>{`手機號碼：${filteredExchangeDetail?.receive_mobile}`}</span>
							<span>{`收件地址：${filteredExchangeDetail?.receive_address}`}</span>
						</p>
					</div>

					<Button
						type="button"
						text="返回"
						theme="default"
						color="black"
						onClick={() => navigate("/exchange")}
					></Button>
				</div>
			</div>
		)
	);
};

export default ExchangeDetailPage;
