import { memo } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { Icon } from "@/atoms";

/**
 * Select
 * @param {string} id
 * @param {string} name
 * @param {boolean} required
 * @param {string} icon
 * @param {[]} selectOptions
 * @param {string} placeholder
 * @param {void} onChange
 * @param {boolean} isReadOnly
 * @param {boolean} disabled
 * @param {'default' | 'admin'} theme 主題：前台 | 後台
 * @returns jsx
 */
const Select = ({
	id,
	name,
	required,
	icon,
	selectOptions,
	placeholder,
	onChange,
	isReadOnly,
	disabled = false,
	theme = "default",
}) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	return (
		<>
			<select
				name={name}
				id={id}
				className={classNames(
					"w-full appearance-none bg-transparent",
					{
						"border-2 border-customGray p-3.5 leading-tight focus:border-black focus:outline-none":
							theme === "default",
					},
					{
						"h-[46px] rounded-lg bg-white py-3 pl-4 pr-8 text-sm outline outline-1 outline-customGray focus:outline-blue-500 focus:ring-blue-500":
							theme === "admin",
					},
					{
						"text-blackT30": isReadOnly || disabled,
					},
					{ "border-red-600 text-red-600": errors[name] },
				)}
				disabled={disabled}
				{...register(name, {
					required: { value: required },
					onChange: onChange,
				})}
			>
				<option value="" disabled>
					{placeholder}
				</option>
				{selectOptions &&
					selectOptions.map((item, index) => {
						return (
							<option key={index} value={item.key}>
								{item.value}
							</option>
						);
					})}
			</select>
			<div
				className={classNames("absolute right-2.5 top-1/2 -translate-y-1/2", {
					"after:absolute after:bottom-0 after:left-[4px] after:h-3 after:w-3 after:bg-white after:opacity-60":
						isReadOnly,
				})}
			>
				<Icon
					iconName={icon}
					className={classNames(
						{ "text-blackT50": !errors[name] },
						{
							"text-red-600": !!errors[name],
						},
					)}
				></Icon>
			</div>
		</>
	);
};

export default memo(Select);
