import { useContext, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";

import { Button } from "@/atoms";
import { LiffContext } from "@/utils";
import { EXCHANGE_STATUS_CODES } from "@/utils/interface/exchange";

const RedemptionIntro = ({ onClick }) => {
	const [imageItems, setImageItems] = useState([]);
	// reducer
	const { liffState } = useContext(LiffContext);

	const formatNumberWithCommas = (number) => {
		return new Intl.NumberFormat().format(Math.floor(number));
	};

	const getImgUrl = () => {
		if (liffState?.productIntro?.images) {
			const img = liffState.productIntro.images.map((item) => ({
				original: item.url,
				thumbnail: item.url,
			}));
			setImageItems(img);
		}
	};

	useEffect(() => {
		getImgUrl();
	}, [liffState.productIntro]);

	return (
		<div className="mb-[75px] w-full space-y-2 bg-gray-50">
			<div className="bg-white">
				{/* 商品圖 */}
				<ImageGallery
					items={imageItems}
					showNav={false}
					showFullscreenButton={false}
					showPlayButton={false}
					lazyLoad={true}
				/>

				{/* 商品名稱 */}
				<div className="mt-5 px-5 py-2">
					<div className="mb-2 flex justify-between font-medium">
						<span className="text-lg">
							{liffState?.productIntro?.display_name}
						</span>
						<span>{`${formatNumberWithCommas(liffState?.productIntro?.point)}點`}</span>
					</div>
				</div>
			</div>

			<div className="flex flex-col bg-white px-5 py-3">
				{/* 商品簡介 */}
				<span className="mb-2 font-medium">商品簡介</span>
				<span className="text-sm" style={{whiteSpace: 'pre-wrap'}}>{liffState?.productIntro?.content}</span>
				<p className="mt-16 text-sm">
					<span>寄送時間：</span>
					<br />
					<span>
						寄送時間為兌換後10個工作天(不含假日)，若遇連續假期、天氣狀況不佳或交通路中斷等情況，配送時間有可能順延或變動。
					</span>
				</p>
			</div>

			<div className="bg-white px-5 py-3">
				{/* 注意事項 */}
				<span className="mb-3 block font-medium">注意事項</span>
				<ul className="list-disc pl-[40px] text-sm">
					<li>兌換後恕無法修改收件資料，務必於兌換頁面詳細確認收件資料。</li>
					<li>以會員點數兌換之禮品或票券，恕無法退/換貨，亦不能轉售。</li>
					<li>
						以會員點數兌換之禮品或票券，不得以任何理由，要求台灣威富返還已進行禮品或票券兌換之會員點數或額外給予其他補償。
					</li>
					<li>台灣威富保有最終決定取消、修改、暫停兌換活動之權利。</li>
				</ul>
				{/* btn */}
				<div className="my-5 space-y-4">
					<Button
						type="button"
						text="立即兌換"
						theme="default"
						color="black"
						onClick={onClick}
						disabled={
							liffState?.productIntro?.exchange_status !==
								EXCHANGE_STATUS_CODES.ON_SALE ||
							!liffState?.productIntro?.enough_tier
						}
					></Button>
				</div>
			</div>
		</div>
	);
};

export default RedemptionIntro;
