import { Link } from "react-router-dom";
import { Icon } from "@/atoms";

/**
 * LiffNavBtn
 * @param {string} iconName
 * @param {string} title
 * @param {string} linkTo
 * @returns jsx
 */
const LiffNavBtn = ({ iconName, title, linkTo }) => {
	return (
		<Link to={linkTo} className="flex flex-1 flex-col items-center p-2.5">
			<Icon iconName={iconName}></Icon>
			<span className="pt-2">{title}</span>
		</Link>
	);
};

export default LiffNavBtn;
