import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import toast from "react-hot-toast";

import { Button, Form, InputDate, Switch } from "@/atoms";
import { AdminPopup, ImageUploader } from "@/components";
import { LiffContext, api } from "@/utils";
import { useFormatAsLocalTime } from "@/hooks";

dayjs.extend(utc);

const PopupLisDetailPage = () => {
	// 預覽圖片
	const [previewUrls, setPreviewUrls] = useState([]);
	// switch開關
	const [switchState, setSwitchState] = useState({
		is_launch: false,
	});
	const [startDate, setStartDate] = useState(null);
	// 根據路徑判斷是否為编辑頁
	const [isEditPage, setIsEditPage] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [popupListData, setPopupListData] = useState({
		imgFile: [],
		start_date: null,
		end_date: null,
		is_launch: false,
	});

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	const { formatAsLocalTime } = useFormatAsLocalTime();
	const confirmPopup = useRef(null);
	const createEditForm = useRef(null);
	const navigate = useNavigate();
	const { popupId } = useParams();

	// 上傳圖片
	const uploadImage = async () => {
		// 過濾出新上傳的圖片
		const newImageUpload = previewUrls.filter((file) => !file.id);

		if (newImageUpload.length === 0) {
			return previewUrls[0]?.id;
		}

		const formData = new FormData();
		newImageUpload.forEach((file) => {
			formData.append("image", file.file);
		});

		try {
			const res = await api().post("admin/upload_banner_image", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			const { id } = res.data;

			return id;
		} catch (error) {
			console.error("Upload Image Failed", error);
		}
	};

	// 新增
	const addNewPopup = async (data) => {
		try {
			const res = await api().post("admin/add_new_popup", {
				...data,
				show_type: "popup",
			});

			if (res.data === "success") {
				toast.success("新增成功");
				navigate("/admin/displayManagement/popupList");
			}
		} catch (error) {
			console.error("Add New Popup Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	// 進入編輯頁面，獲取單筆資料
	const getPopupData = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/admin/get_banner_by_id", {
				params: {
					id: Number(popupId),
					show_type: "popup",
				},
			});
			const { start_date, end_date, is_launch, images } = res.data;

			console.log(res.data);

			setPopupListData({
				...res.data,
				start_date: formatAsLocalTime(start_date, "YYYY-MM-DDTHH:mm"),
				end_date: formatAsLocalTime(end_date, "YYYY-MM-DDTHH:mm"),
			});

			// 變更switch狀態
			setSwitchState({
				is_launch: !!is_launch,
			});

			// 預覽圖片
			setPreviewUrls(images);
		} catch (error) {
			console.error("Update Popup Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 更新
	const updatePopup = async (data) => {
		try {
			const res = await api().post("admin/update_banner_by_id", {
				...data,
				id: Number(popupId),
				show_type: "popup",
			});

			if (res.data === "success") {
				toast.success("更新成功");
				navigate("/admin/displayManagement/popupList");
			}
		} catch (error) {
			console.error("Update Banner Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	const submitPopupData = async (data) => {
		const { start_date, end_date, is_launch } = data;
		// 轉換UTC時間
		const formattedStartDate = start_date
			? dayjs(start_date).utc().format()
			: null;
		const formattedEndDate = end_date ? dayjs(end_date).utc().format() : null;

		const imgId = await uploadImage();

		const formValues = {
			is_launch: !!is_launch,
			start_date: formattedStartDate,
			end_date: formattedEndDate,
			image_id: imgId,
		};

		// 編輯頁面
		if (isEditPage) {
			await updatePopup(formValues);
		} else {
			// 新增頁面
			await addNewPopup(formValues);
		}
	};

	const onSubmit = () => {
		if (previewUrls.length === 0) {
			return;
		}
		confirmPopup.current.showPopup();
	};

	useEffect(() => {
		const editPage = location.pathname.includes("/edit");
		setIsEditPage(editPage);
	}, [location]);

	useEffect(() => {
		if (isEditPage) {
			getPopupData();
		} else {
			liffActions.setShowFullPageLoader(false);
		}
	}, [isEditPage]);

	return (
		<>
			{!liffState.showFullPageLoader && (
				<>
					<Form
						onSubmit={onSubmit}
						defaultValues={popupListData}
						ref={createEditForm}
					>
						<div className="space-y-6 sm:flex sm:flex-col sm:items-center">
							{/* 上傳圖片 */}
							<div className="sm:w-2/3">
								<ImageUploader
									isMultiple={false}
									name="imgFile"
									setPreviewUrls={setPreviewUrls}
									previewUrls={previewUrls}
								></ImageUploader>
							</div>

							{/* 上線日期 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<span className="mb-2.5 block text-sm font-medium text-[#333333]">
									上線日期
								</span>

								<div className="space-y-3 sm:flex sm:gap-4 sm:space-y-0">
									<Switch
										name="is_launch"
										onChange={(e) =>
											setSwitchState((prevState) => ({
												...prevState,
												is_launch: e.target.checked,
											}))
										}
									></Switch>
									{switchState.is_launch && (
										<>
											{/* 開始日期 */}
											<InputDate
												theme="admin"
												name="start_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												onChange={(e) => setStartDate(e.target.value)}
											></InputDate>

											{/* 結束日期 */}
											<InputDate
												theme="admin"
												name="end_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												minDate={
													startDate || dayjs().format("YYYY-MM-DDTHH:mm")
												}
											></InputDate>
										</>
									)}
								</div>
							</div>

							{/* Button */}
							<div className="flex justify-end gap-5 sm:w-2/3">
								<Button
									type="button"
									theme="admin"
									borderOnly={true}
									widthFull={false}
									text="返回"
									onClick={() => navigate("/admin/displayManagement/popupList")}
								></Button>
								<Button
									type="submit"
									theme="admin"
									widthFull={false}
									text="儲存"
								></Button>
							</div>
						</div>
					</Form>

					<AdminPopup
						ref={confirmPopup}
						onConfirm={async () => {
							// eslint-disable-next-line no-unused-vars
							const data = createEditForm.current.getValues();

							await submitPopupData(data);
						}}
					>
						{isEditPage ? (
							<span className="my-8 block whitespace-pre-wrap text-center">
								{`是否確定要儲存\n此次的更改？`}
							</span>
						) : (
							<span className="my-8 block whitespace-pre-wrap text-center">{`是否確定新增？`}</span>
						)}
					</AdminPopup>
				</>
			)}
		</>
	);
};

export default PopupLisDetailPage;
