import { useState, useRef } from "react";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import { Icon, InputFile } from "@/atoms";
import { Popup } from "@/components";

/**
 * ImageUploader
 * @param {boolean} isMultiple 是否多選
 * @returns jsx
 */
const ImageUploader = ({ isMultiple, name, setPreviewUrls, previewUrls }) => {
	const [message, setMessage] = useState("");
	const messagePopup = useRef(null);

	// 選擇圖片檔案
	const onFileChange = (event) => {
		const files = Array.from(event.target.files);

		const isOverSize = files.some((file) => file.size > 4 * 1024 * 1024);
		if (isOverSize) {
			setMessage("檔案不可大於4MB");
			messagePopup.current.showPopup();
			event.target.value = null;
			return;
		}

		const isNotImage = files.some((file) => !/^image\//.test(file.type));
		if (isNotImage) {
			setMessage("請選擇圖片檔案");
			messagePopup.current.showPopup();
			event.target.value = null;
			return;
		}

		const newPreviewUrls = [];

		// 顯示圖片預覽
		files.forEach((file) => {
			const reader = new FileReader();
			reader.onload = () => {
				if (isMultiple) {
					setPreviewUrls((prevUrls) => [
						...prevUrls,
						{ url: reader.result, file: file },
					]);
				} else {
					newPreviewUrls.splice(0, newPreviewUrls.length, {
						url: reader.result,
						file: file,
					});
					setPreviewUrls(newPreviewUrls);
				}
			};
			reader.readAsDataURL(file);
		});
	};

	// 移除圖片
	const onRemoveImg = (index) => {
		setPreviewUrls(previewUrls.filter((_, i) => i !== index));
	};

	const onDragEnd = (result) => {
		const { source, destination } = result;
		// 如果目的地都沒變就跳出
		if (!destination) {
			return;
		}

		// 拷貝新的 previewUrls
		const newPreviewUrls = [...previewUrls];

		// 從 source.index 剪下被拖曳的元素
		const [removedPreview] = newPreviewUrls.splice(source.index, 1);

		//在 destination.index 位置貼上被拖曳的元素
		newPreviewUrls.splice(destination.index, 0, removedPreview);

		// 設定新的 items
		setPreviewUrls(newPreviewUrls);
	};

	return (
		<>
			<p className="mb-2.5 block text-sm font-medium text-[#333333]">
				商品圖檔（檔案不可大於4MB）
				<span className="text-red-600">*</span>
			</p>
			<div className="flex min-h-[65px] items-center rounded-t-lg border-x border-t border-customGray bg-white px-4 py-3">
				<InputFile
					type="file"
					name={name}
					required={false}
					onChange={onFileChange}
					isMultiple={isMultiple}
				></InputFile>
			</div>

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="drop-id" direction="horizontal">
					{(provided) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							className="flex min-h-[240px] flex-wrap gap-8 overflow-x-auto overflow-y-hidden rounded-b-lg border border-customGray bg-white px-4 py-3"
						>
							{previewUrls.map((previewUrl, index) => (
								<Draggable
									key={index}
									draggableId={`item-${index}`}
									index={index}
									isDragDisabled={!isMultiple} // 不是多選時不能拖曳
								>
									{(provided) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="size-[100px]"
										>
											<img
												src={previewUrl.url}
												alt="Preview"
												className="size-full object-contain"
											/>
											<div
												className="absolute right-[-7px] top-[-7px] flex w-6 cursor-pointer rounded-full bg-gray-100"
												onClick={() => onRemoveImg(index)}
											>
												<Icon iconName={"Close"}></Icon>
											</div>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>

			{previewUrls.length === 0 && (
				<span className="block text-xs text-red-600">請選擇圖片</span>
			)}
			<Popup ref={messagePopup} hasBtn={true}>
				<p className="whitespace-pre-wrap pb-5 pt-10 text-center">{message}</p>
			</Popup>
		</>
	);
};

export default ImageUploader;
