/* eslint-disable no-unused-vars */
import { memo } from "react";
import { useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import classNames from "classnames";

import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";

/**
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.label 顯示名稱
 * @param {string} props.type 類型
 * @param {boolean} props.required 是否必填
 * @param {boolean} props.isReadOnly
 * @param {string} props.placeholder
 * @param {React.ReactNode} props.children
 * @param {string} props.errMsg
 * @param {string} props.value
 * @param {Function} props.onChange
 * @param {string} props.defaultValue
 * @param {boolean} props.requiredMark 是否顯示必填＊
 * @param {'default' | 'admin'} props.theme 主題：前台 | 後台
 * @returns jsx
 */
const PhoneNumberField = ({
	onChange,
	value,
	name,
	children,
	label,
	placeholder,
	isReadOnly,
	required,
	type,
	requiredMark,
	theme = "default",
}) => {
	const {
		formState: { errors },
		control,
	} = useFormContext();

	return (
		<>
			<label
				htmlFor={name}
				className={classNames(
					"mb-2.5 block text-sm font-medium text-[#333333]",
					{
						"text-red-600": !!errors[name]?.message,
					},
				)}
			>
				{label}
				{requiredMark && <span className="text-red-600">*</span>}
			</label>
			<div>
				<PhoneInput
					defaultCountry="TW"
					name={name}
					value={value}
					onChange={onChange}
					type={type}
					className={classNames(
						{
							"w-full border-2 border-customGray bg-transparent p-3.5 text-sm leading-[14px] selection:bg-transparent focus:border-black focus:outline-none":
								theme === "default",
						},
						{
							"block w-full rounded-lg px-4 py-3 text-sm outline outline-1 outline-customGray focus:outline-blue-500 focus:ring-blue-500":
								theme === "admin",
						},
						{
							"border-red-600 focus:border-red-600": !!errors[name]?.message,
							"text-gray-300 focus:border-gray-300": isReadOnly,
						},
					)}
					readOnly={isReadOnly}
					placeholder={placeholder}
					rules={{
						required: required && {
							value: true,
							message: `請填寫${label}`,
						},
						// 驗證手機號碼格式
						validate: (value) =>
							(value && isValidPhoneNumber(value)) || "請檢查手機號碼是否正確",
					}}
					control={control}
				/>
				{children}
				<span className="mt-1 block text-xs text-red-600">
					{errors[name]?.message}
				</span>
			</div>
		</>
	);
};

export default memo(PhoneNumberField);
