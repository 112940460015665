export const EXCHANGE_STATUS_CODES = {
	OFF_SALE: "offSale",
	BEFORE_SALE: "beforeSale",
	AFTER_SALE: "afterSale",
	ON_SALE: "onSale",
	NO_STOCK: "noStock",
	FINISHED: "finished",
	BEFORE_START: "beforeStart",
	AFTER_END: "afterEnd",
};

export const SHIPPING_STATUS = {
	WAITING: "WAITING",
	SHIPPING: "SHIPPING",
};
