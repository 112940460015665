import { useState } from "react";

const useCounter = (initNum, limit) => {
	const [num, setNum] = useState(initNum);

	// 增加
	const increment = () => {
		if (num < limit) {
			setNum(num + 1);
		}
	};

	// 減少
	const decrement = () => {
		// 數字不能小於預設值
		if (num > initNum) {
			setNum(num - 1);
		}
	};

	// 檢查是否超過數量限制
	const isLimited = num >= limit;
	return { num, increment, decrement, isLimited };
};

export default useCounter;
