function AgreementTerms() {
	return (
		<>
			<div>
				<p className="py-2 text-base font-medium leading-relaxed">
					會員身分驗證及服務條款
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">
					一、身分驗證與服務條款
				</p>
				<p className="pb-2 text-sm leading-normal">
					在臺端接受且同意以下服務條款並首次使用 The North Face LINE Business
					Connect
					服務時，根據臺端提供的相關內容與LINE帳號進行連結驗證，以確保臺端的使用安全。
					於臺端通過身分驗證後，即可接收個人化服務通知服務。如於使用 The North
					Face LINE Business Connect
					服務查詢內容有爭議時，將以本公司認定資訊為主。
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">
					1-1.個人化服務通知：
				</p>
				<p className="pb-2 text-sm leading-normal">
					在臺端首次使用並成功完成身分驗證後，即可於 LINE
					中接收臺端專屬的資訊通知，包含臺端與本公司往來之個人化通知訊息以及本公司提供之行銷訊息。
				</p>
				<p className="pb-2 text-sm leading-normal">
					(請注意：本項通知服務為自動發送制，若欲取消本項服務，須於LINE通路執行設定。)
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">
					二、消費者資訊之利用
				</p>
				<p className="pb-2 text-sm leading-normal">
					2-1.本公司僅得於履行The North Face LINE Business Connect
					服務及個人行動電話服務之目的範圍內，蒐集、處理及利用臺端之個人資料。但相關法令另有規定者，不在此限。
				</p>
				<p className="pb-2 text-sm leading-normal">
					2-2.本公司茲依據個人資料保護法及相關法令之規定等，說明蒐集、處理、利用及國際傳輸臺端個人資料之管理方針，以及臺端所享有之權利或服務如下：「個人資料運用告知同意聲明」。
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">
					三、服務之停止與更改
				</p>
				<p className="pb-2 text-sm leading-normal">
					倘因法令變更、主管機關命令或有其他不可歸責於本公司之事由，本公司得於網站首頁公告後，停止變更或終止各項服務內容。
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">
					四、其它未盡事宜悉依本公司相關規定。
				</p>
			</div>
			<div>
				<p className="py-2 text-base font-medium leading-relaxed">
					個人資料運用告知聲明
				</p>
				<p className="pb-2 text-sm leading-normal">
					台灣威富品牌有限公司（以下稱本公司）向臺端蒐集個人資料時，依據個人資料保護法（以下稱個資法）第八條第一項規定，應明確告知臺端下列事項：
				</p>
				<p className="pb-2 text-sm leading-normal">
					一、非公務機關名稱： 台灣威富品牌有限公司。
				</p>
				<p className="pb-2 text-sm leading-normal">
					二、蒐集之目的：因銷售及行銷等相關活動需要，所為之蒐集處理及利用、契約、類似契約或其他法律關係管理之事務、消費者、客戶管理與服務、消費者保護、商業與技術資訊、資（通）訊與資料庫管理、資通安全與管理、調查、統計與研究分析、其他諮詢與顧問服務、其他經營合於營業登記項目或組織章程所定之業務。
				</p>
				<p className="pb-2 text-sm leading-normal">
					三、個人資料之類別：姓名、身分證統一編號、性別、出生年月日、通訊方式及其他詳如相關業務申請書或契約書之內容，並以本公司與客戶往來之相關業務、帳戶或服務及自客戶或第三人處理所實際蒐集之個人資料為準。
				</p>
				<p className="pb-2 text-sm leading-normal">
					四、個人資料利用之期間、地區、對象及方式：
				</p>
				<p className="pb-2 text-sm leading-normal">
					4-1.期間：特定目的存續期間、依相關法令所定或因執行業務所必須之保存期間或依個別契約就資料之保存所定之保存年限（以期限最長者為準）。
				</p>
				<p className="pb-2 text-sm leading-normal">
					4-2.地區：「個人資料利用之對象」其國內及國外所在地。
				</p>
				<p className="pb-2 text-sm leading-normal">
					4-3.對象：本公司（含受本公司委託處理事務之委外機構）、其他業務相關之機構、依法有權機構或金融監理機構、客戶所同意之對象（本公司共同合作、行銷或交互運用客戶資料之公司及本公司合作推廣業務之公司）與本公司合作對象如：LINE
					Plus Corporation。
				</p>
				<p className="pb-2 text-sm leading-normal">
					4-4.方式：符合個人資料保護相關法令以自動化機器或其他非自動化之利用方式。
				</p>
				<p className="pb-2 text-sm leading-normal">
					五、依據個資法第三條規定，臺端就本公司保有臺端之個人資料得行使下列權利：
				</p>
				<p className="pb-2 text-sm leading-normal">
					5-1.除有個資法第十條所規定之例外情形外，得向本公司查詢、請求閱覽或請求製給複製本，惟本公司依個資法第十四條規定得酌收必要成本費用。
				</p>
				<p className="pb-2 text-sm leading-normal">
					5-2.得向本公司請求補充或更正，惟依個資法施行細則第十九條規定，臺端應適當釋明其原因及事實。
				</p>
				<p className="pb-2 text-sm leading-normal">
					5-3.本公司如有違反個資法規定蒐集、處理或利用臺端之個人資料，依個資法第十一條第四項規定，臺端得向本公司請求停止蒐集。
				</p>
				<p className="pb-2 text-sm leading-normal">
					5-4.依個資法第十一條第二項規定，個人資料正確性有爭議者，得向本公司請求停止處理或利用臺端之個人資料。惟依該項但書規定，本公司因執行業務所必須並註明其爭議或經臺端書面同意者，不在此限。
				</p>
				<p className="pb-2 text-sm leading-normal">
					5-5.依個資法第十一條第三項規定，個人資料蒐集之特定目的消失或期限屆滿時，得向本公司請求刪除、停止處理或利用臺端之個人資料。惟依該項但書規定，本公司因執行業務所必須或經臺端書面同意者，不在此限。
				</p>
				<p className="pb-2 text-sm leading-normal">
					六、臺端如欲行使上述個資法第三條規定之各項權利，有關如何行使之方式，得向
				</p>
				<p className="pb-2 text-sm leading-normal">
					(1)本公司客服詢問：02-26532011。
				</p>
				<p className="pb-2 text-sm leading-normal">
					(2)本公司網站查詢： https://www.thenorthface.com.tw/。
				</p>
				<p className="pb-2 text-sm leading-normal">
					七、臺端得自由選擇是否提供相關個人資料及類別，惟臺端所拒絕提供之個人資料及類別，如果是與銷售或行銷業務有關或與前述作業所需之資料，本公司可能無法進行必要之銷售或行銷業務或與前述有關之作業而無法提供臺端相關服務或無法提供較佳之服務。
				</p>
			</div>
			<div>
				<p className="py-2 text-base font-medium leading-relaxed">
					會員條款及個資告知事項
				</p>
				<p className="pb-2 text-sm leading-normal">
					親愛的 The North Face 會員您好：
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">會員權益聲明</p>
				<p className="pb-2 text-sm leading-normal">
					親愛的會員您好，非常感謝您使用本商店服務（以下稱本服務），本公司將根據以下之會員服務條款(以下簡稱本條款)，提供會員便利的交易。為了保障您的權益，請務必詳讀本條款，在您註冊成為本商店會員、或開始使用本服務時，即視為您已經閱讀、瞭解並同意接受本條款及「隱私權保護政策」之所有內容，並視為您已確認詳閱並同意個人資料保護告知暨同意事項。
				</p>
				<p className="pb-2 text-sm leading-normal">
					如您不同意本條款之全部或部分內容，請勿註冊，並請立即停止使用本服務。本條款之具體約定內容如後：
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">一、會員資料</p>
				<p className="pb-2 text-sm leading-normal">
					01.
					在您使用本服務之前，必須提供正確個人資料，並加入成為本商店會員，無須支付任何費用；在加入完成後，始得登入本公司網站，使用本服務及參加與本服務相關之活動。如個人資料有異動，請務必即時更新，以保障您的權益。如因會員未正確更新個人資料，致未能收到本公司寄發之會員權益、消費優惠、活動內容等相關資訊，或變更、終止會員權益、消費優惠、活動內容之通知，會員同意在此情形下，視為會員已經收到該等資訊或通知，並不對本公司提出任何主張。
				</p>
				<p className="pb-2 text-sm leading-normal">
					02.
					個人資料之填寫如有重複、錯誤或虛偽不實、故意冒用他人姓名、侵害他人姓名權、公司名稱專用權、商標權或其他智慧財產權、或有違反公序良俗、政府法令、或破壞本公司服務宗旨…等情形，本公司得拒絕或取消註冊，並有權立即暫停或終止會員帳號，取消會員資格，拒絕提供本服務，會員不得向本公司為任何主張，並應自行承擔全部法律責任，若有導致本公司受有任何損失或損害，並應賠償本公司。
				</p>
				<p className="pb-2 text-sm leading-normal">
					03. 隱私權保護聲明：會員之個人資料受到本公司隱私權條款之保護與規範。
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">
					二、服務內容的變更
				</p>
				<p className="pb-2 text-sm leading-normal">
					會員同意本公司得隨時調整、變更、修改或終止本服務及本條款，於本公司公告後生效，不再另行個別通知。
				</p>
				<p className="pb-2 text-sm leading-normal">
					會員因參與本公司活動及使用本公司服務，而與本公司所發生之權利義務關係，均以本條款最新修訂之版本為準。
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">
					三、個人資料保護告知及暨同意事項：
				</p>
				<p className="pb-2 text-sm leading-normal">
					為提供會員最完善的服務，並保護會員個人資料，本商店謹此依個人資料保護法(下稱個資法)規定，告知您如下事項：
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">
					(一)蒐集之目的、個人資料類別、利用期間、地區、對象及方式
				</p>
				<p className="pb-2 text-sm leading-normal">
					為提供會員有關本商店及本公司實體門市各項消費優惠、商品、服務、活動及最新資訊，並有效管理會員資料、進行服務與調查、滿意度及消費統計分析調查(下稱蒐集目的)，本公司將於上開蒐集目的消失前，在完成上開蒐集目的之必要範圍內，蒐集、處理、利用您填載於本商店會員申請之個人資料、消費與交易資料，或日後經您同意而提供之其他個人資料。在上開蒐集目的範圍內，本公司可能會將您全部或部分個人資料，提供予合作廠商。
				</p>
				<p className="pb-2 text-sm leading-normal">
					例：當會員使用本服務進行線上消費，本公司需透過宅配貨運等合作廠商，方能完成貨品或相關贈品之配送，故當會員完成線上交易，即表示會員同意並授權本公司得將會員提供之個人資料(如收件人姓名、配送地址、電話…等)提供予宅配及貨運合作廠商，以利完成貨品或相關贈品之配送。
				</p>
				<p className="py-2 text-sm font-medium leading-relaxed">
					(二)注意事項：
				</p>
				<p className="pb-2 text-sm leading-normal">
					您同意以電子文件或實體簽署作為行使個資法書面同意之方式。如您不同意提供個人資料，或要求刪除或停止蒐集、處理您的個人資料，您瞭解本公司可能因此無法進行網路會員資格審核及相關處理作業，或提供您完善的網路服務，尚請見諒。
				</p>
			</div>
		</>
	);
}

export default AgreementTerms;
