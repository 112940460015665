export const REWARD_ID = {
	FIRST_MGM: 1,
	A2_ONE_TO_ONE: 3,
};

export const REWARD_SLUG = {
	FIRST_MGM: 'A1',
	A2_FILL_IN: 'A2-M1',
	A2_ONE_TO_ONE: 'A2-M2',
	A2_GO_TO_OA_WEB: 'A2-M3',
	A2_MGM: 'A2-M4',
};