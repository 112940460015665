import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import TablePagination from "@mui/material/TablePagination";
import toast from "react-hot-toast";
// import dayjs from "dayjs";

import { Button, Checkbox, Checkboxes, Form, Icon, Input } from "@/atoms";
import { Dropdown, AdminPopup } from "@/components";
import { AppError, LiffContext, api } from "@/utils";
import {
	useCheckboxSelection,
	usePagination,
	useFormatAsLocalTime,
} from "@/hooks";
import { EXCHANGE_STATUS_CODES } from "@/utils/interface/exchange";

const ProductListPage = () => {
	const [showProductList, setShowProductList] = useState([]);
	// table核取方塊子層列表
	const [childCheckboxes, setChildCheckboxes] = useState([]);
	// 現在彈窗的內容
	const [nowPopupContent, setNowPopupContent] = useState(null);
	const [editId, setEditId] = useState(null);
	const [showHiddenProduct, setShowHiddenProduct] = useState(false);

	const navigate = useNavigate();
	// reducer
	const { liffActions } = useContext(LiffContext);
	// checkbox選取
	const { selectedId, onSelectedItem, setSelectedId } =
		useCheckboxSelection(showProductList);
	// 分頁
	const {
		handleChangePage,
		handleChangeRowsPerPage,
		pageSize,
		currentPage,
		setCurrentPage,
		totalItems,
		setTotalItems,
	} = usePagination(5, "prod");

	const { formatAsLocalTime } = useFormatAsLocalTime();
	const confirmPopup = useRef(null);
	const modifyStockPopup = useRef(null);
	const quantityPopup = useRef(null);
	const tableRef = useRef(null);

	// 獲取商品列表
	const getProductList = async () => {
		liffActions.setShowFullPageLoader(true);

		try {
			const res = await api().get("/admin/get_product_list", {
				params: {
					page: currentPage + 1,
					pageSize: pageSize,
					orderBy: "id",
					sortOrder: "desc",
					showHiddenProduct: showHiddenProduct ? 1 : 0,
				},
			});
			const { products, current_page, total } = res.data;

			setShowProductList(products);
			setChildCheckboxes(products.map((item) => `${item.id}`));
			setCurrentPage(current_page - 1);
			setTotalItems(total);
		} catch (error) {
			console.error("Get Product List Failed", error);
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 取得兌換狀態文字
	const getStatusText = (status, isLaunch) => {
		if (!isLaunch) {
			return "草稿";
		}
		switch (status) {
			case EXCHANGE_STATUS_CODES.OFF_SALE:
				return "草稿";
			case EXCHANGE_STATUS_CODES.BEFORE_SALE:
				return "未上線";
			case EXCHANGE_STATUS_CODES.AFTER_SALE:
				return "已結束";
			case EXCHANGE_STATUS_CODES.ON_SALE:
				return "可兌換";
			case EXCHANGE_STATUS_CODES.NO_STOCK:
				return "敬請期待";
			case EXCHANGE_STATUS_CODES.FINISHED:
				return "兌換完畢";
			case EXCHANGE_STATUS_CODES.BEFORE_START:
				return "即將開始";
			case EXCHANGE_STATUS_CODES.AFTER_END:
				return "已結束";
			default:
				return "";
		}
	};

	// 更新商品庫存
	const updateProductStock = async (stock) => {
		try {
			const res = await api().post("/admin/update_product_stock_by_id", {
				id: editId,
				stock,
			});

			if (res.data === "success") {
				modifyStockPopup.current.closePopup();
				toast.success("更新成功");
				getProductList();
			}
		} catch (error) {
			console.error("Update Product Stock Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.STOCK_LESS_THAN_EXCHANGE_COUNT:
						toast.error("庫存數量不可小於已兌換數量");
						break;
					case AppError.NO_FOUND:
					case AppError.INVALID_FORMAT:
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		}
	};

	// 更新商品可兌換數量
	const updateProductQuantity = async (quantity) => {
		try {
			const res = await api().post("admin/update_product_quantity_by_id", {
				id: editId,
				quantity,
			});

			if (res.data === "success") {
				quantityPopup.current.closePopup();
				toast.success("更新成功");
				getProductList();
			}
		} catch (error) {
			console.error("Update Product Quantity Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.QUANTITY_LESS_THAN_EXCHANGE_COUNT:
						toast.error("可兌換數量不可小於已兌換數量");
						break;
					case AppError.NO_FOUND:
					case AppError.INVALID_FORMAT:
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		}
	};

	// 批量更新顯示商品
	const batchProductDisplay = async (is_display, id = null) => {
		try {
			const res = await api().post("admin/batch_product_display", {
				productIdList: id ? [id] : selectedId,
				isDisplay: is_display ? 1 : 0,
			});

			if (res.data === "success") {
				confirmPopup.current.closePopup();
				setSelectedId([]);
				tableRef.current.reset();
				toast.success("更新成功");
				getProductList();
			}
		} catch (error) {
			console.error("Batch Product Display", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_API_LOGIN_TOKEN:
					case AppError.INVALID_FORMAT:
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		}
	};

	// 批量上線商品
	const batchProductLaunch = async () => {
		try {
			const res = await api().post("admin/batch_product_launch", {
				productIdList: selectedId,
			});

			if (res.data === "success") {
				confirmPopup.current.closePopup();
				setSelectedId([]);
				tableRef.current.reset();
				toast.success("更新成功");
				getProductList();
			}
		} catch (error) {
			console.error("Batch Product Launch", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_API_LOGIN_TOKEN:
					case AppError.INVALID_FORMAT:
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		}
	};

	// 批量更新兌換時間為結束
	const batchProductExchangeEnd = async () => {
		try {
			const res = await api().post("admin/batch_product_exchange_end", {
				productIdList: selectedId,
			});

			if (res.data === "success") {
				confirmPopup.current.closePopup();
				setSelectedId([]);
				tableRef.current.reset();
				toast.success("更新成功");
				getProductList();
			}
		} catch (error) {
			console.error("Batch Product Exchange End", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_API_LOGIN_TOKEN:
					case AppError.INVALID_FORMAT:
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		}
	};

	// 批量刪除商品 or 帶入單一商品ID刪除
	const batchDeleteProduct = async (productId = null) => {
		try {
			const res = await api().post("admin/batch_delete_product", {
				productIdList: productId ? [productId] : selectedId,
			});

			if (res.data.length > 0) {
				confirmPopup.current.closePopup();
				setSelectedId([]);
				toast.success("刪除成功");
				getProductList();
			}
		} catch (error) {
			console.error("Batch Product Exchange End", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_API_LOGIN_TOKEN:
					case AppError.INVALID_FORMAT:
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		}
	};

	const batchProductCopy = async () => {
		try {
			const res = await api().post("admin/batch_product_copy", {
				productIdList: selectedId,
			});

			if (res.data.length > 0) {
				confirmPopup.current.closePopup();
				setSelectedId([]);
				tableRef.current.reset();
				toast.success("複製成功");
				getProductList();
			}
		} catch (error) {
			console.error("Batch Product Exchange End", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_API_LOGIN_TOKEN:
					case AppError.INVALID_FORMAT:
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		}
	};

	const tableSubmit = () => {};

	// 庫存總數表單
	const modifyStockSubmit = async (data) => {
		const stock = parseInt(data.stock, 10);
		await updateProductStock(stock);
	};

	// 可兌換數量表單
	const quantityPopupSubmit = async (data) => {
		const quantity = parseInt(data.quantity, 10);

		await updateProductQuantity(quantity);
	};

	useEffect(() => {
		getProductList();
	}, [pageSize, currentPage, showHiddenProduct]);

	return (
		<>
			{!liffActions.showFullPageLoader && (
				<>
					<div className="space-y-6">
						<div className="flex gap-x-3 align-middle">
							<Button
								type="button"
								theme="admin"
								widthFull={false}
								onClick={() => navigate("/admin/productList/add")}
							>
								<Icon iconName={"Add"}></Icon>
								<span>新增商品</span>
							</Button>

							<Button
								type="button"
								theme="admin"
								widthFull={false}
								onClick={() => setShowHiddenProduct(false)}
								disabled={!showHiddenProduct}
							>
								<span>不顯示隱藏商品</span>
							</Button>

							<Button
								type="button"
								theme="admin"
								widthFull={false}
								onClick={() => setShowHiddenProduct(true)}
								disabled={showHiddenProduct}
							>
								<span>顯示隱藏商品</span>
							</Button>
						</div>

						<Form onSubmit={tableSubmit} ref={tableRef}>
							{/* Table */}
							<Checkboxes childCheckboxes={childCheckboxes}>
								<div className="inline-block w-full bg-white align-middle">
									<div className="w-full divide-y divide-gray-100 rounded-lg border">
										<div
											className={classNames("flex h-16 px-4 py-3", {
												"justify-between": selectedId.length > 0,
												"justify-end": selectedId.length === 0,
											})}
										>
											<span className="mr-auto text-sm text-gray-600">
												{"黃色為草稿​"}
												<br />
												{"紅色為提醒新增庫存（庫存數量=0時、兌換數量>0）"}
											</span>
											{selectedId.length > 0 && (
												<span className="self-end text-sm text-blue-600">
													{`已選取${selectedId.length}項商品`}&emsp;
												</span>
											)}
											<div className="flex gap-4">
												{/* 批次調整 */}
												<Dropdown
													text="批次調整"
													isDisabled={selectedId.length === 0}
												>
													<li
														className="cursor-pointer whitespace-nowrap px-4 py-2 hover:rounded-lg hover:bg-gray-100"
														onClick={() => {
															setNowPopupContent({
																text: `是否確定將${selectedId.length}筆資料設定為已出貨？`,
																action: batchProductLaunch,
															});
															confirmPopup.current.showPopup();
														}}
													>
														設定為已上線
													</li>
													<li
														className="cursor-pointer px-4 py-2 hover:rounded-lg hover:bg-gray-100"
														onClick={() => {
															setNowPopupContent({
																text: `是否確定將${selectedId.length}筆資料設定為待出貨？`,
																action: batchProductExchangeEnd,
															});
															confirmPopup.current.showPopup();
														}}
													>
														設定為兌換時間結束
													</li>
												</Dropdown>

												{/* 更多動作 */}
												<Dropdown
													text="更多動作"
													isDisabled={selectedId.length === 0}
												>
													<li
														className="cursor-pointer whitespace-nowrap px-4 py-2 hover:rounded-lg hover:bg-gray-100"
														onClick={() => {
															setNowPopupContent({
																text: `是否確定複製${selectedId.length}個紀錄？`,
																action: batchProductCopy,
															});
															confirmPopup.current.showPopup();
														}}
													>
														複製商品
													</li>
													<li
														className="cursor-pointer whitespace-nowrap px-4 py-2 hover:rounded-lg hover:bg-gray-100"
														onClick={() => {
															setNowPopupContent({
																text: `是否確定將${selectedId.length}筆資料設定為顯示/隱藏？`,
																action: () => batchProductDisplay(false),
															});
															confirmPopup.current.showPopup();
														}}
													>
														設定為隱藏
													</li>
													<li
														className="cursor-pointer whitespace-nowrap px-4 py-2 hover:rounded-lg hover:bg-gray-100"
														onClick={() => {
															setNowPopupContent({
																text: `是否確定將${selectedId.length}筆資料設定為顯示/隱藏？`,
																action: () => batchProductDisplay(true),
															});
															confirmPopup.current.showPopup();
														}}
													>
														設定為顯示
													</li>
													<li
														className="cursor-pointer px-4 py-2 hover:rounded-lg hover:bg-gray-100"
														onClick={() => {
															setNowPopupContent({
																text: `是否確定刪除${selectedId.length}個紀錄？\n一經刪除即永久無法復原`,
																action: batchDeleteProduct,
															});
															confirmPopup.current.showPopup();
														}}
													>
														刪除
													</li>
												</Dropdown>
											</div>
										</div>
										<div className="overflow-x-auto">
											<table className="min-h-[150px] min-w-full divide-y divide-gray-100">
												<thead className="bg-gray-100">
													<tr>
														<th
															scope="col"
															className="sticky left-0 z-[2] bg-gray-100 px-4 py-3"
														>
															<div className="flex items-center whitespace-nowrap">
																<Checkbox
																	required={false}
																	name="selectedAll"
																	isSelectAll={true}
																	onChange={(e) =>
																		onSelectedItem(e, "selectedAll")
																	}
																></Checkbox>
															</div>
														</th>
														<th
															scope="col"
															className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
														>
															商品縮圖
														</th>
														<th
															scope="col"
															className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
														>
															商品名稱
														</th>
														<th
															scope="col"
															className="flex items-center whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
														>
															兌換點數
														</th>
														<th
															scope="col"
															className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
														>
															最低可兌換會員級別
														</th>
														<th
															scope="col"
															className="whitespace-nowrap px-6 py-3 text-center font-medium uppercase text-blackT80"
														>
															可兌換數量/兌換上限
														</th>
														<th
															scope="col"
															className="whitespace-nowrap px-6 py-3 text-center font-medium uppercase text-blackT80"
														>
															庫存/進貨數量
														</th>
														<th
															scope="col"
															className="whitespace-nowrap px-6 py-3 text-center font-medium uppercase text-blackT80"
														>
															會員限量
														</th>
														<th
															scope="col"
															className="whitespace-nowrap px-6 py-3 text-center font-medium uppercase text-blackT80"
														>
															兌換起迄
														</th>
														<th
															scope="col"
															className="whitespace-nowrap px-6 py-3 text-center font-medium uppercase text-blackT80"
														>
															上線時間
														</th>
														<th
															scope="col"
															className="whitespace-nowrap px-6 py-3 text-center font-medium uppercase text-blackT80"
														>
															狀態
														</th>
														<th
															scope="col"
															className="sticky right-0 z-[2] bg-gray-100 px-4 py-3"
														></th>
													</tr>
												</thead>
												<tbody className="divide-y divide-gray-100">
													{showProductList.length > 0 ? (
														showProductList.map((item) => {
															const startDate = formatAsLocalTime(
																item.exchange_start_date,
																"YYYY/MM/DD",
															);
															const endDate = formatAsLocalTime(
																item.exchange_end_date,
																"YYYY/MM/DD",
															);
															const displayDateRange =
																startDate && endDate
																	? `${startDate} ～ ${endDate}`
																	: "未設定";
															// const hasLaunched = dayjs().isAfter(
															// 	dayjs(item.launch_date),
															// );
															return (
																<tr
																	className={classNames(
																		"group hover:bg-gray-50",
																		{
																			"bg-white":
																				item.exchange_count - item.stock !==
																					0 && item.is_launch,
																		},
																		{
																			"bg-red-50 hover:bg-red-50":
																				item.exchange_count - item.stock === 0,
																		},
																		{
																			"bg-yellow-50 hover:bg-yellow-50":
																				!item.is_launch,
																		},
																	)}
																	key={item.id}
																>
																	{/* Checkbox */}
																	<td
																		className={classNames(
																			"sticky left-0 z-[2] px-4 py-3 group-hover:bg-gray-50",
																			{
																				"bg-white":
																					item.exchange_count - item.stock !==
																						0 && item.is_launch,
																			},
																			{
																				"bg-red-50 group-hover:bg-red-50":
																					item.exchange_count - item.stock ===
																					0,
																			},
																			{
																				"bg-yellow-50 group-hover:bg-yellow-50":
																					!item.is_launch,
																			},
																		)}
																	>
																		<div className="flex h-5 items-center">
																			<Checkbox
																				name={`${item.id}`}
																				onChange={(e) =>
																					onSelectedItem(e, item.id)
																				}
																			></Checkbox>
																		</div>
																	</td>
																	{/* 商品縮圖 */}
																	<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																		<div className="size-[100px]">
																			<img
																				src={item.images[0]?.url}
																				className="size-full object-cover"
																			/>
																		</div>
																	</td>
																	{/* 商品名稱 */}
																	<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																		{item.display_name}
																	</td>
																	{/* 兌換點數 */}
																	<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																		{item.point.toLocaleString()}
																	</td>
																	{/* 最低可兌換會員級別 */}
																	<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																		{`${item.min_redeemable_tier?.vip_code} ${item.min_redeemable_tier?.tier_name}`}
																	</td>
																	{/* 兌換剩餘數量 */}
																	<td className="whitespace-nowrap px-6 py-4 text-center text-blackT80">
																		{`${item.quantity - item.exchange_count}/${
																			item.quantity
																		}`}
																		&emsp;
																		{/* 編輯可兌換數量 */}
																		<Icon
																			iconName={"Edit"}
																			fontSize={20}
																			className="cursor-pointer"
																			onClick={() => {
																				setEditId(item.id);
																				quantityPopup.current.showPopup();
																			}}
																		></Icon>
																	</td>
																	{/* 庫存剩餘數量 */}
																	<td className="whitespace-nowrap px-6 py-4 text-center text-blackT80">
																		{`${item.stock - item.exchange_count}/${
																			item.stock
																		}`}
																		&emsp;
																		{/* 編輯庫存量 */}
																		<Icon
																			iconName={"Edit"}
																			fontSize={20}
																			className="cursor-pointer"
																			onClick={() => {
																				setEditId(item.id);
																				modifyStockPopup.current.showPopup();
																			}}
																		></Icon>
																	</td>
																	{/* 會員限量 */}
																	<td className="whitespace-nowrap px-6 py-4 text-center text-blackT80">
																		{item.limited}
																	</td>
																	{/* 兌換起迄 */}
																	<td className="whitespace-nowrap px-6 py-4 text-center text-blackT80">
																		{item.exchange_no_limit
																			? "無限制"
																			: displayDateRange}
																	</td>
																	{/* 上線時間 */}
																	<td className="whitespace-nowrap px-6 py-4 text-center text-blackT80">
																		{item.is_launch &&
																		item.launch_date &&
																		item.unlisting_date
																			? formatAsLocalTime(
																					item.launch_date,
																					"YYYY/MM/DD",
																				)
																			: "未設定"}
																	</td>
																	{/* 狀態 */}
																	<td className="whitespace-nowrap px-6 py-4 text-center text-blackT80">
																		{getStatusText(
																			item.exchange_status,
																			!!item.is_launch,
																		)}
																	</td>
																	{/* 按鈕 */}
																	<td
																		className={classNames(
																			"sticky right-0 z-[2] px-4 py-3 group-hover:bg-gray-50",
																			{
																				"bg-white":
																					item.exchange_count - item.stock !==
																						0 && item.is_launch,
																			},
																			{
																				"bg-red-50 group-hover:bg-red-50":
																					item.exchange_count - item.stock ===
																					0,
																			},
																			{
																				"bg-yellow-50 group-hover:bg-yellow-50":
																					!item.is_launch,
																			},
																		)}
																	>
																		<div className="flex h-5 items-center justify-between gap-2">
																			<div className="flex gap-2">
																				<Button
																					type="button"
																					theme="admin"
																					size="sm"
																					text="編輯"
																					widthFull={false}
																					borderOnly={true}
																					onClick={() =>
																						navigate(
																							`/admin/productList/edit/${item.id}`,
																						)
																					}
																				></Button>
																				<Button
																					type="button"
																					theme="admin"
																					size="sm"
																					text="刪除"
																					widthFull={false}
																					onClick={() => {
																						setNowPopupContent({
																							text: `是否確定刪除商品【${item.display_name}】？\n一經刪除即永久無法復原`,
																							action: () =>
																								batchDeleteProduct(item.id),
																						});
																						confirmPopup.current.showPopup();
																					}}
																				></Button>
																				{!item.is_launch && (
																					<Button
																						type="button"
																						theme="admin"
																						size="sm"
																						text={
																							item.is_display ? "隱藏" : "顯示"
																						}
																						widthFull={false}
																						borderOnly={true}
																						onClick={() => {
																							setNowPopupContent({
																								text: `是否確定${item.is_display ? "隱藏" : "顯示"}商品【${item.display_name}】？`,
																								action: () =>
																									batchProductDisplay(
																										!item.is_display,
																										item.id,
																									),
																							});
																							confirmPopup.current.showPopup();
																						}}
																					></Button>
																				)}
																			</div>
																		</div>
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td
																colSpan="100%"
																className="py-4 text-center font-medium"
															>
																查無資料
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										{/* Pagination */}
										<TablePagination
											component="div"
											count={totalItems}
											page={currentPage}
											rowsPerPage={pageSize}
											onPageChange={(event, newPage) =>
												handleChangePage(newPage)
											}
											onRowsPerPageChange={handleChangeRowsPerPage}
											labelRowsPerPage="每頁行數"
											rowsPerPageOptions={[5, 10, 20, 50, 100]}
										></TablePagination>
									</div>
								</div>
							</Checkboxes>
						</Form>
					</div>
					<AdminPopup
						ref={confirmPopup}
						onConfirm={nowPopupContent ? nowPopupContent.action : null}
					>
						<p className="mt-8 block whitespace-pre-wrap text-center">
							{nowPopupContent && nowPopupContent.text}
						</p>
					</AdminPopup>
					<Form onSubmit={modifyStockSubmit}>
						<AdminPopup ref={modifyStockPopup} title="庫存總量">
							<div className="space-y-4">
								<div>
									<Input
										theme="admin"
										name="stock"
										required={true}
										type="number"
										label="庫存總量"
										placeholder="請填寫庫存總量"
										min={1}
									></Input>
								</div>
							</div>
						</AdminPopup>
					</Form>

					<Form onSubmit={quantityPopupSubmit}>
						<AdminPopup ref={quantityPopup} title="可兌換數量">
							<div className="space-y-4">
								<div>
									<Input
										theme="admin"
										name="quantity"
										required={true}
										type="number"
										label="可兌換數量"
										placeholder="請填寫可兌換數量"
										min={1}
									></Input>
								</div>
								<span className="text-sm text-customGray">上線後不可更改</span>
							</div>
						</AdminPopup>
					</Form>
				</>
			)}
		</>
	);
};

export default ProductListPage;
