import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

/**
 * useFormatAsLocalTime
 * @param {string | Date} date
 * @param {string} format
 */
const useFormatAsLocalTime = () => {
	const formatAsLocalTime = (date, format = "YYYY-MM-DDTHH:mm") => {
		return date ? dayjs.utc(date).local().format(format) : "";
	};

	return { formatAsLocalTime };
};

export default useFormatAsLocalTime;
