import { useContext } from "react";
import classNames from "classnames";
import { LiffContext } from "@/utils";
import User from "@/assets/img/liff/mission/user.png";

/**
 * LiffAvatar
 * @param { 'sm' | 'lg' } [size = 'sm']
 * @returns jsx
 */
const LiffAvatar = ({ size = "sm" }) => {
	// reducer
	const { liffState } = useContext(LiffContext);

	return (
		<div
			className={classNames("select-none overflow-hidden rounded-full", {
				"h-[60px] w-[60px]": size === "sm",
				"h-24 w-24": size === "lg",
			})}
		>
			<img
				src={liffState.me?.avatar_url || User}
				alt=""
				className="h-full w-full object-cover text-center text-transparent"
			/>
		</div>
	);
};

export default LiffAvatar;
