import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Icon, Button } from "@/atoms";
import { useGetPointInfo } from "@/hooks";

const SuccessInfo = ({ successInfo }) => {
	const { getPointInfo } = useGetPointInfo();
	const navigate = useNavigate();

	useEffect(() => {
		// 重新獲取會員點數
		getPointInfo();
	}, []);

	return (
		<>
			<div className="mb-[75px] h-full w-full space-y-2 overflow-y-scroll bg-gray-50">
				<div className="space-y-3 bg-white px-5 py-[50px] text-center text-lg font-medium">
					<Icon iconName={"RedeemCheck"} className="m-auto h-10 w-10"></Icon>
					<div>
						<span>已成功兌換會員禮品</span>
						<br />
						<span>將在 7 個工作天內寄送到下列收件地址</span>
					</div>
				</div>
				<div className="bg-white px-5 py-6">
					<span className="mb-2 block font-medium">兌換資料</span>
					<p className="flex flex-col text-sm">
						<span>{`兌換單號：#${successInfo?.userExchangeActionList[0]?.ref_id}`}</span>
						<span>{`商品名稱：${successInfo?.product?.display_name}`}</span>
						<span>{`兌換數量：${successInfo?.userExchangeActionList?.length}`}</span>
						<span>{`點數總計：${
							successInfo?.userExchangeActionList[0]?.point *
							successInfo?.userExchangeActionList?.length
						}`}</span>
						<span>取貨方式：寄送到府</span>
					</p>
				</div>
				<div className="bg-white px-5 py-6">
					<span className="mb-2 block font-medium">收件資料</span>
					<p className="flex flex-col text-sm">
						<span>{`收件人：${successInfo?.userExchangeActionList[0]?.receive_name}`}</span>
						<span>{`手機號碼：${successInfo?.userExchangeActionList[0]?.receive_mobile}`}</span>
						<span>{`收件地址：${successInfo?.userExchangeActionList[0]?.receive_address}`}</span>
					</p>
					<div className="space-y-4 pb-4 pt-10">
						<Button
							type="button"
							text="查看兌換紀錄"
							theme="default"
							color="black"
							onClick={() => navigate("/exchange")}
						></Button>
						<Button
							type="button"
							text="返回首頁"
							theme="default"
							color="black"
							borderOnly={true}
							onClick={() => navigate("/redeem")}
						></Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default SuccessInfo;
