import React, { useState, useEffect, useRef } from 'react';
import './index.sass';

const PopupLayout = ({
  children,
  isOpen,
  onClose,
  maskVisible = true,
  outSideClickable = false,
  handleOutsideClick = true,
  animation = 'fade',
  zIndex = 1000,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);
  const contentRef = useRef(null);

  const handlePopupClose = () => {
    if (handleOutsideClick) {
      onClose();
    }
  }

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 500);  // Assumption: Animation duration is 500ms
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isVisible && contentRef.current) {
      contentRef.current.getBoundingClientRect();  // Force reflow
    }
  }, [isVisible]);

  if (!isVisible) return null;

  const animationClass = isAnimating ? ` ${animation} enter` : ` ${animation} exit`;
  const maskClass = maskVisible ? '' : ' mask-invisible';
  const clickableClass = outSideClickable ? ' clickable' : '';
  const slideClass = animation === 'slide' ? ' slide' : '';

  return (
    <div
      style={{ zIndex: zIndex }}
      className={`popup-overlay${animationClass}${maskClass}${clickableClass}${slideClass}`}
      onClick={handlePopupClose}
    >
      <div 
        ref={contentRef}
        className={`popup-content${animationClass}`}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default PopupLayout;
