import { LinearProgress } from "@mui/material";
import { useMemberTier } from "@/hooks";

const Progress = ({ children, color, barColor, forceFull, forceZero }) => {
	const { progress } = useMemberTier();

	// 根據 forceFull 和 forceZero 來決定進度條的值
	let progressValue = progress;
	if (forceZero) {
		progressValue = 0;
	} else if (forceFull) {
		progressValue = 100;
	}

	if (progressValue > 100) {
		progressValue = 100;
	} else if (progressValue < 0) {
		progressValue = 0;
	}

	return (
		<>
			<LinearProgress
				classes={{colorPrimary: "#185449", barColorPrimary: "#333"}}
				sx={{
					width: "100%",
					height: "1.25vw",
					borderRadius: "50vw",
					backgroundColor: barColor || "#333",
					'& .MuiLinearProgress-bar': {
						backgroundColor: color || "#185449",
					},
				}}
				color="primary"
				variant="determinate"
				value={progressValue}
			/>
			{children}
		</>
	);
};

export default Progress;
