import { AppTool } from "@/utils/app-tool";
import { LINE_FRIENDSHIP_COUSTOM_DATA_TAGE } from "@/utils/interface/friendship";
import { api } from "@/utils";

const processMgm = async (router, navigate) => {
    const asPath = `${router.pathname}${router.search}`;
    const decodedURI = decodeURIComponent(asPath);
    const map = AppTool.getUrlInfoMap(decodedURI);

    if (map.has(LINE_FRIENDSHIP_COUSTOM_DATA_TAGE.TOP_MGM)) {
        // 處理 OA 全新好友相關的
        const customData = map.get(LINE_FRIENDSHIP_COUSTOM_DATA_TAGE.TOP_MGM)
        let friendshipStatus = false
        if (map.has("friendship_status_changed")) {
            friendshipStatus = true;
        }
       
        await createTopMgmData(customData, friendshipStatus);
        return true
    }
    else if (map.has(LINE_FRIENDSHIP_COUSTOM_DATA_TAGE.FIRST_MGM)) {
        // 處理 firstMgm(綁定 CRM)
        const customData = map.get(LINE_FRIENDSHIP_COUSTOM_DATA_TAGE.FIRST_MGM)
        await createMgmfirstData(customData);
        navigate('/activity/1')
        return true
    }
}

const createMgmfirstData = async (customData) => {
    const customDataList = customData.split('_');
    try {
        const data = {
            activity_id: parseInt(customDataList[0],10),
            invite_id: parseInt(customDataList[1], 10),
        }
        await api().post("/submission_create_mgm_first", data);
        return true;
    }
    catch (err) {
        console.error(err)
    }
}

const createTopMgmData = async (customData, friendshipStatus) => {
    //
    const customDataList = customData.split('_');
    try {
        const data = {
            mission_id: parseInt(customDataList[0], 10),
            invite_id: parseInt(customDataList[1], 10),
            friendship_status: friendshipStatus
        }
        const res = await api().post("/submission_create_top_mgm", data);
        console.log(res);
        return true;
    }
    catch (err) {
        console.error(err)
    }
}

export { processMgm };