import { useLayoutEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Progress } from "@/components";
import { TierStyle } from "@/utils/tierStyles";
import { LiffContext } from "@/utils";
import PointsOverviewBg from "@/assets/img/liff/redemption/points_overview_bg.png";
import "./index.sass";

const PointsOverview = ({ pointInfo, tierCode }) => {
	const navigate = useNavigate();
	const { liffState } = useContext(LiffContext);
	const [currentTierStyle, setCurrentTierStyle] = useState(null);

	const formatNumberWithCommas = (number) => {
		return new Intl.NumberFormat().format(Math.floor(number));
	};

	const getTierStyle = (tierCode) => {
		const tierStyle = new TierStyle();
		const batchTierStyle = tierStyle.getTierStyleByCode(tierCode);
		setCurrentTierStyle(batchTierStyle);
	};

	useLayoutEffect(() => {
		getTierStyle(tierCode);
	}, []);

	return (
		<div className="point-overview">
			<img className="background" src={PointsOverviewBg} alt=""></img>
			<div className="overview-container">
				{currentTierStyle && (
					<>
						<div className="user-profile">
							<img className="vip-icon" src={currentTierStyle.vipIcon}></img>
							<label className="tier-name">{currentTierStyle.tierName}</label>
							<label className="user-name">
								{liffState.me?.line_name || ""}
							</label>
							<div
								className="member-benefits"
								onClick={() =>
									liffState.memberBenefitPopup.open(currentTierStyle.tierCode)
								}
							>
								<span className="under-line">查詢會員權益</span>
								<span style={{ marginLeft: "0.3em" }}>{">"}</span>
							</div>
						</div>
						<div className="progress">
							<Progress
								color={currentTierStyle.color}
								barColor="rgba(0, 0, 0, 0.3)"
							/>
						</div>
					</>
				)}
				<div className="points-info">
					<span className="label">我的點數：</span>
					<span className="points" style={{ fontFamily: "Helvetica Neue" }}>
						{formatNumberWithCommas(pointInfo?.availablePoint)}
					</span>
					<span className="expire">{`${formatNumberWithCommas(pointInfo?.aboutToExpire)} 點將在 3 個月內過期`}</span>
				</div>
			</div>
			<div className="button-list">
				<button onClick={() => navigate("/exchange")}>
					<span>兌換紀錄</span>
				</button>
				<button onClick={() => navigate("/points")}>
					<span>點數歷程</span>
				</button>
			</div>
		</div>
	);
};

export { PointsOverview };
