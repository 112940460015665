import { useState, useImperativeHandle, forwardRef } from "react";
import { Icon } from "@/atoms";

/**
 * LiffAgreementPopup
 * @param {React.ReactNode} props.children
 * @param {string} [props.title=null]
 * @param {React.Ref} ref
 * @returns jsx
 */
const LiffAgreementPopup = forwardRef(({ children, title }, ref) => {
	//  是否顯示彈窗
	const [isShowPopup, setIsShowPopup] = useState(false);

	// 開啟彈窗
	const onShowPopup = () => {
		setIsShowPopup(true);
	};

	// 關閉彈窗
	const onClosePopup = () => {
		setIsShowPopup(false);
	};

	useImperativeHandle(ref, () => {
		return {
			showPopup: () => onShowPopup(), // 開啟彈窗
			closePopup: () => onClosePopup(), // 關閉彈窗
		};
	});

	return (
		isShowPopup && (
			<div className="fixed inset-0 z-50 flex h-screen max-h-full flex-col overflow-y-scroll bg-gray-100">
				{/* 關閉按鈕 */}
				<button
					className="absolute right-2 top-2 z-[2]"
					onClick={() => {
						onClosePopup();
					}}
				>
					<Icon iconName={"Close"} className="text-blackT50"></Icon>
				</button>
				{/* Title */}
				<div className="fixed inset-x-0 top-0 bg-gray-100 p-4">
					<p className="text-base font-medium">
						The North Face
						<br />
						{title}
					</p>
				</div>
				{/* Content */}
				<div className="mt-20 overflow-y-scroll bg-[rgb(239,239,239)] p-5">
					{children}
				</div>
			</div>
		)
	);
});

LiffAgreementPopup.displayName = "LiffAgreementPopup";

export default LiffAgreementPopup;
