/* eslint-disable no-unused-vars */
import { useLocation } from "react-router-dom";

const Breadcrumbs = () => {
	const location = useLocation();
	const pathnames = location.pathname.split("/").filter((x) => x);

	const breadcrumbNames = {
		admin: null,
		productList: "商品列表",
		exchangeList: "兌换紀錄",
		displayManagement: "顯示管理",
		imgCarouselList: "圖片輪播",
		textCarouselList: "文字輪播",
		popupList: "彈窗",
		edit: "編輯",
		add: "新增",
	};

	const breadcrumbs = pathnames
		.map((path) => breadcrumbNames[path])
		.filter((name) => name !== null && name !== undefined);
	const breadcrumbString = breadcrumbs.join(" / ");

	return <div className="text-lg font-bold text-white">{breadcrumbString}</div>;
};

export default Breadcrumbs;
