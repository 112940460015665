import { createContext, useContext } from "react";

export const CheckboxesContext = createContext({
	checkedState: undefined,
	setCheckedState: undefined,
	childCheckboxes: undefined,
});

export const useCheckboxesContext = () => {
	return useContext(CheckboxesContext);
};
