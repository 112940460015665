import { useRef, useContext, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Form, Checkbox, Button } from "@/atoms";
import {
	LiffAgreementPopup,
	AgreementTerms,
	MembershipTerms,
	PrivacyPolicy,
} from "@/components";
import { LiffContext } from "@/utils";

import HomeLogo from "@/assets/img/liff/tnf-logo.svg";

const AgreementPage = () => {
	const navigate = useNavigate();
	// 會員權益聲明彈窗
	const membersPopupRef = useRef();
	// 隱私權政策彈窗
	const privacyPopupRef = useRef();
	// reducer
	const { liffActions, liffState } = useContext(LiffContext);

	const onSubmit = (data) => {
		if (data.agreement) {
			navigate("/binding", { state: { isCheck: true } });
		}
	};

	useLayoutEffect(() => {
		// 若已綁定電話
		if (liffState.isMemberBound) {
			navigate("/");
		}

		liffActions.setShowFullPageLoader(false);
	}, []);

	return (
		<>
			<div className="flex h-screen w-full flex-col">
				<header className="flex items-center justify-between border-b border-solid border-b-gray-200 p-5">
					<div className="flex-none">
						<p className="text-2xl font-medium">申請 LINE 個人化服務</p>
						<p className="text-base text-[#666666]">
							綁定 LINE 官方帳號 享受個人化服務
						</p>
					</div>
					<div className="flex flex-1 items-center pl-4">
						<img src={HomeLogo} alt="" className="h-12 w-16" loading="lazy" />
					</div>
				</header>
				<div className="flex h-full max-h-full min-h-0 w-full flex-col">
					<div className="flex h-full max-h-full min-h-0 flex-initial flex-col">
						<div className="flex-none p-4">
							<p className="text-base font-medium">
								The North Face Line Business Connect
							</p>
							<p className="text-base font-medium">服務使用條款</p>
						</div>
						<div className="flex h-full max-h-full min-h-0 shrink grow flex-col space-y-4 overflow-y-scroll bg-[#EFEFEF] p-4">
							<AgreementTerms></AgreementTerms>
						</div>
					</div>
				</div>
				<Form onSubmit={onSubmit}>
					<div className="flex-none space-y-4 p-4">
						<div className="flex w-full items-center">
							<Checkbox
								name="agreement"
								required={true}
								errMsg="請閱讀並同意服務條款"
							>
								<div className="ml-3">
									<span>
										我已詳閱並同意相關使用須知，並同意
										<button
											type="button"
											className="underline"
											onClick={() => membersPopupRef.current.showPopup()}
										>
											會員權益聲明
										</button>
										、
										<button
											type="button"
											className="underline"
											onClick={() => privacyPopupRef.current.showPopup()}
										>
											隱私權及網站使用條款
										</button>
										。
									</span>
								</div>
							</Checkbox>
						</div>
						<div>
							<Button
								type="submit"
								theme="default"
								color="black"
								text="送出"
							></Button>
						</div>
					</div>
				</Form>
			</div>
			{/* 隱私權彈窗 */}
			<LiffAgreementPopup ref={membersPopupRef} title="會員權益聲明">
				<MembershipTerms></MembershipTerms>
			</LiffAgreementPopup>
			<LiffAgreementPopup ref={privacyPopupRef} title="隱私權及網站使用條款">
				<PrivacyPolicy></PrivacyPolicy>
			</LiffAgreementPopup>
		</>
	);
};

export default AgreementPage;
