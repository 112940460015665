import { createContext, useReducer } from "react";

// 建立共用環境
const LiffContext = createContext();

// initialState
const initialState = {
	isMemberBound: false,
	me: null,
	memberData: null,
	showFullPageLoader: true,
	pointsInfo: null,
	productIntro: null,
	memberBenefitPopup: false
};

// reducer
const reducer = (state, action) => {
	switch (action.type) {
		// 是否綁定
		case "SET_IS_MEMBER_BOUND":
			return {
				...state,
				isMemberBound: action.payload,
			};
		// line基本資料
		case "SET_ME":
			return {
				...state,
				me: action.payload,
			};
		// 會員基本資料
		case "SET_MEMBER_DATA":
			return {
				...state,
				memberData: action.payload,
			};
		// 是否顯示全頁loading
		case "SET_SHOW_FULL_PAGE_LOADER":
			return {
				...state,
				showFullPageLoader: action.payload,
			};
		// 獲取點數資訊
		case "SET_POINT_INFO":
			return {
				...state,
				pointInfo: action.payload,
			};
		// 商品資訊
		case "SET_PRODUCT_INFO":
			return {
				...state,
				productIntro: action.payload,
			};
		case "SET_MEMBER_BENEFIT_POPUP":
			return {
				...state,
				memberBenefitPopup: action.payload
			};
		default:
			throw Error("Unknown action: " + action.type);
	}
};

// 建立provider元件
const LiffProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const actions = {
		setIsMemberBound: (payload) => {
			dispatch({
				type: "SET_IS_MEMBER_BOUND",
				payload,
			});
		},
		setMe: (payload) => {
			dispatch({
				type: "SET_ME",
				payload,
			});
		},
		setMemberData: (payload) => {
			dispatch({
				type: "SET_MEMBER_DATA",
				payload,
			});
		},
		setShowFullPageLoader: (payload) => {
			dispatch({
				type: "SET_SHOW_FULL_PAGE_LOADER",
				payload,
			});
		},
		setPointInfo: (payload) => {
			dispatch({
				type: "SET_POINT_INFO",
				payload,
			});
		},
		setProductIntro: (payload) => {
			dispatch({
				type: "SET_PRODUCT_INFO",
				payload,
			});
		},
		setMemberBenefitPopup: (payload) => {
			dispatch({
				type: "SET_MEMBER_BENEFIT_POPUP",
				payload
			});
		}
	};

	return (
		<LiffContext.Provider
			value={{
				liffState: state,
				liffActions: actions,
			}}
		>
			{children}
		</LiffContext.Provider>
	);
};

export { LiffContext, LiffProvider };
