import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import toast from "react-hot-toast";

import {
	Form,
	Button,
	Input,
	Textarea,
	Switch,
	Select,
	InputDate,
} from "@/atoms";
import { ImageUploader, AdminPopup, Popup } from "@/components";
import { AppError, LiffContext, api } from "@/utils";
import { useFormatAsLocalTime } from "@/hooks";

dayjs.extend(utc);

const receiveModeOptions = [{ key: "SEND", value: "寄送" }];
const categoryOptions = [{ key: "ENTITY", value: "ENTITY" }];

const ProductListDetailPage = () => {
	// 預覽圖片
	const [previewUrls, setPreviewUrls] = useState([]);
	// switch開關
	const [switchState, setSwitchState] = useState({
		is_launch: false,
		exchange_limit: false,
	});
	// 兌換開始日期
	const [exchangeStartDate, setExchangeStartDate] = useState(null);
	// 上架日期
	const [launchDate, setLaunchDate] = useState(null);
	// 根據路徑判斷是否為编辑頁
	const [isEditPage, setIsEditPage] = useState(false);
	// 商品id不存在
	const [isError, setIsError] = useState(false);
	// 會員級別選項
	const [tierOptions, setTierOptions] = useState([]);
	// 新增商品資料
	const [productData, setProductData] = useState({
		display_name: null,
		image_id_list: [],
		point: null,
		content: null,
		category: categoryOptions[0].key,
		sku_id: null,
		min_redeemable_tier_code: null,
		receive_mode: receiveModeOptions[0].key,
		exchangeRemainingQuantity: null,
		limited: null,
		is_launch: false,
		launch_date: null,
		unlisting_date: null,
		exchange_limit: false,
		exchange_start_date: null,
		exchange_end_date: null,
	});

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	const { formatAsLocalTime } = useFormatAsLocalTime();
	const { productId } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const [message, setMessage] = useState("");
	const messagePopup = useRef(null);
	const confirmPopup = useRef(null);
	const editCreateFormRef = useRef(null);

	const getTierList = async () => {
		try {
			const res = await api().get("/admin/get_member_tier_list");
			const tiers = res.data;
			const formattedTiers = tiers.map((tier) => ({
				key: tier.tier_code,
				value: `${tier.vip_code} ${tier.tier_name}`,
			}));
			console.log("formattedTiers", formattedTiers);
			setTierOptions(formattedTiers);
		} catch (error) {
			console.error("Get Tier List Failed", error);
		}
	};

	// 計算最小可選擇日期
	const getMinDate = (isEditing) => {
		// 如果是編輯頁面，則可以選擇小於等於今天的日期
		return isEditing ? null : dayjs().format("YYYY-MM-DDTHH:mm");
	};

	// 進入編輯頁面，獲取單筆商品資料
	const getProductData = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/admin/get_product_by_id", {
				params: { id: productId },
			});

			const {
				id,
				sku_id,
				display_name,
				images,
				point,
				value,
				content,
				category,
				min_redeemable_tier_code,
				receive_mode,
				limited,
				is_launch,
				launch_date,
				unlisting_date,
				exchange_no_limit,
				exchange_start_date,
				exchange_end_date,
				quantity,
				exchange_count,
			} = res.data;
			const remainingQuantity =
				typeof quantity === "number" && typeof exchange_count === "number"
					? quantity - exchange_count
					: null;

			setProductData({
				id,
				sku_id,
				display_name,
				image_id_list: images,
				point,
				value,
				content,
				category,
				// sku: "SKU",
				min_redeemable_tier_code,
				receive_mode,
				exchangeRemainingQuantity: remainingQuantity,
				limited: parseInt(limited, 10),
				is_launch,
				launch_date: formatAsLocalTime(launch_date, "YYYY-MM-DDTHH:mm"),
				unlisting_date: formatAsLocalTime(unlisting_date, "YYYY-MM-DDTHH:mm"),
				exchange_limit: !exchange_no_limit,
				exchange_start_date: formatAsLocalTime(
					exchange_start_date,
					"YYYY-MM-DDTHH:mm",
				),
				exchange_end_date: formatAsLocalTime(
					exchange_end_date,
					"YYYY-MM-DDTHH:mm",
				),
			});

			// 變更switch狀態
			setSwitchState({
				is_launch: !!is_launch,
				exchange_limit: !exchange_no_limit,
			});

			// 預覽圖片
			setPreviewUrls(images);
		} catch (error) {
			console.error("Get Product Info Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.NO_FOUND:
					case AppError.INVALID_FORMAT:
						setIsError(true);
						break;

					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 上傳圖片
	const uploadImage = async (id) => {
		// 過濾出新上傳的圖片
		const newImageUpload = previewUrls.filter((file) => !file.id);

		const maxSize = 4 * 1000 * 1000; // 4MB
		const isOverSize = newImageUpload.some((file) => file.file.size > maxSize);
		if (isOverSize) {
			setMessage("圖片過大，無法上傳，\n請重新選擇圖片。");
			messagePopup.current.showPopup();
			return;
		}

		const uploadPromises = newImageUpload.map((file) => {
			const formData = new FormData();
			formData.append("image", file.file);
			formData.append("product_id", productId ? productId : id);
			return api().post("/admin/upload_product_image_by_product_id", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
		});

		try {
			const res = await Promise.all(uploadPromises);

			const uploadedImagesInfo = res.map((item) => ({
				id: item.data.id,
				url: item.data.url,
			}));

			return uploadedImagesInfo;
		} catch (error) {
			const errorCode = error.response.data.error.code;
			switch (errorCode) {
				case AppError.IMAGE_SIZE_ERROR:
					setMessage("圖片過大，無法上傳，\n請重新選擇圖片。");
					messagePopup.current.showPopup();
					break;
				default:
					setMessage("上傳圖片失敗，請檢查圖片格式。");
					messagePopup.current.showPopup();
			}
		}
	};

	// 編輯商品資料
	const updateProduct = async (editFormValues) => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().post(
				"/admin/update_product_by_id",
				editFormValues,
			);

			if (res.data === "success") {
				navigate("/admin/productList");
			}
		} catch (error) {
			console.error("Update Product Failed", error);

			// TODO: 錯誤處理
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 新增商品
	const addNewProduct = async (addFormValues) => {
		try {
			const res = await api().post("/admin/add_new_product", addFormValues);

			const productId = res.data.id;
			if (!productId) {
				return toast.error("新增商品失敗，請再試一次。");
			}

			const upload_res = await uploadImage(productId);

			if (upload_res.length <= 0) {
				return toast.error("新增商品失敗，請再試一次。");
			}

			confirmPopup.current.closePopup();
			toast.success("新增商品成功");
			navigate("/admin/productList");
		} catch (error) {
			console.error("Add New Product Failed", error);
		}
	};

	// 提交編輯 / 新增
	const submitData = async (data) => {
		const formattedLaunchDate = data.launch_date
			? dayjs(data.launch_date).utc().format()
			: null;
		const formattedExchangeDateStartDate = data.exchange_start_date
			? dayjs(data.exchange_start_date).utc().format()
			: null;
		const formattedExchangeDateEndDate = data.exchange_end_date
			? dayjs(data.exchange_end_date).utc().format()
			: null;
		const formattedUnlistingDate = data.unlisting_date
			? dayjs(data.unlisting_date).utc().format()
			: null;

		if (isEditPage) {
			// 新上傳的圖片
			const newUploadedImages = await uploadImage(data.images);

			if (!newUploadedImages) {
				confirmPopup.current.closePopup();
				return;
			}

			const updatedPreviewUrls = previewUrls.map((previewUrl) => {
				return previewUrl.id
					? previewUrl
					: newUploadedImages && newUploadedImages.shift();
			});

			// 將圖片id取出
			const imageIds = updatedPreviewUrls.map((item) => item.id);

			const editFormValues = {
				id: data.id,
				sku_id: data.sku_id,
				category: data.category,
				display_name: data.display_name,
				point: data.point,
				value: data.value,
				content: data.content,
				limited: parseInt(data.limited, 10),
				min_redeemable_tier_code: data.min_redeemable_tier_code,
				receive_mode: data.receive_mode,
				launch_date: formattedLaunchDate,
				unlisting_date: formattedUnlistingDate,
				is_launch: !!data.is_launch,
				exchange_no_limit: !data.exchange_limit,
				exchange_start_date: formattedExchangeDateStartDate,
				exchange_end_date: formattedExchangeDateEndDate,
				image_id_list: imageIds,
			};

			await updateProduct(editFormValues);
		} else {
			// 新增商品
			const addFormValues = {
				category: data.category,
				display_name: data.display_name,
				point: parseInt(data.point, 10),
				content: data.content,
				limited: parseInt(data.limited, 10),
				min_redeemable_tier_code: data.min_redeemable_tier_code,
				receive_mode: data.receive_mode,
				launch_date: formattedLaunchDate,
				unlisting_date: formattedUnlistingDate,
				is_launch: !!data.is_launch,
				exchange_no_limit: !data.exchange_limit,
				exchange_start_date: formattedExchangeDateStartDate,
				exchange_end_date: formattedExchangeDateEndDate,
				value: 0,
				sku_id: data.sku_id,
			};

			await addNewProduct(addFormValues);
		}
	};

	const onSubmit = () => {
		// 如果沒有圖片，則不允許提交
		if (previewUrls.length === 0) {
			return;
		}
		confirmPopup.current.showPopup();
	};

	useEffect(() => {
		const editPage = location.pathname.includes("/edit");
		setIsEditPage(editPage);
		getTierList();
	}, [location]);

	useEffect(() => {
		if (isEditPage) {
			getProductData();
		} else {
			liffActions.setShowFullPageLoader(false);
		}
	}, [isEditPage]);

	useEffect(() => {
		// 如果id不存在，則返回商品列表頁
		if (isError) {
			navigate("/admin/productList");
		}
	}, [isError]);

	return (
		<>
			{!liffState.showFullPageLoader && (
				<>
					<Form
						onSubmit={onSubmit}
						defaultValues={productData}
						ref={editCreateFormRef}
					>
						<div className="space-y-6 sm:flex sm:flex-col sm:items-center">
							{/* 商品名稱 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<Input
									theme="admin"
									name="display_name"
									required={true}
									type="text"
									label="商品名稱"
									placeholder="請輸入商品名稱"
								></Input>
							</div>

							{/* 上傳圖片 */}
							<div className="sm:w-2/3">
								<ImageUploader
									isMultiple={true}
									name="image_id_list"
									setPreviewUrls={setPreviewUrls}
									previewUrls={previewUrls}
								></ImageUploader>
							</div>

							{/* 兌換商品點數 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<Input
									theme="admin"
									name="point"
									required={true}
									type="number"
									label="兌換商品點數"
									placeholder="請輸入商品點數"
									disabled={productData.is_launch}
									isReadOnly={productData.is_launch}
								></Input>
							</div>

							{/* 最低可兌換會員級別 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<p className="mb-2.5 block text-sm font-medium text-[#333333]">
									最低可兌換會員級別
									<span className="text-red-600">*</span>
								</p>
								<div>
									<Select
										label="會員級別"
										name="min_redeemable_tier_code"
										placeholder="請選擇會員級別"
										required={true}
										selectOptions={tierOptions}
										icon="ArrowDropDown"
										theme="admin"
										disabled={productData.is_launch}
										isReadOnly={productData.is_launch}
									></Select>
								</div>
							</div>

							{/* 說明文字 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<Textarea
									theme="admin"
									name="content"
									required={true}
									label="說明文字"
								></Textarea>
								<span className="text-sm font-medium text-customGray">
									手機上顯示約25字會換行
								</span>
							</div>

							{/* 商品類別 */}
							<div className="flex-wrap space-y-6 sm:flex sm:w-2/3 sm:gap-2 sm:space-y-0">
								<p className="mb-2.5 block w-full text-sm font-medium text-[#333333]">
									商品類別
									<span className="text-red-600">*</span>
								</p>
								<div className="flex-1">
									<Select
										name="category"
										placeholder="請選擇商品類別"
										required={false}
										selectOptions={categoryOptions}
										icon="ArrowDropDown"
										theme="admin"
										disabled={true}
									></Select>
								</div>
								<div className="flex-1">
									<Input
										label="商品類別"
										is_display_name={false}
										theme="admin"
										name="sku_id"
										required={true}
										type="text"
										placeholder="請輸入商品類別"
									></Input>
								</div>
							</div>

							{/* 領取方式 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<p className="mb-2.5 block text-sm font-medium text-[#333333]">
									領取方式
									<span className="text-red-600">*</span>
								</p>
								<div>
									<Select
										name="receive_mode"
										placeholder="請選擇寄送方式"
										required={false}
										selectOptions={receiveModeOptions}
										icon="ArrowDropDown"
										theme="admin"
										disabled={true}
									></Select>
								</div>
							</div>

							{/* 數量 */}
							<div className="gap-2 space-y-6 sm:flex sm:w-2/3 sm:space-y-0">
								{isEditPage && (
									<div className="flex-1">
										<Input
											theme="admin"
											name="exchangeRemainingQuantity"
											required={true}
											type="text"
											label="兌換剩餘數量"
											placeholder="請填寫兌換剩餘數量"
											requiredMark={false}
											isReadOnly={true}
										></Input>
									</div>
								)}

								<div className="flex-1">
									<Input
										theme="admin"
										name="limited"
										required={true}
										type="number"
										label="每人限量"
										placeholder="請輸入每人限量"
										isReadOnly={dayjs().isAfter(productData.launch_date)}
										min={1}
									></Input>
								</div>
							</div>

							{/* 上架日期 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<span className="mb-2.5 block text-sm font-medium text-[#333333]">
									上架起始（未指定日期將保存為草稿）
								</span>

								<div className="space-y-3 sm:flex sm:gap-4 sm:space-y-0">
									<Switch
										name="is_launch"
										onChange={(e) =>
											setSwitchState((prevState) => ({
												...prevState,
												is_launch: e.target.checked,
											}))
										}
									></Switch>
									{switchState.is_launch && (
										<>
											{/* 上架日期 */}
											<InputDate
												theme="admin"
												name="launch_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												minDate={getMinDate(isEditPage)}
												onChange={(e) => setLaunchDate(e.target.value)}
											></InputDate>
											{/* 下架日期 */}
											<InputDate
												theme="admin"
												name="unlisting_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												minDate={launchDate || getMinDate(isEditPage)}
											></InputDate>
										</>
									)}
								</div>
							</div>

							{/* 兌換起始 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<span className="mb-2.5 block text-sm font-medium text-[#333333]">
									兌換起始
								</span>

								<div className="space-y-3 sm:flex sm:gap-4 sm:space-y-0">
									<Switch
										name="exchange_limit"
										onChange={(e) =>
											setSwitchState((prevState) => ({
												...prevState,
												exchange_limit: e.target.checked,
											}))
										}
									></Switch>

									{switchState.exchange_limit && (
										<>
											{/* 起 */}
											<InputDate
												theme="admin"
												name="exchange_start_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												minDate={getMinDate(isEditPage)}
												onChange={(e) => setExchangeStartDate(e.target.value)}
											></InputDate>
											{/* 迄 */}
											<InputDate
												theme="admin"
												name="exchange_end_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												minDate={exchangeStartDate || getMinDate(isEditPage)}
											></InputDate>
										</>
									)}
								</div>
							</div>

							{/* Button */}
							<div className="flex justify-end gap-5 sm:w-2/3">
								<Button
									type="button"
									theme="admin"
									borderOnly={true}
									widthFull={false}
									text="返回"
									onClick={() => navigate("/admin/productList")}
								></Button>
								<Button
									type="submit"
									theme="admin"
									widthFull={false}
									text="儲存"
								></Button>
							</div>
						</div>
					</Form>
					<AdminPopup
						ref={confirmPopup}
						onConfirm={async () => {
							const data = editCreateFormRef.current.getValues();

							await submitData(data);
						}}
					>
						{isEditPage ? (
							<span className="my-8 block whitespace-pre-wrap text-center">
								{`是否確定要儲存\n對此商品所做的更改？`}
							</span>
						) : (
							<span className="my-8 block whitespace-pre-wrap text-center">{`是否確定商品上架？\n確定上架後，產品的兌換點數、最低可兌換會員級別、兌換數量、每人限量則不可更改。`}</span>
						)}
					</AdminPopup>
					<Popup ref={messagePopup} hasBtn={true}>
						<p className="whitespace-pre-wrap pb-5 pt-10 text-center">
							{message}
						</p>
					</Popup>
				</>
			)}
		</>
	);
};

export default ProductListDetailPage;
