import { useState } from "react";

const useCheckboxSelection = (items = []) => {
	const [selectedId, setSelectedId] = useState([]);

	const onSelectedItem = (checked, selectedItem) => {
		if (selectedItem === "selectedAll") {
			// 如果是全選
			if (checked) {
				setSelectedId(items.map((item) => (item.id ? item.id : item.ref_id)));
			} else {
				setSelectedId([]);
			}
		} else {
			// 如果是子層
			if (checked) {
				setSelectedId((prev) => [...prev, selectedItem]);
			} else {
				setSelectedId((prev) => prev.filter((id) => id !== selectedItem));
			}
		}
	};

	return { selectedId, setSelectedId, onSelectedItem };
};

export default useCheckboxSelection;
