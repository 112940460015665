import { memo, useImperativeHandle, forwardRef, useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {Function} props.onSubmit 表單送出時觸發
 * @param {object} props.defaultValues 表單預設值
 * @param {Function} props.onValidChange 表單驗證狀態改變時觸發
 * @param {React.MutableRefObject} ref
 */
const Form = forwardRef(
	({ children, onSubmit, defaultValues, onValidChange }, ref) => {
		const methods = useForm({ defaultValues });

		// 自定義 ref 方法
		useImperativeHandle(ref, () => ({
			getValues: (name) => methods.getValues(name), // 獲取指定欄位資料
			getAllValues: () => methods.getValues(), // 獲取表單全部資料
			setValue: (name, value) => methods.setValue(name, value), // 設定指定欄位資料
			reset: () => methods.reset(),
			watch: (name) => methods.watch(name), // 監聽指定欄位資料
		}));

		useEffect(() => {
			// 表單驗證狀態改變時觸發
			if (onValidChange) {
				onValidChange(methods.formState.isValid);
			}
		}, [methods.formState.isValid]);

		// 監聽表單改變
		methods.watch((value, attr) => {
			methods.trigger(attr.name);
		});

		return (
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
			</FormProvider>
		);
	},
);

Form.displayName = "Form";

export default memo(Form);
