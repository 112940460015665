/* eslint-disable no-unused-vars */
import { useEffect, useContext, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import toast from "react-hot-toast";

import { LiffContext, api, AppError } from "@/utils";
import {
	DeliveryForm,
	SuccessInfo,
	RedemptionIntro,
} from "./redemptionItem/index";
import { EXCHANGE_STATUS_CODES } from "@/utils/interface/exchange";
import { AppTool } from "@/utils/app-tool";

// img
import ChangedOverBg from "@/assets/img/liff/redemption/changed_over_bg.png";

// 測試productId：clcirw7x40006mi08pgtfg92r

const RedemptionIntroPage = () => {
	// 是否顯示DeliveryForm
	const [isShowForm, setIsShowForm] = useState(false);
	// 顯示成功兌換資訊
	const [showSuccessInfo, setShowSuccessInfo] = useState(false);
	const [isError, setIsError] = useState(false);
	// 成功兌換資訊
	const [successInfo, setSuccessInfo] = useState(null);

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	const navigate = useNavigate();
	const { productId } = useParams();
	// 獲取商品資訊
	const getProductIntro = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get(`/get_product_by_id`, {
				params: { id: productId },
			});

			if (res.data) {
				liffActions.setProductIntro(res.data);
			} else {
				setIsError(true);
			}
		} catch (error) {
			console.error("Get Product Info Failed", error);

			if (error.response) {
				setIsError(true);
			}
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	const exchangeCheck = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			await api().post(`/exchange_check_by_product_id`, { productId: productId });
		} catch (error) {
			AppTool.openDialog('.cChangedOverDialog')
		}
	};

	const onShowDeliveryForm = () => {
		if (
			liffState?.productIntro?.exchange_status !== EXCHANGE_STATUS_CODES.ON_SALE
		) {
			return;
		}
		exchangeCheck();
		setIsShowForm(true);
		window.scrollTo(0, 0);
	};

	const handlePopupClose = () => {
		setShowSuccessInfo(true);
		setIsShowForm(false);
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		getProductIntro();
	}, []);

	useEffect(() => {
		if (isError) {
			navigate("/redeem");
		}
	}, [isError]);

	return (
		<>
			<div role="presentation" className="cChangedOverDialog flex-col items-center justify-center fixed top-0 left-0 w-[100vw] h-[110vh] z-[100] duration-500"
				style={{ display: 'none', marginTop: '0' }}
				onClick={() => {
					AppTool.closeDialog('.cChangedOverDialog')
					navigate("/redeem");
				}}>
				<div className="cMask bg-[#000] opacity-[0.5] w-[100%] h-[100%] absolute z-[0]" />
				<div role="presentation" className="cDialog w-[60vw] mt-[-20vw] z-[1] rounded-[1vw] p-[1vw] duration-500 relative" style={{ transform: 'scale( 0 )' }}>
					<img className='w-[100%]' src={ChangedOverBg || ''} />
				</div>
			</div>

			{!showSuccessInfo &&
				(isShowForm ? (
					<DeliveryForm
						onPopupClose={handlePopupClose}
						setSuccessInfo={setSuccessInfo}
					></DeliveryForm>
				) : (
					<RedemptionIntro
						onClick={onShowDeliveryForm}
						isShowForm={isShowForm}
					></RedemptionIntro>
				))}
			{showSuccessInfo && <SuccessInfo successInfo={successInfo}></SuccessInfo>}
		</>
	);
};

export default RedemptionIntroPage;
