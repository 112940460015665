 class AppTool {

  static getUrlInfoMap(strUrl) {
    const kMap = new Map
    if (strUrl.indexOf("?") !== -1) {
      const getSearch = strUrl.split("?");
      getSearch.forEach(search => {
        const getPara = search.split("&");
        for (let i = 0; i < getPara.length; i += 1) {
          const kParaVal = getPara[i].split("=");
          if (kParaVal.length === 2) {
            kMap.set(kParaVal[0], kParaVal[1]);
          }
          else {
            kMap.set(kParaVal[0], null);
          }
        }
      });
    }
    return kMap
  }

  static openDialog(dialogItem) {
    const dialogItems = document.querySelectorAll(dialogItem);
    dialogItems.forEach((item) => {
      if (item instanceof HTMLElement) {  // test is HTMLElement
        /* eslint-disable no-param-reassign */
        item.style.display = 'flex';
        /* eslint-disable no-param-reassign */
      }
    })


    setTimeout(() => {
      dialogItems.forEach((item) => {
        const cDialog = item.querySelector('.cDialog');
        if (cDialog) {
          if (cDialog instanceof HTMLElement) {
            /* eslint-disable no-param-reassign */
            cDialog.style.transform = 'scale(1)';
            /* eslint-disable no-param-reassign */
          }
        }
      });
    }, 100);
  }

  static closeDialog(dialogItem) {
    const dialogItems = document.querySelectorAll(dialogItem);

    dialogItems.forEach((item) => {
      const cDialog = item.querySelector('.cDialog');
      if (cDialog) {
        if (cDialog instanceof HTMLElement) {
          cDialog.style.transform = 'scale(0)';
        }

        setTimeout(() => {
          if (item instanceof HTMLElement) {
            /* eslint-disable no-param-reassign */
            item.style.display = 'none';
            /* eslint-disable no-param-reassign */
          }
        }, 500);
      }
    });
  }
}

export { AppTool };