import Lottie from "lottie-react";
import TnfLoading from "./lottie-tnf-loading.json";
import { useContext } from "react";
import { LiffContext } from "@/utils";

const style = {
	width: "100%",
	height: "100vh",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "absolute",
	top: 0,
	left: 0,
	zIndex: 9999,
	backgroundColor: "white",
};

const LiffLoader = () => {
	// reducer
	const { liffState } = useContext(LiffContext);

	return (
		liffState.showFullPageLoader && (
			<Lottie autoPlay loop style={style} animationData={TnfLoading} />
		)
	);
};

export default LiffLoader;
