import { useContext, useState } from "react";

import { LiffContext, api } from "@/utils";

const useGetUserExchangeList = () => {
	const [exchangeData, setExchangeData] = useState([]);
	// reducer
	const { liffActions } = useContext(LiffContext);

	const [isLoading, setIsLoading] = useState(true);

	const getUserExchangeList = async () => {
		try {
			const res = await api().get("/get_user_exchange_list");

			if (res.data.length > 0) {
				// 儲存兌換紀錄
				setExchangeData(res.data);
			} else {
				setExchangeData([]);
			}
		} catch (error) {
			console.error("Get user exchange list failed", error);
		} finally {
			liffActions.setShowFullPageLoader(false);
			setTimeout(() => {
				setIsLoading(false);
			}, 500);
		}
	};
	return { getUserExchangeList, exchangeData, isLoading };
};

export default useGetUserExchangeList;
