/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { AppError, api } from "@/utils";

const AdminLoginGuard = () => {
	// 是否驗證成功
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	const geAdminProfile = async () => {
		try {
			await api().get("/admin/me");
			setIsAuthenticated(true);
		} catch (error) {
			console.error("Admin profile fetch failed", error);

			setIsAuthenticated(false);
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		geAdminProfile();
	}, []);

	if (!isAuthenticated && !isLoading) {
		navigate("/admin");
		return;
	}

	return isAuthenticated && <Outlet></Outlet>;
};

export default AdminLoginGuard;
