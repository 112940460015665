import { useContext, useEffect, useState } from 'react';
import Bg from "@/assets/img/liff/mission/pinned_activity/submission/bg.png";
import UserDefaultIcon from '@/assets/img/liff/mission/pinned_activity/submission/icon-user.png';
import ShareMessage from '@/assets/img/liff/mission/pinned_activity/submission/flex_message.png';
import People3 from "@/assets/img/liff/mission/pinned_activity/people/people_3.gif";
import People4 from "@/assets/img/liff/mission/pinned_activity/people/people_4.gif";
import { LINE_FRIENDSHIP_COUSTOM_DATA_TAGE } from "@/utils/interface/friendship";
import { MISSION_ID, MISSION_SlUG } from "@/utils/interface/activity";
import Triangle from './triangle';
import { LiffContext, api } from "@/utils";

import ReactGA from "react-ga4";


const getMessage = (userId, avatarUrl) => {
  const targetUrl = `https://liff.line.me/${process.env.REACT_APP_LIFF_ID}/`;
  const targetUrlEncoded = encodeURIComponent(targetUrl);
  const linkUrl = `${window.location.origin}/auth?target_url=${targetUrlEncoded}?${LINE_FRIENDSHIP_COUSTOM_DATA_TAGE.TOP_MGM}=${MISSION_ID.TOP_MGM}_${userId}`;
  return [
    {
      "type": "flex",
      "altText": "The North Face 好友獨家通知｜現在加入我的探險小隊，完成所有任務最高可以獲得會員點數5,500點！",
      "contents": {
        "type": "bubble",
        "size": "giga",
        "body": {
          "type": "box",
          "layout": "vertical",
          "paddingAll": "0px",
          "contents": [
            {
              "type": "image",
              "url": avatarUrl,
              "position": "absolute",
              "offsetTop": "42%",
              "offsetStart": "23%",
              "size": "55%"
            },
            {
              "type": "image",
              "url": `${process.env.REACT_APP_ASSET_URL}${ShareMessage}`,
              "position": "relative",
              "size": "100%",
              "margin": "0px",
              "aspectRatio": "1005:1251"
            }
          ],
          "action": {
            "type": "uri",
            "label": "action",
            "uri": linkUrl
          }
        },
      },
    },
  ];
};

const shareMessage = async (userId, avatarUrl) => {
  try {
    const liff = (await import('@line/liff')).default;
    const res = await liff.shareTargetPicker(getMessage(userId, avatarUrl), { isMultiple: true });
    if (res) {
      console.log(`[${res.status}] Message sent!`);
      // TODO GA
      // logEvent(`share_ok_${MISSION_SlUG.TOP_MGM}`)
    } else {
      const [majorVer, minorVer] = (liff.getLineVersion() || "").split('.');
      if (Number(majorVer) === 10 && Number(minorVer) < 11) {
        // LINE 10.3.0 - 10.10.0
        // Old LINE will access here regardless of user's action
        console.log('TargetPicker was opened at least. Whether succeeded to send message is unclear');
      } else {
        // LINE 10.11.0 -
        // sending message canceled
        console.log('TargetPicker was closed!');
      }
    }
  } catch (error) {
    console.log('something wrong happen:', error)
  }
};

const ActivityDetailPage = () => {
  const [peoplePos, setPeoplePos] = useState('top-[-20vw] left-[-20vw]');
  const [mission, setMission] = useState (null);
  const [user, setUser] = useState (null);
  const [avatarUrl, setAvatarUrl] = useState (null);
  const [finishedCount, setFinishedCount] = useState(0);
  const { liffActions, liffState } = useContext(LiffContext);

  //liffState?.me?

  const getMission = async () => {
    //const m = await getMisionBySlug({ slug: AppDef.MISSION_SlUG.TOP_MGM });
    //setMission(m);
    try {
			const res = await api().get("/get_mission_by_slug", {
				params: { slug: MISSION_SlUG.TOP_MGM },
			});
      setMission(res.data);
		}
		catch (err) {
			console.log(err);
		}
    console.log(liffState)
    liffActions.setShowFullPageLoader(false);
  }

  const getAvatarStyle = (index) => {
    if (mission && mission.submissions[index] && mission.submissions[index].friend.avatar_url) {
      return { backgroundImage: `url(${mission.submissions[index].friend.avatar_url})` };
    }
    return { backgroundImage: `url(${UserDefaultIcon})`, backgroundSize: '70%' };
  }

  useEffect(() => {
    if (mission) {
      const count = mission.submissions.length;
      setFinishedCount(count);

      let pos = '';
      switch (count) {
        case 0: pos = 'top-[78vw] left-[82vw]'; break;
        case 1: pos = 'top-[63vw] left-[35vw]'; break;
        case 2: pos = 'top-[20vw] left-[40vw]'; break;
        case 3:
        default:
          pos = 'top-[-20vw] left-[-20vw]'; // Hide
          break;
      }
      setPeoplePos(pos)
    }
  }, [mission]);

  useEffect(() => {
    const avatarUrlTemp = liffState.me.avatar_url && liffState.me.avatar_url != '' ?  liffState.me.avatar_url : `${process.env.REACT_APP_ASSET_URL}${UserDefaultIcon}`
    setAvatarUrl(avatarUrlTemp);
    setUser(liffState.me);
    getMission();
    ReactGA.event('page_top_mission_2_4')
  }, []);

  return (
    <>
      {!liffState.showFullPageLoader && (
        <div className="flex h-full max-h-full w-full flex-none flex-col items-center bg-white">
          <div className="w-full h-full space-y-4">
            <div className="submission-2-4 relative w-[100vw] min-h-[194vw] bg-cover bg-center text-white flex flex-col overflow-x-hidden" style={{ backgroundImage: `url(${Bg})` }}>
              <div className={`title text-[5vw] pt-3 px-2  font-bold ${finishedCount >= 3 ? 'text-right' : ''}`}>獨探索 不如眾探索！</div>
              <div className={`subtitle text-[3vw] px-2 font-bold ${finishedCount >= 3 ? 'text-right' : ''}`}>點擊進入任務，組成你的探險小隊，<br />領取好禮！</div>

              {/* Lines */}
              <div className="line-1 absolute" />

              {/* Node */}
              <div className="node-1 absolute top-[84vw] left-[74vw]">
                <div className="w-[16vw] h-[16vw] border-[2px] border-white rounded-[100vw]">
                  <div className="w-full h-full bg-center bg-cover bg-no-repeat rounded-[100vw]" style={getAvatarStyle(0)} />
                </div>
                <div className="mt-1 text-xs text-center">觀測員</div>
              </div>
              <div className="node-1 absolute top-[68vw] left-[26vw]">
                <div className="w-[16vw] h-[16vw] border-[2px] border-white rounded-[100vw]">
                  <div className="w-full h-full bg-center bg-cover bg-no-repeat rounded-[100vw]" style={getAvatarStyle(1)} />
                </div>
                <div className="mt-1 text-xs text-center">補給員</div>
              </div>
              <div className="node-1 absolute top-[24vw] left-[29vw]">
                <div className="w-[16vw] h-[16vw] border-[2px] border-white rounded-[100vw]">
                  <div className="w-full h-full bg-center bg-cover bg-no-repeat rounded-[100vw]" style={getAvatarStyle(2)} />
                </div>
                <div className="mt-1 text-xs text-center">先鋒員</div>
              </div>

              {/* People */}
              <div className={`people-3 absolute ${peoplePos} w-[19vw]`} style={{ transform: 'rotate(-45deg)' }}><img src={People3} alt="" width="100%" /></div>
              {finishedCount >= 3 && <div className="people-4 absolute w-[30vw] top-[12vw] left-[8vw]"><img src={People4} alt="" width="100%" /></div>}

              {/* Action */}
              <div className="absolute top-[110vw] w-[100vw] text-center text-sm font-bold">已成功邀請的好友</div>
              <div className="absolute top-[120vw] w-[100vw]">
                {user && <div role="presentation" className="w-[45vw] mx-auto py-[1vw] text-center border-[1px] border-white rounded-md"
                  onClick={() => shareMessage(Number(user.id), avatarUrl)}>邀請好友<Triangle className="ml-6 -mr-5" /></div>}
              </div>

              {/* Rule */}
              <div className="rule absolute bottom-0 w-[100vw] h-[50vw] bg-white rounded-tl-xl rounded-tr-xl text-black">
                <div className="title text-[5vw] font-bold text-center m-2">活動規則</div>
                <div className="mt-1 border-t-[1px] border-[#999] m-2 text-[3vw] pt-3">邀請好友加入探險隊，浮現頭像後即為成功加入。集滿3位好友完成任務，即可獲得 2,500點會員點數。</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};


export default ActivityDetailPage;
