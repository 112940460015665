import React, { memo, useState } from "react";
import { CheckboxesContext } from "./useCheckboxesContext";

const Checkboxes = ({ children, childCheckboxes }) => {
	const [checkedState, setCheckedState] = useState({});

	return (
		<CheckboxesContext.Provider
			value={{ checkedState, setCheckedState, childCheckboxes }}
		>
			{children}
		</CheckboxesContext.Provider>
	);
};

export default memo(Checkboxes);
