import { useEffect } from "react";

/**
 * useOutsideClick
 * @param {React.RefObject} ref
 * @param {Function} callback - 點擊外部後的 callback function
 * @param {boolean} isActive - 是否啟用
 */
const useOutsideClick = (ref, callback, isActive) => {
	const onClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			callback();
		}
	};

	useEffect(() => {
		// isActive 為 false 時，不啟用
		if (!isActive) return;

		document.addEventListener("click", onClickOutside);

		return () => {
			document.removeEventListener("click", onClickOutside);
		};
	}, [ref, isActive]);
};

export default useOutsideClick;
