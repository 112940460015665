import { useEffect, useState, useContext } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import liff from "@line/liff";

import { api, LiffContext } from "@/utils";
import { processMgm } from "@/submission/index";

const parsedQueryString = {};

const LiffLoginGuard = () => {
	// 是否驗證成功
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	// reducer
	const { liffActions } = useContext(LiffContext);

	const location = useLocation();
	const navigate = useNavigate();

	const initializeLiff = async () => {
		try {
			await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });

			if (!liff.isLoggedIn()) {
				liff.login({ redirectUri: window.location.href });
				return;
			}

			// 檢查 token 是否有效
			await checkToken();
		} catch (error) {
			liff.logout();
			console.error("LIFF Initialization failed:", error);
			window.location.href = `https://liff.line.me/${process.env.REACT_APP_LIFF_ID}`;
		}
	};

	// 檢查 token 是否有效
	const checkToken = async () => {
		const storedToken = localStorage.getItem("api_token");

		if (storedToken) {
			const isValidToken = await getSelf(false);
			
			if (!isValidToken) {
				await refreshAccessToken();
			} else {
				// 登入成功
				await processLoginNextStep();
			}
		} else {
			await refreshAccessToken();
		}
	};

	// 重新獲取登入 token 和用戶資料
	const refreshAccessToken = async () => {
		await getLoginApiToken();
		await getSelf();
	};

	const getLoginApiToken = async () => {
		const accessToken = liff.getAccessToken();
		const profile = await liff.getProfile();
		const friendship = await liff.getFriendship();
		// 檢查用戶有無加入官方帳號;
		if (!friendship.friendFlag) {
			console.error("Not a friend, redirecting to official account page.");
			window.location.href = process.env.REACT_APP_LINE_OA_URL;
			return;
		}
		const friendStatusChanged =
			parsedQueryString.friendship_status_changed === "true";
		const { userId: line_uid } = profile;
		const loginData = {
			line_uid,
			access_token: accessToken,
			friendship: friendship.friendFlag,
			friendship_status_changed: friendStatusChanged,
		};
		const res = await api().post("/login", loginData);
		const api_token = res.access_token;
		localStorage.setItem("api_token", api_token);

		// 登入成功
		await processLoginNextStep();
	};

	const getSelf = async (isPrcocessInvalidAppToken = true) => {
		try {
			const res = await api(0, isPrcocessInvalidAppToken).get("/me");
			liffActions.setMe(res.data);

			return true;
		} catch (error) {
			console.error("Token validation failed", error);
			return false;
		}
	};

	const processLoginNextStep = async () => {
		// 處理 mgm
		await processMgm(location, navigate);
		// 檢查是否已綁定會員資料
		await getCrmBindingStatus();
	}

	// 驗證是否綁定過會員資料
	const getCrmBindingStatus = async () => {
		try {
			const res = await api().get("/get_crm_member_binding_status");
			setIsAuthenticated(true);
			if (res.data === true) {
				liffActions.setIsMemberBound(true);
				// 繼續渲染(Outlet)
			} else {
				liffActions.setIsMemberBound(false);
				// 前往綁定頁面
				navigate("/agreement");
			}
		} catch (error) {
			console.error("CRM member info fetch failed", error);
		}
	};

	useEffect(() => {
		initializeLiff();
	}, []);

	if (!isAuthenticated) {
		return;
	}

	return <Outlet></Outlet>;
};

export default LiffLoginGuard;
