/* eslint-disable no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import liff from "@line/liff";

import { Icon, Button } from "@/atoms";
import { LiffContext, api } from "@/utils";
import { Popup } from "@/components";
import { ACTIVITY_SlUG } from "@/utils/interface/activity";
import { REWARD_SLUG } from "@/utils/interface/reward";
// img
import MgmCover from "@/assets/img/liff/mission/mgm_first/cover.png";
import User from "@/assets/img/liff/mission/user.png";
import FlexMsg1 from "@/assets/img/liff/mission/mgm_first/flex_msg_1.png";
import FlexMsg2 from "@/assets/img/liff/mission/mgm_first/flex_msg_2.png";
import { useNavigate } from "react-router-dom";
import { LINE_FRIENDSHIP_COUSTOM_DATA_TAGE } from "@/utils/interface/friendship";

const MgmFirst = (activityId, userId) => {
	const [mgmFirstMission, setMgmFirstMission] = useState();
	const [linkUrl, setLinkUrl] = useState('');
	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	const navigate = useNavigate();
	const mgmFirst = useRef();

	const sendReward = async () => {
		try {
			const res = await api().post("/send_reward_by_slug", { slug: REWARD_SLUG.FIRST_MGM });
			console.log(res);
			mgmFirst.current.showPopup();
			return res.data;
		}
		catch (err) {
			console.log(err);
			alert("獎勵發送失敗");
			return null
		}
	};

	const assetUrl = process.env.REACT_APP_ASSET_URL;

	const flexMsg = {
		type: "flex",
		altText:
			"The North Face 官網年度會員節限時活動開跑！和我一同完成任務，把品牌限定好禮帶回家！",
		contents: {
			type: "carousel",
			contents: [
				{
					type: "bubble",
					size: "giga",
					body: {
						type: "box",
						layout: "vertical",
						paddingAll: "0px",
						contents: [
							{
								type: "image",
								url: `${assetUrl}${FlexMsg1}`,
								position: "relative",
								size: "100%",
								margin: "0px",
								aspectRatio: "1040:1475",
							},
						],
						action: {
							type: "uri",
							label: "action",
							uri: linkUrl,
						},
					},
				},
				{
					type: "bubble",
					size: "giga",
					body: {
						type: "box",
						layout: "vertical",
						paddingAll: "0px",
						contents: [
							{
								type: "image",
								url: `${assetUrl}${FlexMsg2}`,
								position: "relative",
								size: "100%",
								margin: "0px",
								aspectRatio: "1040:1475",
							},
						],
						action: {
							type: "uri",
							label: "action",
							uri: linkUrl,
						},
					},
				},
			],
		},
	};

	const getActivityList = async () => {
		liffActions.setShowFullPageLoader(true);

		try {
			const list = await api().get("/get_activity_list");

			list.data.forEach(async (item) => {
				switch (item.slug) {
					case ACTIVITY_SlUG.MGM_FIRST: {
						const avatar = await getAvatar(item);
						setMgmFirstMission(avatar);

						const targetUrl = `https://liff.line.me/${process.env.REACT_APP_LIFF_ID}/`;
						const linkUrlTemp = `${targetUrl}?${LINE_FRIENDSHIP_COUSTOM_DATA_TAGE.FIRST_MGM}=${item.id}_${liffState.me.id}`;
						setLinkUrl(linkUrlTemp);
						break;
					}
				}
			});
		} catch (error) {
			console.error("Get Activity List Failed", error);
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 送出分享訊息
	const sendShareTargetPicker = async () => {
		try {
			const res = await liff.shareTargetPicker([flexMsg]);
			if (res.status === "success") {
				// 獲取任務資訊
				const avatar = await getAvatar(mgmFirstMission);
				setMgmFirstMission(avatar);
			} else {
				const [majorVer, minorVer] = (liff.getLineVersion() || "").split(".");
				if (parseInt(majorVer) === 10 && parseInt(minorVer) < 11) {
					// LINE 10.3.0 - 10.10.0
					// Old LINE will access here regardless of user's action
					console.log(
						"TargetPicker was opened at least. Whether succeeded to send message is unclear",
					);
				} else {
					// LINE 10.11.0 -
					// sending message canceled
					console.log("TargetPicker was closed!");
				}
			}
		} catch (error) {
			// something went wrong before sending a message
			console.log("Something went wrong before sending a message:", error);
		}
	};

	// 取得任務好友頭像
	const getAvatar = async (item) => {
		await Promise.all(
			item.missions.map((mission) =>
				api()
					.get("/get_mission_by_slug", {
						params: { slug: mission.slug },
					})
					.then((res) => {
						mission.avatar_url = res.data?.submissions[0]?.friend?.avatar_url;
					})
					.catch((error) => {
						console.error("No Found Activity", error);
					}),
			),
		);
		return item;
	};

	useEffect(() => {
		if (!mgmFirstMission) {
			return;
		}

		// 檢查是否是此任務
		if (mgmFirstMission.slug !== ACTIVITY_SlUG.MGM_FIRST) {
			navigate("/activity");
			return;
		}

		// 檢查是否已經邀請兩位好友
		const allInvited = mgmFirstMission?.missions?.every(
			(item) => item.is_completed == true,
		);

		if (allInvited) {
			// 顯示完成任務彈窗
			if (mgmFirstMission?.rewards.length !== 0 && mgmFirstMission?.rewards[0]?.is_completed === true) {
				mgmFirst.current.showPopup();
			}
			else {
				sendReward();
			}
		}
	}, [mgmFirstMission]);

	useEffect(() => {
		getActivityList();
	}, []);

	return (
		<>
			{!liffState.showFullPageLoader && (
				<div className="flex h-screen w-full flex-col overflow-hidden bg-mgmFirstBg bg-cover bg-center">
					{/* Top */}
					<div className="space-y-2 p-3 pt-6 text-white">
						{/* Title */}
						<div className="flex justify-between">
							<span>組成探險隊</span>
							<div className="flex items-center">
								<Icon iconName={"GiftWhite"} className="h-6 w-6"></Icon>
								<span className="ml-2">TNF 行李吊牌</span>
							</div>
						</div>
						{/* Cover */}
						<div className="h-[36vw] w-full overflow-hidden rounded-md">
							<img
								src={MgmCover}
								className="h-full w-full object-cover"
								alt="mgm cover"
							/>
						</div>
						{/* Progress */}
						<div className="mt-2 flex justify-between">
							{mgmFirstMission?.missions?.map((item) => (
								<div
									className={classNames("mx-1 h-1.5 flex-1 bg-black", {
										"bg-white": item.is_completed === true,
									})}
									key={item.id}
								></div>
							))}
						</div>
					</div>
					{/* Activity */}
					<div className="flex flex-col items-center space-y-3 p-5">
						<span className="text-lg font-bold text-white">
							已經邀請的綁定好友
						</span>
						{/* User icon  */}
						<div className="flex w-[48vw] justify-between">
							{mgmFirstMission?.missions?.map((item, index) => {
								console.log(item.avatar_url);
								return (
									<div
										key={index}
										className={classNames(
											"h-[16vw] w-[16vw] rounded-full border-[2px] border-white",
											{ "overflow-hidden ": !!item.avatar_url },
										)}
									>
										<img
											src={item.avatar_url || User}
											className={classNames(
												{
													// 未綁定好友
													"absolute left-[2vw] top-0 h-[14vw] w-[11vw]":
														!item.avatar_url,
												},
												{
													// 已綁定好友
													"h-full w-full object-cover": !!item.avatar_url,
												},
											)}
											alt="User 圖示"
										/>
									</div>
								);
							})}
						</div>
						{/* Button */}
						<Button
							type="button"
							theme="active"
							withArrow={true}
							color="white"
							borderOnly={true}
							text="邀請好友"
							contentColor="white"
							onClick={sendShareTargetPicker}
							widthFull={false}
						></Button>
					</div>
					{/* 活動辦法 */}
					<div className="h-[calc(100vh-70px)] overflow-y-auto rounded-t-lg bg-white p-3 text-black">
						<div className="border-b-2 border-gray-400 pb-2 text-center font-bold">
							活動辦法
						</div>
						<p className="whitespace-pre-line p-1 pb-20 pt-2 text-xs">
							{mgmFirstMission?.rule}
						</p>
					</div>
				</div>
			)}
			<Popup ref={mgmFirst} type="active-black" processClosePopupAction={() => { navigate("/activity") }} >
				<div className="mb-8 flex flex-col items-center font-medium text-white">
					<span>你已完成</span>
					<span>「組成探險隊」任務</span>
				</div>
				<div className="flex flex-col items-center text-xs text-white">
					<span>獎品兌換序號</span>
					<span>已發送至官方帳號</span>
				</div>
			</Popup>
		</>
	);
};

export default MgmFirst;
