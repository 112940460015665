import { useContext, useLayoutEffect } from "react";
import { LiffContext } from "@/utils";

const NotFoundPage = () => {
	// reducer
	const { liffActions, liffState } = useContext(LiffContext);

	useLayoutEffect(() => {
		liffActions.setShowFullPageLoader(false);
	}, [location, liffState.showFullPageLoader]);

	return (
		<div className="flex h-screen w-full items-center justify-center">
			<span className="mr-5 block border-e-2 py-2.5 pr-6 text-2xl font-medium">
				404
			</span>
			<span className="text-sm">This page could not be found.</span>
		</div>
	);
};

export default NotFoundPage;
