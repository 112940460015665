const useDraggable = (initialItems, setInitialItems) => {
	const reorder = (startIndex, endIndex) => {
		// 拷貝新的 showPopupList
		const result = Array.from(initialItems);

		// 剪下被拖曳的元素
		const [removed] = result.splice(startIndex, 1);

		// 貼上被拖曳的元素
		result.splice(endIndex, 0, removed);

		// result.map((row, index) => (row.order = index));

		return result;
	};

	// table拖曳
	const onDragEnd = (result) => {
		const { source, destination } = result;
		// 如果目的地都沒變就跳出
		if (!result.destination) {
			return;
		}

		const update = reorder(source.index, destination.index);
		setInitialItems(update);
	};

	return { onDragEnd };
};

export default useDraggable;
