import { useState, useRef } from "react";

import classNames from "classnames";

import { Icon } from "@/atoms";
import { useOutsideClick } from "@/hooks";

/**
 * Tooltip
 * @param {React.ReactNode} children
 * @param {string} label
 * @param {boolean} isExpired
 * @returns jsx
 */
const Tooltip = ({ children, label, isExpired }) => {
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	// 是否點擊外部關閉Tooltip
	const [activateClickOutside, setActivateClickOutside] = useState(false);

	const innerRef = useRef();

	// 開啟Tooltip
	const onShowTooltip = () => {
		setIsTooltipOpen(true);
		setTimeout(() => setActivateClickOutside(true), 0);
	};

	// 關閉Tooltip
	const onCloseTooltip = () => {
		setIsTooltipOpen(false);
		setActivateClickOutside(false);
	};

	useOutsideClick(innerRef, onCloseTooltip, activateClickOutside);

	return (
		<>
			<div ref={innerRef}>
				<button
					type="button"
					className="flex items-center text-sm "
					onClick={onShowTooltip}
				>
					{label && (
						<span
							className={classNames(
								"mr-1 ",
								{ "text-red-600": !isExpired },
								{
									"text-gray-400": isExpired,
								},
							)}
						>
							{label}
						</span>
					)}
					<Icon
						iconName={"Info"}
						fontSize={16}
						className={classNames(
							{ "text-red-600": !isExpired },
							{
								"text-gray-500": isExpired,
							},
						)}
					></Icon>
				</button>
				{isTooltipOpen && (
					<div className="absolute top-[75%] z-10 mt-3 rounded-lg bg-white p-4 shadow-custom">
						{/* Title */}

						{/* Content */}
						{children}
					</div>
				)}
			</div>
		</>
	);
};

export default Tooltip;
