import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";

import { Button, Checkbox, Checkboxes, Form, Icon } from "@/atoms";
import { LiffContext, api } from "@/utils";
import {
	useCheckboxSelection,
	useDraggable,
	useFormatAsLocalTime,
} from "@/hooks";
import { BANNER_STATUS } from "@/utils/interface/banner";

const TextCarouselListPage = () => {
	// table核取方塊子層列表
	const [childCheckboxes, setChildCheckboxes] = useState([]);
	const [showTextCarouselList, setShowTextCarouselList] = useState([]);
	// 篩選非草稿的輪播文字
	const [filteredTextCarouselList, setFilteredTextCarouselList] = useState([]);
	// table可否拖曳
	const [isDragEnabled, setIsDragEnabled] = useState(false);

	// reducer
	const { liffActions } = useContext(LiffContext);
	// checkbox選取
	const { selectedId, onSelectedItem } =
		useCheckboxSelection(showTextCarouselList);
	// 拖曳
	const { onDragEnd } = useDraggable(
		filteredTextCarouselList,
		setFilteredTextCarouselList,
	);
	const { formatAsLocalTime } = useFormatAsLocalTime();

	const navigate = useNavigate();

	// 獲取文字輪播列表
	const getBannerList = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/admin/get_banner_list", {
				params: {
					show_type: "marquee",
				},
			});

			setShowTextCarouselList(res.data);
			setFilteredTextCarouselList(res.data);
			setChildCheckboxes(res.data.map((item) => `${item.id}`));
		} catch (error) {
			console.error("Get Popup List Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	const getStatusText = (status, isLaunch) => {
		if (!isLaunch) {
			return "草稿";
		}
		switch (status) {
			case BANNER_STATUS.ON_SALE:
				return "上線";
			case BANNER_STATUS.OFF_SALE:
				return "下架";
			case BANNER_STATUS.AFTER_SALE:
				return "已結束";
			case BANNER_STATUS.BEFORE_SALE:
				return "未上線";
			default:
				return "";
		}
	};

	const updateBannerList = async () => {
		// 將草稿的id取出
		const draftIdList = showTextCarouselList
			.filter((item) => !item.is_launch)
			.map((item) => item.id);

		// 將非草稿的id取出
		const idList = filteredTextCarouselList.map((item) => item.id);

		const newIdList = [...draftIdList, ...idList];

		try {
			const res = await api().post("/admin/update_banner_list", {
				show_type: "marquee",
				id_list: newIdList,
			});

			if (res.data === "success") {
				await getBannerList();
				setIsDragEnabled(false);
			}
		} catch (error) {
			console.error("Update Banner List Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	const onSubmit = () => {};

	useEffect(() => {
		// 拖曳時，只顯示非草稿的彈窗
		if (isDragEnabled) {
			setFilteredTextCarouselList(
				filteredTextCarouselList.filter((item) => item.is_launch),
			);
		}
	}, [isDragEnabled]);

	useEffect(() => {
		getBannerList();
	}, []);

	return (
		<div className="space-y-6">
			<Button
				type="button"
				theme="admin"
				widthFull={false}
				onClick={() =>
					navigate("/admin/displayManagement/textCarouselList/add")
				}
			>
				<Icon iconName={"Add"}></Icon>
				<span>新增文案</span>
			</Button>
			<Form onSubmit={onSubmit}>
				{/* Table */}
				<Checkboxes childCheckboxes={childCheckboxes}>
					<div className="inline-block w-full bg-white align-middle">
						<div className="w-full divide-y rounded-lg border">
							{/* 上方功能區塊 */}
							<div
								className={classNames("flex h-16  px-4 py-3", {
									"justify-between": selectedId.length > 0,
									"justify-end": selectedId.length === 0,
								})}
							>
								{selectedId.length > 0 && (
									<span className="self-end text-sm text-blue-600">
										{`已選取${selectedId.length}項商品`}
									</span>
								)}

								{/* 拖曳編輯Btn */}
								{!isDragEnabled && (
									<Button
										type="button"
										theme="admin"
										widthFull={false}
										borderOnly={true}
										text="拖曳編輯順序"
										onClick={() => setIsDragEnabled(true)}
									></Button>
								)}

								{/* 拖曳時顯示 */}
								{isDragEnabled && (
									<div className="flex gap-2">
										<Button
											type="button"
											theme="admin"
											widthFull={false}
											borderOnly={true}
											text="取消"
											onClick={() => {
												setIsDragEnabled(false);
												getBannerList();
											}}
										></Button>
										<Button
											type="button"
											theme="admin"
											widthFull={false}
											text="確定"
											onClick={updateBannerList}
										></Button>
									</div>
								)}
							</div>
							<div className="overflow-x-auto">
								<table className="min-h-[150px] min-w-full divide-y divide-gray-100">
									<thead className="bg-gray-100">
										<tr>
											<th
												scope="col"
												className="sticky left-0 z-[2] bg-gray-100 px-4 py-3"
											>
												<div className="flex items-center whitespace-nowrap">
													<Checkbox
														name="selectedAll"
														isSelectAll={true}
														onChange={(e) => onSelectedItem(e, "selectedAll")}
													></Checkbox>
												</div>
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
											>
												#
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
											>
												輪播文案
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
											>
												開始時間
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-center font-medium uppercase text-blackT80"
											>
												結束時間
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-center font-medium uppercase text-blackT80"
											>
												狀態
											</th>
											<th
												scope="col"
												className="sticky right-0 z-[2] bg-gray-100 px-4 py-3"
											></th>
										</tr>
									</thead>
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable droppableId="drop-id">
											{(provided) => (
												<tbody
													className="divide-y divide-gray-100"
													ref={provided.innerRef}
													{...provided.droppableProps}
												>
													{filteredTextCarouselList.length > 0 ? (
														filteredTextCarouselList.map((item, index) => (
															<Draggable
																key={item.id}
																draggableId={`${item.id}`}
																index={index}
																isDragDisabled={!isDragEnabled} // 是否可以拖曳
															>
																{(provided) => (
																	<tr
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		className={classNames(
																			"group bg-white hover:bg-gray-50",
																			{
																				"cursor-pointer": isDragEnabled,
																			},
																			{
																				"bg-yellow-50 hover:bg-yellow-50":
																					!item.is_launch,
																			},
																		)}
																	>
																		<td
																			className={classNames(
																				"sticky left-0 z-[2] bg-white px-4 py-3 group-hover:bg-gray-50",
																				{
																					"bg-yellow-50 group-hover:bg-yellow-50":
																						!item.is_launch,
																				},
																			)}
																		>
																			<div className="flex h-5 items-center">
																				<Checkbox
																					name={`${item.id}`}
																					onChange={(e) =>
																						onSelectedItem(e, item.id)
																					}
																				></Checkbox>
																			</div>
																		</td>
																		{/* 預設順序 */}
																		<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																			{index + 1}
																		</td>
																		{/* 彈窗圖片 */}
																		<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																			<span>{item.text}</span>
																		</td>
																		{/* 開始時間 */}
																		<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																			{formatAsLocalTime(
																				item.start_date,
																				"YYYY-MM-DD HH:mm",
																			)}
																		</td>
																		{/* 結束時間 */}
																		<td className="whitespace-nowrap px-6 py-4 text-center text-blackT80">
																			{formatAsLocalTime(
																				item.end_date,
																				"YYYY-MM-DD HH:mm",
																			)}
																		</td>
																		{/* 狀態 */}
																		<td className="whitespace-nowrap px-6 py-4 text-center text-blackT80">
																			{getStatusText(
																				item.status,
																				item.is_launch,
																			)}
																		</td>
																		{/* 修改按鈕 */}
																		<td
																			className={classNames(
																				"sticky right-0 z-[2] bg-white px-4 py-3 group-hover:bg-gray-50",
																				{
																					"bg-yellow-50 group-hover:bg-yellow-50":
																						!item.is_launch,
																				},
																			)}
																		>
																			<div className="flex h-5 items-center">
																				<Button
																					type="button"
																					theme="admin"
																					size="sm"
																					text="編輯"
																					widthFull={false}
																					borderOnly={true}
																					onClick={() =>
																						navigate(
																							`/admin/displayManagement/textCarouselList/edit/${item.id}`,
																						)
																					}
																				></Button>
																			</div>
																		</td>
																	</tr>
																)}
															</Draggable>
														))
													) : (
														<tr>
															<td
																colSpan="100%"
																className="py-4 text-center font-medium"
															>
																尚無資料
															</td>
														</tr>
													)}
													{provided.placeholder}
												</tbody>
											)}
										</Droppable>
									</DragDropContext>
								</table>
							</div>
							{/* 下方功能區塊 */}
							<div className="h-16 px-4 py-1"></div>
						</div>
					</div>
				</Checkboxes>
			</Form>
		</div>
	);
};

export default TextCarouselListPage;
