import { useState } from "react";
import { PopupLayout } from "@/components/popupLayout";
import CloseButton from "@/assets/img/liff/close-button.png";
import dayjs from "dayjs";
import "./index.sass";

const Coupon = ({ coupon }) => {
	const [ isInstructionsPopupOpen, setIsInstructionsPopupOpen ] = useState(false);

	function formatDate(dateString) {
		return dayjs(dateString).format('YYYY/MM/DD');
	}

	const handleInstructionButtonClick = () => {
		setIsInstructionsPopupOpen(true);
	};

	return (
		<div className="cCoupon">
			{/* 使用說明 */}
			<PopupLayout
				isOpen={isInstructionsPopupOpen}
				handleOutsideClick={false}
				onClose={() => setIsInstructionsPopupOpen(false)}
				animation="slide"
				zIndex={19}
			>
					<div className="coupon-instruction-popup">
						<div className="top-bar">
							<label>{`使用說明`}</label>
							<img className="close-btn" src={CloseButton} onClick={() => setIsInstructionsPopupOpen(false)}></img>
						</div>
						<div className="content-container">
							<div className="content">
								<div className="section">
									<label>優惠卷名稱：</label>
									<label>{coupon.couponName}</label>
								</div>
								<div className="section">
									<label>優惠卷副標：{coupon.couponTitle}</label>
								</div>
								<div className="section">
									<label>使用期間：{`${formatDate(coupon.validTime)} - ${formatDate(coupon.invalidTime)}`}</label>
								</div>
								<div className="section">
									<label>使用說明：</label>
									<label>{coupon.useRuleDesc}</label>
								</div>
							</div>
							<div className="shadow"></div>
						</div>
					</div>
			</PopupLayout>
			<img className="coupon-background" src={coupon.image}></img>
			<div className="description" style={{color: coupon.textColor}}>
				<label className="name">{coupon.couponName}</label>
				<label className="title">{coupon.couponTitle}</label>
				<label className="date">{`${formatDate(coupon.validTime)} - ${formatDate(coupon.invalidTime)}`}</label>
			</div>
			<div className="instruction-button" onClick={()=>handleInstructionButtonClick()}/>
		</div>
	);
};

export { Coupon };
