/* eslint-disable no-unused-vars */
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { AppError, LiffContext, api } from "@/utils";
import { Button, Form, Input } from "@/atoms";

import TnfLogo from "@/assets/img/admin/tnf-logo.svg";

const LoginPage = () => {
	const [userAccount, setUserAccount] = useState({
		email: "test@test.com",
		password: "Root_2024_test",
	});
	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	const navigate = useNavigate();

	// 登入api
	const adminLogin = async (loginData) => {
		try {
			const res = await api().post("/admin/login", loginData);

			const api_token = res.access_token;
			localStorage.setItem("api_token", api_token);

			navigate("/admin/productList");
		} catch (error) {
			console.error("Login Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_USER_OR_PASSWORD:
						toast.error("帳號或密碼錯誤，請再試一次。");
						break;

					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	const onSubmit = (data) => {
		adminLogin(data);
	};

	useEffect(() => {
		console.log(userAccount);
	}, [userAccount]);

	useLayoutEffect(() => {
		liffActions.setShowFullPageLoader(false);
	}, [location, liffState.showFullPageLoader]);

	return (
		<div className="flex h-screen w-full flex-col items-center justify-center bg-adminLoginBg bg-cover bg-center p-7">
			<div className="w-72 rounded bg-white p-5 md:w-80">
				<div className="my-6 flex justify-center">
					<img src={TnfLogo} alt="Tnf logo" className="w-12" />
					<span className="ml-3 text-xl font-bold">點數館</span>
				</div>
				<div className="mb-5 text-center">
					<span className="mb-2 block text-xl font-bold">管理系統</span>
					<hr />
				</div>
				<Form onSubmit={onSubmit} defaultValues={userAccount}>
					<div className="flex flex-col gap-4">
						<div>
							<Input
								type="email"
								name="email"
								theme="admin"
								label="帳號"
								placeholder="請輸入帳號"
								required={true}
								pattern={/^\S+@\S+$/}
							></Input>
						</div>

						<div>
							<Input
								type="password"
								name="password"
								theme="admin"
								label="密碼"
								placeholder="請輸入密碼"
								required={true}
								maxLength={50}
								minLength={6}
							></Input>
						</div>

						<div className="mt-6">
							<Button
								type="submit"
								text="登入"
								theme="admin"
								widthFull={true}
							></Button>
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default LoginPage;
