import React, { useState, useImperativeHandle, forwardRef } from "react";

const ToastManager = forwardRef((_, ref) => {
	const [toasts, setToasts] = useState([]);

	useImperativeHandle(ref, () => ({
		addToast(content, duration = 3000) {
			const id = Date.now();
			const newToast = { id, content, visible: false };

			setToasts((prevToasts) => [...prevToasts, newToast]);

			setTimeout(() => {
				setToasts((prevToasts) =>
					prevToasts.map((toast) =>
						toast.id === id ? { ...toast, visible: true } : toast,
					),
				);
			}, 10);

			setTimeout(() => {
				removeToast(id);
			}, duration);
		},
	}));

	const removeToast = (id) => {
		setToasts((prevToasts) =>
			prevToasts.map((toast) =>
				toast.id === id ? { ...toast, visible: false } : toast,
			),
		);

		setTimeout(() => {
			setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
		}, 300);
	};

	return (
		<div style={containerStyles}>
			{toasts.map((toast) => (
				<div
					key={toast.id}
					style={{
						...toastStyles,
						opacity: toast.visible ? 1 : 0,
						transform: toast.visible ? "translateY(0)" : "translateY(-20px)",
					}}
				>
					{toast.content}
				</div>
			))}
		</div>
	);
});

const containerStyles = {
	position: "fixed",
	top: "20px",
	left: 0,
	right: 0,
	display: "flex",
	flexDirection: "column",
	gap: "10px",
	zIndex: 1000,
};

const toastStyles = {
	display: "flex",
	justifyContent: "center",
	color: "white",
	padding: "10px 20px",
	borderRadius: "5px",
	boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
	transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
	opacity: 0,
	transform: "translateY(-20px)",
};

ToastManager.displayName = "ToastManager";

export default ToastManager;
