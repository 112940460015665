export const MISSION_SlUG = {
	MGM_FIRST_1: "mgm_first_1",
	MGM_FIRST_2: "mgm_first_2",
	FILL_IN_MEMBER_DATA: "fill_in_member_data",
	ONE_TO_ONE: "one_to_one",
	GO_TO_OA_WEB: "go_to_oa_web",
	TOP_MGM: "mem_top",
};

export const MISSION_ID = {
	TOP_MGM: 7,
};

export const MISSTION_PERFORM_RESULT = {
	SUCCESS: "success",
	FAIL: "fail",
	MISSION_UNFINISHED: "mission_unfinished",
	ALREADY_RECEIVED: "already_received",
	LIMIT_FULL: "limit_full",
	TIME_LIMIT_REPAET: "time_limit_repeat",
};

export const ACTIVITY_SlUG = {
	PINNED_ACTIVITY: "pinned_activity",
	WINTER_HOLIDY: "winter-holiday",
	MGM_FIRST: "mgm_first",
};

export const DEF_PUBLISH_STATUS = {
	PUBLISH: "publish",
	CLOSED: "closed",
	DEAFT: "draft",
};
