const PrivacyPolicy = () => {
	return (
		<>
			<p className="pb-2 text-sm leading-normal">
				本隱私政策描述了我們可能向您收集的資訊類型，我們收集、使用資訊的方式，以及您對您的資訊享有的選擇權。一旦您使用我們的網站或Mobile
				APP，或以其他方式向我們提供資訊，
				<span className="underline">即表示您同意受本隱私政策約束。</span>
			</p>
			<p className="pb-2 text-sm leading-normal">
				本隱私政策的最新更新日期為2021年7月，且可能不時更新。請定期查看本隱私政策，瞭解進一步的更新和變更。本隱私政策的最新更新版本，將在本網站上提供。
			</p>
			<p className="py-2 text-sm font-medium leading-relaxed">
				收集您的資料的範圍
			</p>
			<p className="pb-2 text-sm leading-normal">
				本隱私政策僅適用於威富所擁有及經營的網站。威富網站內可能包含許多連結、或其他合作夥伴及個別開店店家所提供的商品或服務，關於該等連結網站、合作夥伴網站或個別開店店家之網路店家的隱私權聲明及與個人資料保護有關之告知事項，請參閱各該連結網站、合作夥伴網站或個別開店店家之網路店家。
			</p>
			<p className="py-2 text-sm font-medium leading-relaxed">
				收集您的資料的目的
			</p>
			<div className="pb-2 text-sm leading-normal">
				我們可能為了以下所有或任何目的，使用並保留您向我們提供的所有個人資訊和資料，和/或與您的會員身份相關的所有資訊和資料：
				<ul className="list-disc pl-4">
					<li>處理您的會員申請；</li>
					<li>向您提供我們的會員計畫項下的服務；</li>
					<li>
						我們和/或VF
						Corporation的任何品牌或其在全球的任何子公司（「威富」）開展商品/服務行銷，和/或提供贈品；
					</li>
					<li>我們和/或任何威富品牌向您提供精選產品/服務、促銷活動和福利；</li>
					<li>就與您的會員身份相關的所有事宜，與您進行溝通；</li>
					<li>管理、營運和維護我們的會員計畫；</li>
					<li>
						我們和/或任何威富品牌設計全新的商品和/或贈品和/或服務，或改進現有商品和/或贈品和/或服務；
					</li>
					<li>調查投訴或可疑交易；</li>
					<li>預防或發現犯罪行為；</li>
					<li>按照適用法律和/或法規要求進行揭露；</li>
					<li>統計分析和/或研究；</li>
					<li>確保我們滿足或遵守行業慣例和/或習慣；</li>
					<li>
						在轉讓我們的業務或我們對您的個人資訊享有的權利時，使實際或擬受讓人能夠評估交易意向；及
					</li>
					<li>與上述所有或任何目的相關的，或上述目的所附帶的任何其他目的。</li>
				</ul>
			</div>
			<p className="py-2 text-sm font-medium leading-relaxed">
				收集您的資料的方式
			</p>
			<div className="pb-2 text-sm leading-normal">
				我們可能通過以下任何一種方式收集您的個人資訊和資料：
				<ul className="list-disc pl-4">
					<li>威富品牌網站；</li>
					<li>威富零售店鋪；</li>
					<li>威富零售代表；</li>
					<li>威富品牌行銷或銷售活動；</li>
					<li>威富品牌Mobile APP；</li>
					<li>威富指定合作廠商；及</li>
					<li>威富品牌與您互動的任何其他方式。</li>
				</ul>
			</div>
			<p className="py-2 text-sm font-medium leading-relaxed">自動收集的資訊</p>
			<p className="pb-2 text-sm leading-normal">
				當您與我們互動時，我們將自動收集並存儲特定的其他非個人可識別資訊。例如，當您訪問本網站時，我們可能收集您的網路協議（IP）位址、瀏覽器資訊和參考網站功能變數名稱，並分析這些資訊，以瞭解使用者偏好、趨勢並統計網站使用資料。
			</p>
			<p className="pb-2 text-sm leading-normal">
				同時，我們也可能通過cookie、網路信標和相關技術收集資訊，以分析、瞭解您使用網站的方式，提高您對網站的訪問次數。
			</p>
			<p className="pb-2 text-sm leading-normal">
				“Cookie”指網站為了儲存資料，通過瀏覽器發送至使用者硬碟的小型文字檔。大多數瀏覽器預設允許cookie運行，但也允許您關閉cookie。若您選擇關閉或以其他方式拒絕cookie，您可能無法充分利用本網站提供的所有產品/服務。我們建議您將cookie保持在「開啟」狀態，以便獲得更好的購物體驗。
			</p>
			<p className="pb-2 text-sm leading-normal">
				我們聘用合作廠商公司提供網路資料託管服務，幫助我們測量並確定訪問者使用我們網站的方式，以及我們的網站和促銷活動的有效性，從而幫助我們改善、優化網站以及我們提供的產品和服務。同時，我們聘用合作廠商公司代表我們發佈網路廣告（橫幅廣告或連結）。這些公司採用跟蹤技術（包括cookie和圖元標籤），基於您對我們網站的訪問和您與我們的產品和服務的交互，收集相關非個人可識別資訊，以根據您的興趣和您對我們網站的瀏覽記錄，代表我們和其他廣告商提供互聯網定製化廣告（再行銷）。我們利用合作廠商受眾資料（例如：年齡、性別和興趣）更好地瞭解訪問我們網站的受眾，並向他們進行推廣。我們不使用再行銷清單、cookie、資料饋送或其他匿名識別字，亦不將其與個人可識別資訊連結。
			</p>
			<p className="pb-2 text-sm leading-normal">
				此外，我們發送給您的一些電子郵件可能包含「點擊URL」，這些URL連結至網站特定頁面，點擊它們，您將自動進入我們的網站，我們可能收集與「點擊」相關的資訊。您可以選擇不點擊電子郵件中的URL連結，從而避免該等資訊被收集。
			</p>
			<p className="pb-2 text-sm leading-normal">
				同時，出於營運和行銷目的（例如測量和分析流量規律），我們還在我們的店鋪中使用了各種零售分析工具。這些工具可從感測器（包括攝影機、智慧手機探測器、Wi-Fi網路或其他設備）收集資料，從而改善您的店內體驗。
			</p>
			<p className="py-2 text-sm font-medium leading-relaxed">
				我們收集的個人資訊和資料的類型
			</p>
			<div className="pb-2 text-sm leading-normal">
				我們可能要求提供、收集並儲存以下部分或所有類型的您的個人資訊和資料：
				<ul className="list-disc pl-4">
					<li>
						姓名、帳單地址、收貨地址、居住地址、E-MAIL、電話號碼、財務和付款資訊（例如信用卡號碼和到期日）等為完成收款或付款所需之資料、性別、個人興趣、出生日期、我們發貨時您提供的第三方資訊、IP位址，及其他得以直接或間接識別使用者身分之個人資料。同時，我們還可能保留您的產品興趣記錄和購買記錄；
					</li>
					<li>您下載或使用我們的Mobile APP時的位置和使用的行動裝置；</li>
					<li>
						您使用社群網站（例如：Facebook、Instagram、Twitter或微信）訪問我們的網站和應用程式時，我們可能從該等社群網站接收的您的個人資訊；
					</li>
					<li>您在任何威富品牌會員申請表中向我們提供的資訊；</li>
					<li className="underline">
						可用於識別您的身份的以任何方式捕捉的生物識別資料和資訊（例如：臉部特徵、指紋等）。
					</li>
				</ul>
			</div>
			<p className="py-2 text-sm font-medium leading-relaxed">兒童隱私</p>
			<p className="pb-2 text-sm leading-normal">
				我們無意收集不滿14周歲的任何人的個人資訊。如果您不滿14周歲，您不得向我們提供任何個人資訊。如果您是不滿14周歲的兒童的父母或監護人，且懷疑其已向我們提供個人資訊，您可通過本隱私政策所述溝通方式聯繫我們，行使您作為父母或監護人的權利。
			</p>
			<p className="py-2 text-sm font-medium leading-relaxed">個人資訊的利用</p>
			<p className="pb-2 text-sm leading-normal">
				威富所蒐集的足以識別使用者身分的個人資料、消費與交易資料，或日後經您同意而提供之其他個人資料，都僅供威富於其內部、依照蒐集之目的進行處理和利用、或為完成提供服務或履行合約義務之必要、或依照相關法令規定或有權主管機關（包括但不限於法院或台灣票據交換所）之命令或要求，否則威富不會將足以識別使用者身分的個人資料提供給本隱私權條款未臚列的第三人（包括境內及境外）、或移作蒐集目的以外之使用。威富會持續保管、處理及利用相關資料。在上開蒐集目的範圍內，本商店會將所蒐集的個人資料，提供予合作廠商（包括但不限提供本服務之宅配貨運業者或其他）以協助交易完成或終止。若選擇以信用卡方式支付，僅限信用卡持卡人本人使用，且得就此交易資料向發卡銀行、收單行及持卡人照會並確認是否屬實，於核對無誤後付款程序才算完成。依上開蒐集目的所取得的個人資料，可能會傳送至您的國家或地區以外的地方進行保存或處理，且於符合個人資料保護法的情況下才會將您的資訊傳送至境外。
			</p>
			<p className="py-2 text-sm font-medium leading-relaxed">
				您的個人資訊和資料的轉移
			</p>
			<p className="pb-2 text-sm leading-normal">
				我們可能為了上文所述所有或任何目的，向下列主體揭露、轉移您的個人資訊：威富所有或控制的任何公司（不論是否位於亞洲）；合作廠商獎勵、忠誠或優惠計畫提供商；聯合品牌或交叉行銷零售合作夥伴或慈善或非盈利組織；外部服務提供者（包括但不限於我們的代理商、承包商、分包商、電信運營商、郵寄服務提供者、直銷代理商、電話行銷服務提供者、客服中心、資料處理服務提供者、資訊技術提供商）；對我們承擔保密義務的任何其他方，以及受讓我們的業務或我們對您的個人資訊享有的權利的任何實際或擬受讓人。
			</p>
			<p className="py-2 text-sm font-medium leading-relaxed">資訊共用方</p>
			<div className="pb-2 text-sm leading-normal">
				我們可能為了直接行銷的目的（包括下列目的），與VF
				Corporation品牌家族中的其他品牌（例如，包括Altra ®、Bulwark Protection
				®、Dickies ®、Eagle creek ®、Eastpak ®、Horace Small ®、Icebreaker
				®、Jansport ®、Kipling ®、Kodiak ®、Napapijri ®、Red Kap
				®、Smartwool ®、Terra ®、The North Face ®、Timberland ®和Vans
				®）共用您的個人資訊：
				<ul className="list-disc pl-4">
					<li>管理您對我們的簡報、郵寄清單或忠誠計畫的訂閱或參與相關事宜；</li>
					<li>使您能夠參與促銷活動和其他計畫（例如：比賽和抽獎）；</li>
					<li>
						通過電子郵件或其他電子通訊方式（例如：SMS、MMS、傳真等），向您發送（您可選擇是否同意）有關以下方面的促銷資訊和資料：我們的產品和服務；關於價格和促銷的特別計畫；其他計畫（例如：忠誠計畫、威富集團組織或參與的活動和展會）；
					</li>
					<li>改進我們的產品和服務；</li>
					<li>調查研究目的；</li>
					<li>用戶畫像；</li>
				</ul>
			</div>
			<p className="pb-2 text-sm leading-normal">
				我們可能與為上述目的代表我們履行服務的特定合作廠商共用個人資訊，該等合作廠商包括但不限於網站營運方、網站託管方、您要求的產品和服務的提供方、您的付款的授權和處理方、產品完成方、詐欺保護和信用風險降低方、產品定製方、行銷和促銷資料分發方、忠誠計畫管理方、促銷執行方、網站評估方、資料分析方和（如果適用）資料清理方。
			</p>
			<p className="pb-2 text-sm leading-normal">
				在法律要求我們向第三方揭露個人資訊的情況下，我們可能與該第三方共用個人資訊。
			</p>
			<p className="py-2 text-sm font-medium leading-relaxed">
				第三方利用與廣告（分析）服務
			</p>
			<p className="pb-2 text-sm leading-normal">
				為優化您的消費體驗，我們提供相關第三方認證服務的功能，第三方認證業者如Google、Facebook、Apple、Line等，讓您可以利用此功能，更快速地完成威富網站的註冊與登入流程。同時，為了讓您能更輕易地接觸所偏好的商品資訊，我們會在不同平台對您作優化廣告投放及使用流程的目的範圍內，將您的個人資料分享給提供廣告分析、投放的服務業者如Google、Facebook、Line、Yahoo、Criteo、Adobe、Tagtoo，另該些服務業者得以為您進行精準的商品廣告安排，此外，我們也已安裝
				Google Analytics (分析)
				廣告功能。如您欲停用此類功能及服務，建議您可參考Google所提供不透露資訊的方式以停用該功能，或與我們聯繫，我們會提供必要的協助。
			</p>
			<p className="py-2 text-sm font-medium leading-relaxed">
				您的個人資訊和資料的安全
			</p>
			<div className="pb-2 text-sm leading-normal">
				<ul className="list-disc pl-4">
					<li>
						我們高度重視您的資訊安全。我們已建立相應安全流程和措施，保護我們儲存個人資訊的伺服器和網路。但是，在現有技術條件下，網路資訊轉移不可能做到100%安全，因此，我們無法確保或保證通過網路轉移的資訊的安全性。
					</li>
					<li>
						為了保護您向我們提供的個人資訊，您：
						<ul className="list-none pl-4">
							<li>（i）不得與其他人分享您的密碼或帳戶資訊，</li>
							<li>（ii）應使用安全的網頁瀏覽器，和</li>
							<li>（iii）應經常修改您的密碼。</li>
						</ul>
					</li>
					<li>
						與我們合作的任何合作廠商，均無權為了履行我們指定的服務之外的任何目的，使用或揭露您在本網站或通過本網站向我們提供的個人資訊。
					</li>
					<li>
						若發生個人資訊安全意外事件，我們將立即通過電子郵件、信件、電話、推播通知等方式告知您相關資訊。若難以逐一向個人資訊主體告知，我們將採取合理、有效的方式發佈公告。另請留意，手機號碼是關聯您會員身分的核心認證資訊，若您手機遺失、更換手機號碼、或有變更手機號碼所有權名義時，請務必與我們聯繫，進一步完成必要的個人資訊變更，以確保您的個人資料、交易資料的安全性與機密性；若您未提出前述資訊變更需求，須自行負擔可能發生的一切損害。
					</li>
					<li>
						除非適用法律規定了更短或更長的保留期限，否則我們保留個人資料的期限，不會長於實現本隱私政策所述具體資料處理目的所必需的時間。
					</li>
				</ul>
			</div>
			<p className="py-2 text-sm font-medium leading-relaxed">
				您的權利、查詢權和更正權
			</p>
			<div className="pb-2 text-sm leading-normal">
				您有權：
				<ul className="list-disc pl-4">
					<li>確認我們是否保留了您的任何個人資訊；</li>
					<li>要求查詢、閱覽我們所保留您的個人資訊或要求給予複本；</li>
					<li>
						要求我們更正或更新任何不準確的個人資訊；如您所提供之資料錯誤或缺漏，則不受本隱私政策之保護；
					</li>
					<li>
						（隨時）確認我們與個人資訊有關的政策和慣例和我們保留的個人資訊的類型；
					</li>
					<li>
						選擇不再允許我們或其他方將您的個人資料用於直接行銷。若要做出此選擇，請於直效行銷郵件內，點選取消訂閱連結。
					</li>
					<li>
						要求我們刪除、停止處理或利用我們所保留您的個人資料，但因威富執行職務或業務所必須者，不在此限。因應歐盟個人資料保護法規（European
						Union General Data Protection Regulation,
						GDPR），歐盟國家使用者將暫停使用該相關網站之各服務。若您有任何詢問，請與客服中心聯絡。若您欲行使刪除請求權，請與客服中心聯絡，我們得酌收必要成本費用，並將處理結果以書面通知您；
					</li>
					<li>刪除用戶帳戶；和</li>
					<li>撤回您的同意。</li>
				</ul>
			</div>
			<p className="pb-2 text-sm leading-normal">
				與我們的政策、慣例以及我們保留的個人資訊的類型相關的所有個人資訊查閱或更正申請，均應採用書面形式，並發送至以下地址：
			</p>
			<p className="pb-2 text-sm leading-normal">收件人：客戶服務部</p>
			<p className="pb-2 text-sm leading-normal">
				地址： 臺北市信義區信義路5段7號37樓
			</p>
			<p className="pb-2 text-sm leading-normal">
				e-mail： consumer_privacy_APAC@vfc.com
			</p>
			<p className="pb-2 text-sm leading-normal">我們將儘快回覆您的要求。</p>
		</>
	);
};

export default PrivacyPolicy;
