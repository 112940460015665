import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const AuthPage = () => {
	const router = useLocation();

	useEffect(() => {
		const liffUrl = `https://liff.line.me/${process.env.REACT_APP_LIFF_ID}`;
		const searchParams = new URLSearchParams(router.search);
		const targetUrl = searchParams.get('target_url');
		if (router.pathname === '/auth' && targetUrl) {
			const newLiffUrl = `${liffUrl}?target_url=${targetUrl}`
			const liffUrlEncode = encodeURIComponent(newLiffUrl);
			const channelId = process.env.REACT_APP_LINE_CHANNEL_ID;
			const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${channelId}&redirect_uri=${liffUrlEncode}&bot_prompt=aggressive&scope=profile&state=0`;
			window.location.href = url;
		}
	}, []);

	return (
		<div>
		</div>
	);
};

export default AuthPage;
