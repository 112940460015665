import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import dayjs from "dayjs";
import toast from "react-hot-toast";

import { Form, Input, Icon, Button, PhoneNumberField } from "@/atoms";
import { Popup } from "@/components";
import { api, AppError, LiffContext } from "@/utils";

import HomeLogo from "@/assets/img/liff/tnf-logo.svg";

let validationId = "";

const PhoneBindingPage = () => {
	const location = useLocation();
	// 是否發送驗證碼
	const [isSmsSent, setIsSmsSent] = useState(false);
	const [endTime, setEndTime] = useState(null);
	// 剩餘時間
	const [remainingTime, setRemainingTime] = useState(15);
	// 是否可重新發送
	const [resendAllowed, setResendAllowed] = useState(false);
	const [phone, setPhone] = useState("");
	const [isBtnLoading, setIsBtnLoading] = useState(false);

	const pinRef = useRef(null);
	const errorPopupRef = useRef(null);
	const navigate = useNavigate();

	// reducer
	const { liffState } = useContext(LiffContext);

	const onPhoneChange = (newPhone) => {
		// 更新電話號碼
		setPhone(newPhone);
	};

	// 送出手機號碼
	const onPhoneNumSubmit = () => {
		createValidations();

		// 設置終止時間為現在加 15 秒
		const newEndTime = dayjs().add(15, "seconds");
		setEndTime(newEndTime);
		setIsSmsSent(true);
		setResendAllowed(false);
	};

	// 送出簡訊驗證碼
	const onValidationsSubmit = (data) => {
		const pinNumber = parseInt(data.pin, 10);

		if (!isNaN(pinNumber)) {
			createValidationsVerify(pinNumber);
		} else {
			toast.error("無效的驗證碼格式。");
		}
	};

	// 發送驗證碼api
	const createValidations = async () => {
		setIsBtnLoading(true);
		try {
			const res = await api().post("/mobile_validations", { mobile: phone });
			validationId = res.data.id;
		} catch (error) {
			console.error("Create validations failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;

				switch (errorCode) {
					case AppError.VERIFICATION_CODE_IN_COOLDOWN:
						toast.error("請15秒後再試。");
						break;
					case AppError.VERIFICATION_CODE_CANNOT_SEND:
						toast.error("無法發送驗證碼，請再試一次。");
						break;
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			// 清空輸入驗證碼欄位
			pinRef.current.reset();
			setIsBtnLoading(false);
		}
	};

	// 檢查檢查驗證碼是否有效
	const createValidationsVerify = async (pinNumber) => {
		setIsBtnLoading(true);
		try {
			const res = await api().post(
				`/mobile_validations/${validationId}/verify`,
				{
					pin: pinNumber,
				},
			);

			const mobile_verification_token = res.data.token;

			// 檢查驗證碼token是否有效
			createRegisterCrm(mobile_verification_token);
		} catch (error) {
			console.error("Create validations verify", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_FORMAT:
					case AppError.INVALID_VERIFICATION_CODE:
						toast.error("驗證碼錯誤。");
						break;
					case AppError.VERIFICATION_CODE_FAILED_TOO_MANY_TIMES:
						toast.error("驗證碼錯誤3次，請重新發送驗證碼。");
						break;
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
			setIsBtnLoading(false);
		}
	};

	// 檢查驗證碼token是否有效
	const createRegisterCrm = async (token) => {
		try {
			await api().post("/register_crm_member", {
				mobile: phone,
				mobile_verification_token: token,
			});
			// localStorage.setItem("isMemberBound", "true");
			await userFromMgmFirst();
			navigate("/bindingSuccess", { state: { fromBinding: true } });
		} catch (error) {
			console.error("Create register member", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_FORMAT:
						toast.error("發生錯誤，請再試一次。");
						break;
					case AppError.CRM_FAILED:
					case AppError.MAAC_FAILED:
						errorPopupRef.current.showPopup();
						break;
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
			setIsBtnLoading(false);
		}
	};

	// 綁定成功者是否為透過mgmFirst
	const userFromMgmFirst = async () => {
		try {
			const res = await api().post("/submission_completed_mgm_first", {
				activity_id: 1,
			});
			console.log(res.data);
		} catch (error) {
			console.error("User from mgm first failed", error);

			// 若發生錯誤，無需處理
		}
	};

	// 更新剩餘時間
	function updateRemainingTime(endTime) {
		const now = dayjs();
		const secondsLeft = endTime.diff(now, "second");
		setRemainingTime(secondsLeft);

		if (secondsLeft <= 0) {
			// 重置狀態
			setIsSmsSent(false);
			setResendAllowed(true);
		}
	}

	// 簡訊可重新發送倒數
	useEffect(() => {
		let timer;
		if (endTime) {
			updateRemainingTime(endTime);

			timer = setInterval(() => {
				updateRemainingTime(endTime);
			}, 1000);
		}

		return () => {
			if (timer) clearInterval(timer);
		};
	}, [endTime]);

	useEffect(() => {
		// 若已綁定電話
		if (liffState.isMemberBound) {
			navigate("/");
			return;
		}

		// 若沒勾過同意書
		if (!location.state?.isCheck) {
			navigate("/agreement");
			return;
		}
	}, []);

	return (
		<>
			<div className="flex w-full flex-col">
				<header className="flex items-center justify-between border-b border-solid border-b-gray-200 p-5">
					<div className="flex-none">
						<p className="text-2xl font-medium">請輸入手機號碼</p>
						<p className="text-base text-[#666666]">
							綁定 LINE 官方帳號 享受個人化服務
						</p>
					</div>
					<div className="flex flex-1 items-center pl-4">
						<img src={HomeLogo} alt="" className="h-12 w-16" loading="lazy" />
					</div>
				</header>
			</div>
			<div className="space-y-4 p-4 py-8">
				<Form onSubmit={onPhoneNumSubmit}>
					<PhoneNumberField
						onChange={onPhoneChange}
						value={phone}
						name="phone"
						label="手機號碼"
						required={true}
						placeholder="請輸入手機號碼"
						type="tel"
					>
						<div className="absolute right-0 top-1/2 -translate-y-1/2 pr-3.5">
							{/* 倒數 */}
							{isSmsSent && remainingTime > 0 && (
								<button
									className="flex h-8 items-center justify-center px-1.5 py-1 text-sm font-medium text-customGray"
									disabled
								>
									{`${remainingTime}秒後可重新發送`}
								</button>
							)}

							{/* 重新發送 */}
							{resendAllowed && (
								<button
									className="flex h-8 items-center justify-center px-1.5 py-1 text-sm font-medium text-black"
									type="submit"
								>
									重新發送
									<Icon iconName={"Refresh"} fontSize={20}></Icon>
								</button>
							)}
						</div>
					</PhoneNumberField>
					{!isSmsSent && !resendAllowed && (
						<div className="mt-4">
							<Button
								type="submit"
								theme="default"
								color="black"
								text="發送簡訊驗證碼"
								showBtnLoader={isBtnLoading}
							></Button>
						</div>
					)}
				</Form>
				{(isSmsSent || resendAllowed) && (
					<Form onSubmit={onValidationsSubmit} ref={pinRef}>
						<Input
							name="pin"
							label="簡訊驗證碼"
							type="text"
							placeholder="請輸入簡訊中的驗證碼"
							maxLength="4"
							minLength="4"
							required={true}
							requiredMark={false}
						></Input>
						<div className="mt-4">
							<Button
								type="submit"
								theme="default"
								color="black"
								text="驗證"
								showBtnLoader={isBtnLoading}
							></Button>
						</div>
					</Form>
				)}
			</div>
			<Popup ref={errorPopupRef}>
				<span className="flex justify-center text-xl">
					發生錯誤，請聯繫客服。
				</span>
			</Popup>
		</>
	);
};

export default PhoneBindingPage;
