const NoRecords = ({ title, text, children }) => {
	return (
		<div className="flex items-center justify-between px-4 py-2">
			<div className="flex flex-col">
				<span>{title}</span>
				<span className="text-sm text-gray-500">{text}</span>
			</div>
			{children}
		</div>
	);
};

export default NoRecords;
