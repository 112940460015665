import { useContext, useState, useRef } from "react";
import { LiffContext } from "@/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { ProfileCard } from "./ProfileCard";
import { Popup, LiffAvatar } from "@/components";
import QRCode from "react-qr-code";
import CarouselButton from "@/assets/img/liff/carousel_button.webp";
import { TierStyle, V0, V1, V2, V3, V4 } from "@/utils/tierStyles";

const ProfileCardCarousel = ({ className }) => {
	const { liffState } = useContext(LiffContext);
	const currentTierCode = liffState.memberData?.tierCode;
	const initialSlide = new TierStyle().getTierStyleByCode(
		currentTierCode,
	).tierSeq;
	const [currentIndex, setCurrentIndex] = useState(initialSlide);
	const swiperRef = useRef(null);
	const qrCodePopupRef = useRef();

	const handleOpenQrPopup = () => {
		qrCodePopupRef.current.showPopup();
	};

	const handleSlideChange = (swiper) => {
		setCurrentIndex(swiper.realIndex);
	};

	return (
		<>
			<div className={`profile-card-carsousel ${className}`}>
				<Swiper
					ref={swiperRef}
					className="carousel"
					initialSlide={initialSlide}
					pagination={true}
					spaceBetween={20}
					modules={[Navigation]}
					onSlideChange={handleSlideChange}
					navigation={{
						nextEl: "#button-next",
						prevEl: "#button-prev",
					}}
				>
					<SwiperSlide key={0}>
						<ProfileCard tierStyle={V0} openQrPopup={handleOpenQrPopup} />
					</SwiperSlide>
					<SwiperSlide key={1}>
						<ProfileCard tierStyle={V1} openQrPopup={handleOpenQrPopup} />
					</SwiperSlide>
					<SwiperSlide key={2}>
						<ProfileCard tierStyle={V2} openQrPopup={handleOpenQrPopup} />
					</SwiperSlide>
					<SwiperSlide key={3}>
						<ProfileCard tierStyle={V3} openQrPopup={handleOpenQrPopup} />
					</SwiperSlide>
					<SwiperSlide key={4}>
						<ProfileCard tierStyle={V4} openQrPopup={handleOpenQrPopup} />
					</SwiperSlide>
				</Swiper>
				<button
					id="button-prev"
					className="absolute left-0 top-[50%] z-[1] h-full -translate-y-1/2 scale-x-[-1] px-[0.5vw]"
					style={{ opacity: currentIndex === 0 ? 0 : 1 }}
				>
					<img
						className="w-[3.5vw] max-w-[39px]"
						src={CarouselButton}
						alt=""
						loading="lazy"
					/>
				</button>
				<button
					id="button-next"
					className="absolute right-0 top-[50%] z-[1] h-full -translate-y-1/2 px-[0.5vw]"
					style={{ opacity: currentIndex === 4 ? 0 : 1 }}
				>
					<img
						className="w-[3.5vw] max-w-[39px]"
						src={CarouselButton}
						alt=""
						loading="lazy"
					/>
				</button>
			</div>
			<Popup ref={qrCodePopupRef}>
				<div className="flex items-center">
					{/* avatar */}
					<LiffAvatar></LiffAvatar>
					{/* 姓名、會員ID */}
					<div className="ml-2 flex flex-col text-base font-medium">
						<span>{liffState.me?.line_name}</span>

						<span className="break-all text-sm text-gray-500">
							{liffState.memberData?.cardNumber}
						</span>
					</div>
				</div>
				<div className="mt-10 flex justify-center">
					<QRCode
						size={172}
						value={
							liffState.memberData?.cardNumber && liffState.memberData?.tierCode
								? `${liffState.memberData.cardNumber}|${liffState.memberData.tierCode}`
								: ""
						}
					></QRCode>
				</div>
			</Popup>
		</>
	);
};

export { ProfileCardCarousel };
