import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, useSprings, animated } from "@react-spring/web";

import ToastManager from "./ToastManager";
import PinnedActivityBg from "@/assets/img/liff/mission/pinned_activity/bg.png";
import PinOff from "@/assets/img/liff/mission/pinned_activity/pin-off.png";
import Line1 from "@/assets/img/liff/mission/pinned_activity/line_1.png";
import Line2 from "@/assets/img/liff/mission/pinned_activity/line_2.png";
import Line3 from "@/assets/img/liff/mission/pinned_activity/line_3.png";
import Line4 from "@/assets/img/liff/mission/pinned_activity/line_4.png";
import People1 from "@/assets/img/liff/mission/pinned_activity/people/people_1.gif";
import People2 from "@/assets/img/liff/mission/pinned_activity/people/people_2.gif";
import People3 from "@/assets/img/liff/mission/pinned_activity/people/people_3.gif";
import People4 from "@/assets/img/liff/mission/pinned_activity/people/people_4.gif";
import BtnClose from "@/assets/img/liff/mission/pinned_activity/btn_close.svg";
import IconHouse from "@/assets/img/liff/mission/pinned_activity/house.png";
import IconArrowUp from "@/assets/img/liff/mission/pinned_activity/icon_arrow_up.png";
import MissionToast1 from "@/assets/img/liff/mission/pinned_activity/toast/toast_01.png";
import MissionToast2 from "@/assets/img/liff/mission/pinned_activity/toast/toast_02.png";
import MissionToast3 from "@/assets/img/liff/mission/pinned_activity/toast/toast_03.png";
import MissionToast4 from "@/assets/img/liff/mission/pinned_activity/toast/toast_04.png";
import {
	MISSION_SlUG,
	MISSTION_PERFORM_RESULT,
} from "@/utils/interface/activity";
import { REWARD_SLUG } from "@/utils/interface/reward";
import GeneralModal from "./generalModal";
import Triangle from "./triangle";
import "./mission.scss";
import { api } from "@/utils";
import { useGetPointInfo } from "@/hooks";

const PinOn = (props) => {
	const { className, onClick } = props;
	return (
		<div
			role="presentation"
			className={`pin-on relative m-0 size-[5vw] p-0 ${className}`}
			onClick={onClick}
		>
			<div className="pin-on-outer absolute size-[5vw] rounded-[100vw] border-[1px] border-[#EFB135]" />
			<div className="pin-on-inner absolute m-1 size-[3vw] rounded-[100vw] bg-[#EFB135]" />
		</div>
	);
};

let isSendRewarding = false;
let skipSendReward = false;

const PinnedActivity = (props) => {
	const { pinnedActivity, getActivityList } = props;
	const navigate = useNavigate();
	const [modalTitle, setModalTitle] = useState("");
	const [modalSubtitle, setModalSubtitle] = useState("");
	const [modalRemind, setModalRemind] = useState("");
	const [modalRemindColor, setModalRemindColor] = useState("#ffffff");
	const [currentMissionIndex, setCurrentMissionIndex] = useState(-1);
	const [currentMission, setCurrentMission] = useState(null);
	const isRunAutoCompleteMission = useRef(false);
	const toastRef = useRef();
	const { getPointInfo } = useGetPointInfo();

	// TODO:
	const getMisionBySlug = async (slug) => {
		try {
			const res = await api().get("/get_mission_by_slug", {
				params: { slug: slug },
			});
			return res.data;
		} catch (err) {
			console.log(err);
			return null;
		}
	};
	const createFillIn = async (slug) => {
		try {
			const res = await api().post("/submission_create_fill_in", {
				slug: slug,
			});
			return res.data;
		} catch (err) {
			console.log(err);
			return null;
		}
	};
	const completedMisionBySlug = async (slug) => {
		try {
			const res = await api().post("/completed_mision_by_slug", { slug: slug });
			return res.data;
		} catch (err) {
			console.log(err);
			return null;
		}
	};
	const sendRewardBySlug = async (slug) => {
		try {
			const res = await api().post("/send_reward_by_slug", { slug: slug });
			getPointInfo();
			return res.data;
		} catch (err) {
			console.log(err);
			return null;
		}
	};

	const getTargetMission = () => {
		if (!pinnedActivity?.missions) {
			return -1;
		}
		for (let i = 0; i < pinnedActivity?.missions.length; i += 1) {
			const m = pinnedActivity.missions[i];
			if (m.is_completed === false) {
				return i;
			}
		}
		return -1;
	};

	const [mountainStyles, mountainApi] = useSpring(() => ({
		config: { duration: 200 },
		from: { scale: 1, x: "0vw", y: "0vw" },
	}));

	const [peopleStyles, peopleApi] = useSprings(
		4,
		(index) => {
			let w = "0vw";
			switch (index) {
				case 0:
					w = "19vw";
					break;
				case 1:
					w = "13vw";
					break;
				case 2:
					w = "15vw";
					break;
				case 3:
					w = "16vw";
					break;
				default:
					break;
			}
			return {
				from: { x: "0vw", y: "0vw", width: w },
			};
		},
		[],
	);

	const openMission = (index) => {
		let x = "0vw";
		let y = "0vw";
		switch (index) {
			case 0:
				x = "96vw";
				y = "-57vw";
				break;
			case 1:
				x = "-59vw";
				y = "-4vw";
				break;
			case 2:
				x = "-34vw";
				y = "67vw";
				break;
			case 3:
				x = "-6vw";
				y = "96vw";
				break;
			default:
				break;
		}

		mountainApi.start({ scale: 3, x, y });
		setCurrentMissionIndex(index);

		if (index === 0) {
			const isCompleted = pinnedActivity.missions[0].is_completed;
			if (isCompleted) {
				setModalTitle(`已完成 任務一`);
				setModalSubtitle(
					`您已領取完善資料點數\n每個會員帳號限領一次\n（含11/10前完善會員資料者）\n點數效期：30天`,
				);
				setModalRemind("點擊右上按鈕返回首頁");
				setModalRemindColor("#ffffff");
			}
		}

		peopleApi.start((i) => {
			switch (i) {
				case 0:
					return { to: { x: "15vw", y: "-39vw", width: "57vw" } };
				case 1:
					return { to: { x: "-32vw", y: "-10vw", width: "39vw" } };
				case 2:
					return { to: { x: "-26vw", y: "5vw", width: "45vw" } };
				case 3:
					return { to: { x: "-14vw", y: "15vw", width: "48vw" } };
				default:
					break;
			}
			return {};
		});
	};

	const closeMission = () => {
		mountainApi.start({ scale: 1, x: "0vw", y: "0vw" });
		setCurrentMissionIndex(-1);

		peopleApi.start((i) => {
			let w = "0vw";
			switch (i) {
				case 0:
					w = "19vw";
					break;
				case 1:
					w = "13vw";
					break;
				case 2:
					w = "15vw";
					break;
				case 3:
					w = "16vw";
					break;
				default:
					break;
			}
			return {
				to: { x: "0vw", y: "0vw", width: w },
			};
		});
	};

	const afterCheckMission = async (missionSlug) => {
		// Get mission is_completed from activity.
		const isCompleted =
			pinnedActivity.missions[currentMissionIndex].is_completed;
		if (!isCompleted) {
			switch (missionSlug) {
				case MISSION_SlUG.GO_TO_OA_WEB: {
					const liff = (await import("@line/liff")).default;
					liff.openWindow({
						url: "https://maac.io/2bZr6", // Official website url.
						external: false,
					});
					break;
				}
				default:
					break;
			}
		}
	};

	const executeMission = async (missionSlug) => {
		// If mission not completed, then open the related page.
		switch (missionSlug) {
			case MISSION_SlUG.FILL_IN_MEMBER_DATA:
				await createFillIn(missionSlug);
				navigate("/profile");
				break;
			case MISSION_SlUG.ONE_TO_ONE:
				window.location.href =
					"https://linewith.me/liff/cl93l9njp0567o8lnpeqmyohb/tnf/store-picker"; // Provide by ChatBrick.
				break;
			case MISSION_SlUG.TOP_MGM:
				navigate(`/activity/mission/2-4`);
				break;
			default:
				break;
		}
	};

	const onClickNotStartMission = () => {
		let missionName = "";
		let subtitle = "";
		let remind = "";
		switch (getTargetMission()) {
			case 0:
				missionName = "一";
				subtitle = "請完成會員資料填寫\n完成探險者啟程";
				remind = "*會員欄位資料須確實填寫完成";
				break;
			case 1:
				missionName = "二";
				subtitle = "請完成 一對一專屬門市顧問綁定\n獲得冒險指南";
				break;
			case 2:
				missionName = "三";
				subtitle = "請完成 瀏覽 The North Face官網\n獲得齊全裝備";
				break;
			case 3:
				missionName = "四";
				subtitle = "邀請夥伴成為探險家";
				break;
			default:
				break;
		}
		setModalTitle(`尚未完成 任務${missionName}`);
		setModalSubtitle(subtitle);
		setModalRemind(remind);
		setModalRemindColor("#EFB135");
	};

	const getSlugByMissionIndex = (index) => {
		let missionSlug = null;
		switch (index) {
			case 0:
				missionSlug = MISSION_SlUG.FILL_IN_MEMBER_DATA;
				break;
			case 1:
				missionSlug = MISSION_SlUG.ONE_TO_ONE;
				break;
			case 2:
				missionSlug = MISSION_SlUG.GO_TO_OA_WEB;
				break;
			case 3:
				missionSlug = MISSION_SlUG.TOP_MGM;
				break;
			default:
				break;
		}
		return missionSlug;
	};

	const getCurrentMission = async () => {
		const missionSlug = getSlugByMissionIndex(currentMissionIndex);
		if (missionSlug === null) {
			return;
		}
		const m = await getMisionBySlug(missionSlug);
		setCurrentMission(m);
	};

	const getCurrentMissionByIndex = async (missionIndex) => {
		const missionSlug = getSlugByMissionIndex(missionIndex);
		if (missionSlug === null) {
			return;
		}
		const m = await getMisionBySlug(missionSlug);
		setCurrentMission(m);
	};

	const onClickCheckMission = async () => {
		if (currentMissionIndex === -1) {
			const targetMissionIndex = getTargetMission();
			if (targetMissionIndex !== -1) {
				openMission(targetMissionIndex);
			}
		} else {
			/**
			 * When user click the "Go to finish" button, check the mission is completed or not.
			 *
			 * Mission 0: Fill in member data.   Action: open member data page.
			 * Mission 1: One to one.   Action: open LINE OA.
			 * Mission 2: Go to OA web.   Action: Open offical web url.
			 * Mission 3: Top MGM.    Action: open the mgm page.
			 *
			 * For mission 1, 2, 4, call the api completedMisionBySlug to let server check the mission is completed or not,
			 * if not, then open the related page or url.
			 */
			const missionSlug = getSlugByMissionIndex(currentMissionIndex);
			if (missionSlug !== null) {
				try {
					const res = await completedMisionBySlug(missionSlug);
					getCurrentMission();
					if (res === "success") {
						if (currentMissionIndex === 0) {
							// 任務一(slug : fill_in_member_data)，維持由 crm 發送點數
							setModalTitle(`已完成 任務一`);
							setModalSubtitle(
								`您已領取完善資料點數\n每個會員帳號限領一次\n（含11/10前完善會員資料者）\n點數效期：30天`,
							);
							setModalRemind("點擊右上按鈕返回首頁");
							setModalRemindColor("#ffffff");
						} else {
							let missionName = "";
							let pointName = "";
							switch (currentMissionIndex) {
								case 1:
									missionName = "二";
									pointName = "6,000";
									break;
								case 2:
									missionName = "三";
									pointName = "500";
									break;
								case 3:
									missionName = "四";
									pointName = "4,000";
									break;
								default:
									break;
							}
							setModalTitle(`已完成 任務${missionName}`);
							setModalSubtitle(
								`點擊下方按鈕\n領取獎勵會員點數 ${pointName}點\n點數效期：30天`,
							);
						}
						afterCheckMission(missionSlug);
						// Reload the pinned activity.
						getActivityList();
					} else if (res === MISSTION_PERFORM_RESULT.TIME_LIMIT_REPAET) {
						setModalTitle(`您已完成本任務`);
						setModalSubtitle(
							"2023/11/10 前完成本任務者​\n恕不發放本任務獎勵\n點擊確認前往完成下一任務",
						);
						skipSendReward = true;
						getActivityList();
					} else {
						await executeMission(missionSlug);
					}
				} catch (err) {
					console.log(err);
				}
			}
		}
	};

	const getMissionButton = () => {
		let btnText = "";
		let classes = "bg-white text-black";
		let triangleColor = "black";
		let hasCallback = true;
		if (currentMissionIndex === -1) {
			const targetMission = getTargetMission();
			if (targetMission === -1) {
				btnText = "已完成";
				classes = "bg-gray-400 text-white";
				triangleColor = "white";
			} else {
				btnText = "開始挑戰";
			}
		} else {
			hasCallback = true;
			if (currentMission?.is_completed === false) {
				btnText = "去完成";
			} else if (currentMission?.rewards[0]?.is_completed) {
				btnText = "已完成";
				classes = "bg-gray-400 text-white";
				triangleColor = "gray-400";
				hasCallback = false;
			} else if (currentMission?.slug === MISSION_SlUG.FILL_IN_MEMBER_DATA) {
				btnText = "已完成";
				classes = "bg-gray-400 text-white";
				triangleColor = "gray-400";
				hasCallback = false;
			} else if (!currentMission) {
				btnText = "載入中...";
				classes = "bg-gray-400 text-white";
				triangleColor = "gray-400";
				hasCallback = false;
			} else {
				btnText = "去領獎";
			}
		}
		return (
			<button
				type="button"
				className={`btn-start rounded px-[2.5vw] py-[1.5vw] text-center text-[3.2vw] font-[600] ${classes}`}
				onClick={hasCallback ? onClickCheckMission : () => {}}
			>
				{btnText}
				<Triangle color={triangleColor} className="-mr-1 ml-2" />
			</button>
		);
	};

	/**
	 * This function will auto complete the target mission, when user enter this page.
	 */
	const tryToCompleteTargetMission = async () => {
		const targetMissionIndex = getTargetMission();
		if (targetMissionIndex === -1) {
			return;
		}

		const missionSlug = getSlugByMissionIndex(targetMissionIndex);
		if (missionSlug === null) {
			// Means all done.
			return;
		}
		if (missionSlug === MISSION_SlUG.GO_TO_OA_WEB) {
			// Skip this mission on auto complete.
			return;
		}

		const res = await completedMisionBySlug(missionSlug);
		getCurrentMissionByIndex(targetMissionIndex);
		if (res === "success") {
			// Zoom in the mission
			openMission(targetMissionIndex);

			if (targetMissionIndex === 0) {
				// 任務一(slug : fill_in_member_data)，維持由 crm 發送點數
				setModalTitle(`已完成 任務一`);
				setModalSubtitle(
					`您已領取完善資料點數\n每個會員帳號限領一次\n（含11/10前完善會員資料者）\n點數效期：30天`,
				);
				setModalRemind("點擊右上按鈕返回首頁");
				setModalRemindColor("#ffffff");
			} else {
				let missionName = "";
				let pointName = "";
				switch (targetMissionIndex) {
					case 0:
						missionName = "ㄧ";
						pointName = "1,000";
						break;
					case 1:
						missionName = "二";
						pointName = "6,000";
						break;
					case 2:
						missionName = "三";
						pointName = "500";
						break;
					case 3:
						missionName = "四";
						pointName = "4,000";
						break;
					default:
						break;
				}
				setModalTitle(`已完成 任務${missionName}`);
				setModalSubtitle(`點擊下方按鈕\n領取獎勵會員點數 ${pointName}點`);
			}

			// Reload the pinned activity.
			getActivityList();
		} else if (res === MISSTION_PERFORM_RESULT.TIME_LIMIT_REPAET) {
			openMission(targetMissionIndex);
			setModalTitle(`您已完成本任務`);
			setModalSubtitle(
				"2023/11/10 前完成本任務者​\n恕不發放本任務獎勵\n點擊確認前往完成下一任務",
			);
			getActivityList();
			skipSendReward = true;
		}
	};

	const getReward = async () => {
		if (isSendRewarding) {
			return;
		}
		isSendRewarding = true;
		// If not in mission, then return.
		if (currentMissionIndex === -1 || currentMissionIndex === 0) {
			// 任務一(slug : fill_in_member_data)，維持由 crm 發送點數
			setModalTitle("");
			setModalSubtitle("");
			isSendRewarding = false;
			return;
		}

		let rewardSlug = null;
		let rewardToast = null;
		switch (currentMissionIndex) {
			case 0:
				rewardSlug = REWARD_SLUG.A2_FILL_IN;
				rewardToast = MissionToast1;
				break;
			case 1:
				rewardSlug = REWARD_SLUG.A2_ONE_TO_ONE;
				rewardToast = MissionToast2;
				break;
			case 2:
				rewardSlug = REWARD_SLUG.A2_GO_TO_OA_WEB;
				rewardToast = MissionToast3;
				break;
			case 3:
				rewardSlug = REWARD_SLUG.A2_MGM;
				rewardToast = MissionToast4;
				break;
			default:
				break;
		}
		if (rewardSlug === null) {
			setModalTitle("");
			isSendRewarding = false;
			return;
		}

		if (rewardSlug === REWARD_SLUG.A2_ONE_TO_ONE && skipSendReward) {
			setModalTitle("");
			skipSendReward = false;
			isSendRewarding = false;
			return;
		}

		try {
			const res = await sendRewardBySlug(rewardSlug);
			if (res === "success") {
				setModalTitle("");
				toastRef.current.addToast(<img src={rewardToast} alt="" />);
				// Refresh point.
				// getPointsSummary();
			} else if (res === "already-received") {
				setModalTitle("");
			} else if (res === MISSTION_PERFORM_RESULT.TIME_LIMIT_REPAET) {
				setModalTitle("您已完成本任務");
				setModalSubtitle(
					"2023/11/10 前完成本任務者​\n恕不發放本任務獎勵\n點擊確認前往完成下一任務",
				);
			} else if (res === MISSTION_PERFORM_RESULT.LIMIT_FULL) {
				setModalTitle("領取點數失敗");
				setModalSubtitle("獎勵已經發完");
			} else {
				setModalTitle("領取點數失敗");
				setModalSubtitle("");
			}
			isSendRewarding = false;
			// Go back to mountain.
			closeMission();
		} catch (err) {
			console.log(err);
		}
	};

	const [collapseStyles, collapseApi] = useSpring(() => ({
		config: { duration: 300 },
		from: { opacity: 1, height: "100vw" },
	}));
	const [arrowStyles, arrowApi] = useSpring(() => ({
		config: { duration: 300 },
		from: { rotate: 0 },
	}));

	const [isCollapsed, setIsCollapsed] = useState(false);
	const onClickCollapse = () => {
		if (!isCollapsed) {
			collapseApi.start({ opacity: 0, height: "0vw" });
			arrowApi.start({ rotate: 180 });
		} else {
			collapseApi.start({ opacity: 1, height: "100vw" });
			arrowApi.start({ rotate: 0 });
		}
		setIsCollapsed(!isCollapsed);
	};

	useEffect(() => {
		if (currentMissionIndex !== -1) {
			getCurrentMission();
		}
	}, [currentMissionIndex]);

	useEffect(() => {
		if (pinnedActivity) {
			// If all mission completed, then collapse the activity.
			if (pinnedActivity.missions.every((m) => m?.is_completed === true)) {
				if (!isCollapsed) {
					onClickCollapse();
				}
			}

			// Do only once.
			if (!isRunAutoCompleteMission.current) {
				isRunAutoCompleteMission.current = true;
				tryToCompleteTargetMission();
			}
		}
	}, [pinnedActivity]);

	return (
		<div>
			<animated.div
				className="pinned-activity size-[100vw] p-[2vw]"
				style={collapseStyles}
			>
				<div className="relative size-full overflow-hidden rounded-xl">
					<div className="absolute size-full bg-gray-600 opacity-20" />
					<animated.div
						className="absolute size-full bg-cover bg-center"
						style={{
							backgroundImage: `url(${PinnedActivityBg})`,
							...mountainStyles,
						}}
					>
						{/* Line */}
						<div
							className="line-1 absolute left-[19.3vw] top-[66.8vw] h-[4vw] w-[45vw] bg-contain bg-left bg-no-repeat"
							style={{ backgroundImage: `url(${Line1})` }}
						/>
						<div
							className="line-2 absolute left-[5vw] top-[48.3vw] h-[20vw] w-[68vw] bg-contain bg-left bg-no-repeat"
							style={{ backgroundImage: `url(${Line2})` }}
						/>
						<div
							className="line-3 absolute left-[32.4vw] top-[28.5vw] h-[19vw] w-[45vw] bg-contain bg-left bg-no-repeat"
							style={{ backgroundImage: `url(${Line3})` }}
						/>
						<div
							className="line-4 absolute left-[47.45vw] top-[16vw] h-[11vw] w-[14vw] bg-contain bg-left bg-no-repeat"
							style={{ backgroundImage: `url(${Line4})` }}
						/>

						{/* House */}
						{currentMissionIndex === -1 && (
							<div className="house absolute left-[63vw] top-[61vw] mx-auto mt-0 w-[31vw]">
								<img src={IconHouse} className="" alt="" />
							</div>
						)}

						{/* Pin */}
						{!pinnedActivity.missions[0].is_completed &&
							currentMissionIndex === -1 && (
								<div className="mission-1 absolute left-[10.5vw] top-[61vw] h-[10vw] w-[12vw]">
									<div className="text-center text-xs text-white">任務一</div>
									{getTargetMission() === 0 ? (
										<PinOn
											className="pin-on-1 mx-auto"
											onClick={() => openMission(0)}
										/>
									) : (
										<div
											role="presentation"
											className="mx-auto mt-0 w-[4vw]"
											onClick={() => onClickNotStartMission()}
										>
											<img src={PinOff} className="" alt="" />
										</div>
									)}
								</div>
							)}
						{!pinnedActivity.missions[1].is_completed &&
							currentMissionIndex === -1 && (
								<div className="mission-2 absolute left-[63.2vw] top-[40.2vw] h-[10vw] w-[12vw]">
									<div className="text-center text-xs text-white">任務二</div>
									{getTargetMission() === 1 ? (
										<PinOn
											className="pin-on-2 mx-auto"
											onClick={() => openMission(1)}
										/>
									) : (
										<div
											role="presentation"
											className="mx-auto mt-0 w-[4vw]"
											onClick={() => onClickNotStartMission()}
										>
											<img src={PinOff} className="" alt="" />
										</div>
									)}
								</div>
							)}
						{!pinnedActivity.missions[2].is_completed &&
							currentMissionIndex === -1 && (
								<div className="mission-3 absolute left-[55vw] top-[21vw] h-[10vw] w-[12vw]">
									<div className="text-center text-xs text-white">任務三</div>
									{getTargetMission() === 2 ? (
										<PinOn
											className="pin-on-3 mx-auto"
											onClick={() => openMission(2)}
										/>
									) : (
										<div
											role="presentation"
											className="mx-auto mt-0 w-[4vw]"
											onClick={() => onClickNotStartMission()}
										>
											<img src={PinOff} className="" alt="" />
										</div>
									)}
								</div>
							)}
						{!pinnedActivity.missions[3].is_completed &&
							currentMissionIndex === -1 && (
								<div className="mission-4 absolute left-[46vw] top-[7vw] h-[10vw] w-[12vw]">
									<div className="text-center text-xs text-white">FINISH</div>
									{getTargetMission() === 3 ? (
										<PinOn
											className="pin-on-4 mx-auto"
											onClick={() => openMission(3)}
										/>
									) : (
										<div
											role="presentation"
											className="mx-auto mt-0 w-[4vw]"
											onClick={() => onClickNotStartMission()}
										>
											<img src={PinOff} className="" alt="" />
										</div>
									)}
								</div>
							)}
					</animated.div>
					<div className="absolute">
						{/* People */}
						{((pinnedActivity.missions[0].is_completed &&
							currentMissionIndex === -1) ||
							currentMissionIndex === 0) && (
							<animated.div
								className="people-1 absolute left-[7vw] top-[56vw] w-[19vw]"
								style={{ ...peopleStyles[0] }}
								onClick={() => openMission(0)}
							>
								<img src={People1} alt="" width="100%" />
							</animated.div>
						)}
						{((pinnedActivity.missions[1].is_completed &&
							currentMissionIndex === -1) ||
							currentMissionIndex === 1) && (
							<animated.div
								className="people-2 absolute left-[62vw] top-[37vw] w-[13vw]"
								style={{ ...peopleStyles[1] }}
								onClick={() => openMission(1)}
							>
								<img src={People2} alt="" width="100%" />
							</animated.div>
						)}
						{((pinnedActivity.missions[2].is_completed &&
							currentMissionIndex === -1) ||
							currentMissionIndex === 2) && (
							<animated.div
								className="people-3 absolute left-[55vw] top-[20vw] w-[15vw]"
								style={{ ...peopleStyles[2] }}
								onClick={() => openMission(2)}
							>
								<img src={People3} alt="" width="100%" />
							</animated.div>
						)}
						{((pinnedActivity.missions[3].is_completed &&
							currentMissionIndex === -1) ||
							currentMissionIndex === 3) && (
							<animated.div
								className="people-4 absolute left-[44vw] top-[2vw] w-[16vw]"
								style={{ ...peopleStyles[3] }}
								onClick={() => openMission(3)}
							>
								<img src={People4} alt="" width="100%" />
							</animated.div>
						)}
					</div>
					<div className="absolute left-0 top-0 p-3 text-[4vw] font-bold  text-white">
						探險隨時趣
					</div>
					{currentMissionIndex !== -1 && (
						<div
							role="presentation"
							className="absolute right-3 top-3 w-[5vw]"
							onClick={() => closeMission()}
						>
							<img src={BtnClose} />
						</div>
					)}
					<div className="absolute bottom-0 left-0 p-3 text-white">
						{currentMissionIndex === -1 && (
							<div className="subtitle text-[3vw]">
								完成所有任務成為「滿分探險者」
								<br />
								可獲得11,500點會員點數
							</div>
						)}
						{currentMissionIndex === 0 && (
							<>
								<div className="title font-bold">任務一 探險者啟程</div>
								<div className="subtitle text-[3vw]">
									{currentMission?.is_completed ? (
										<>
											你已成為「探險者啟程」
											<br />
											獲得 1,000點會員點數
										</>
									) : (
										<>
											請完成會員資料填寫
											<br />
											可獲得會員點數 1,000點
										</>
									)}
								</div>
							</>
						)}
						{currentMissionIndex === 1 && (
							<>
								<div className="title font-bold">任務二 冒險指南</div>
								<div className="subtitle text-[3vw]">
									{currentMission?.is_completed ? (
										<>
											已完成 冒險指南
											<br />
											獲得 6,000點會員點數
										</>
									) : (
										<>
											請完成 一對一專屬門市顧問綁定
											<br />
											可獲得會員點數 6,000點
										</>
									)}
								</div>
							</>
						)}
						{currentMissionIndex === 2 && (
							<>
								<div className="title font-bold">任務三 齊全裝備</div>
								<div className="subtitle text-[3vw]">
									{currentMission?.is_completed ? (
										<>
											已完成 裝備齊全
											<br />
											獲得 500點會員點數
										</>
									) : (
										<>
											請瀏覽 The North Face官網
											<br />
											可獲得會員點數 500點
										</>
									)}
								</div>
							</>
						)}
						{currentMissionIndex === 3 && (
							<>
								<div className="title font-bold">任務四 邀請夥伴</div>
								<div className="subtitle text-[3vw]">
									{currentMission?.is_completed ? (
										<>
											已完成 邀請夥伴
											<br />
											獲得 4,000點會員點數
										</>
									) : (
										<>
											請邀請 LINE好友成為探險家
											<br />
											可獲得會員點數 4,000點
										</>
									)}
								</div>
							</>
						)}
					</div>
					<div className="absolute bottom-0 right-0 p-3 text-white">
						{getMissionButton()}
					</div>
				</div>
			</animated.div>

			{/* Collapse button */}
			<div className="control flex justify-center pb-3 pt-1">
				<div
					role="presentation"
					className="btn-collapse inline-block rounded-[100vw] bg-white px-4 py-0.5 text-sm text-black"
					onClick={onClickCollapse}
				>
					{isCollapsed ? "展開" : "收起"}
				</div>
				<animated.div
					className="arrow ml-[3vw] inline-block h-[5.5vw] w-[5vw] text-center"
					style={arrowStyles}
				>
					<img src={IconArrowUp} height="100%" className="pt-[0.5vw]" />
				</animated.div>
			</div>

			<GeneralModal
				isOpen={!!modalTitle}
				title={modalTitle}
				subtitle={modalSubtitle}
				remind={modalRemind}
				remindColor={modalRemindColor}
				closeModal={() => {
					setModalTitle("");
					setModalSubtitle("");
					setModalRemind("");
					setModalRemindColor("#ffffff");
				}}
				onConfirm={getReward}
			/>

			<ToastManager ref={toastRef} />
		</div>
	);
};

PinnedActivity.propTypes = {
	pinnedActivity: PropTypes.object,
};

export default PinnedActivity;
