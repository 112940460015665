import Modal from "react-modal";
import Triangle from "./triangle";

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    border: 'none',
  },
};

const GeneralModal = (props) => {
  const { isOpen, closeModal, title, subtitle, onConfirm, remind, remindColor } = props;
  return (
    <Modal isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
      <div className="w-[60vw] min-h-[40vw] text-white bg-transparent border-[1vw] border-white rounded-lg p-3 flex items-center justify-center ">
        <div>
          <div className="title mb-3 text-lg whitespace-pre text-center font-[800]">{title}</div>
          <div className="subtitle text-sm whitespace-pre text-center">{subtitle}</div>
          <div className="remind text-sm whitespace-pre text-center mt-3" style={{ display: remind !== '' ? 'block' : 'none', color: remindColor}}>{remind}</div>
        </div>
      </div>
      <div className="text-center">
        <button type="button" className="confirm-btn relative mx-auto my-4 bg-white rounded-lg" onClick={onConfirm} onKeyDown={onConfirm}>
          <div className="title text-[4vw] text-black text-center py-[1.5vw] px-[15vw] font-[800]">確認<Triangle color="black" className="ml-5 -mr-5" /></div>
        </button>
      </div>
    </Modal>
  );
}

export default GeneralModal;
