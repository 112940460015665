import { useContext } from "react";
import { Progress } from "@/components";
import { LiffContext } from "@/utils";
import { useMemberTier } from "@/hooks";
import { ProfileCardMask } from "./ProfileCardMask";
import ProfileCardBg from "@/assets/img/liff/level-system/profile_card_bg.png";
import Logo from "@/assets/img/liff/logo.webp";
import Avatar from "@/assets/img/liff/avatar.webp";

const ProfileCard = ({ tierStyle, openQrPopup }) => {
	const { renderMemberTierInfo } = useMemberTier();
	const { liffState } = useContext(LiffContext);
	const currentTierCode = liffState.memberData?.tierCode;
	const avatarUrl = liffState.me?.avatar_url || Avatar;
	const availablePoint = liffState.pointInfo?.availablePoint;

	const formatNumberWithCommas = (number) => {
		return new Intl.NumberFormat().format(Math.floor(number));
	};

	return (
		<div className="profile-card">
			<ProfileCardMask
				currentTierCode={currentTierCode}
				batchTierStyle={tierStyle}
			/>
			<img className="profile-background" src={ProfileCardBg} />
			<div className="profile-logo">
				<img src={Logo} />
			</div>
			<div className="qr-button" onClick={openQrPopup}>
				<img src={tierStyle.qrIcon}></img>
			</div>
			<div className="user-profile">
				<div
					className="comp-user-avatar"
					style={{ outlineColor: tierStyle.color }}
				>
					<img src={avatarUrl} alt="" />
				</div>
				<div className="profile-info">
					<div className="vip-grade">
						<img src={tierStyle.vipIcon}></img>
						<label style={{ color: tierStyle.color }}>
							{tierStyle.tierName}
						</label>
						<span>{tierStyle.tierNameEN}</span>
					</div>
					<div className="user-name">
						<label>{liffState.me?.line_name}</label>
					</div>
				</div>
			</div>
			{currentTierCode === tierStyle.tierCode ? (
				<div className="member-point">
					<img src={tierStyle.pointIcon}></img>
					<label style={{ color: tierStyle.color }}>
						會員點數 :{" "}
						<span style={{ fontFamily: "Helvetica Neue" }}>
							{formatNumberWithCommas(availablePoint)}
						</span>
					</label>
					<button
						onClick={() =>
							liffState.memberBenefitPopup.open(tierStyle.tierCode)
						}
					>
						<span>查看會員權益</span>
						<div className="triangle-right" />
					</button>
				</div>
			) : (
				<div className="member-point"></div>
			)}
			<div className="progress-bar">
				{tierStyle.getTierStyleByCode(currentTierCode).tierSeq <
				tierStyle.tierSeq ? (
					<Progress
						color={tierStyle.color}
						barColor="rgba(0, 0, 0, 0.3)"
						forceZero={true}
					/>
				) : tierStyle.getTierStyleByCode(currentTierCode).tierSeq >
				  tierStyle.tierSeq ? (
					<Progress
						color={tierStyle.color}
						barColor="rgba(0, 0, 0, 0.3)"
						forceFull={true}
					/>
				) : (
					<Progress color={tierStyle.color} barColor="rgba(0, 0, 0, 0.3)">
						<div className="member-tier-info">
							<label>
								{currentTierCode === tierStyle.tierCode
									? renderMemberTierInfo()
									: null}
							</label>
						</div>
					</Progress>
				)}
			</div>
			<div
				className="bottom-bar"
				style={{ backgroundColor: tierStyle.color }}
			/>
		</div>
	);
};

export { ProfileCard };
