/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import liff from "@line/liff";

import { LiffContext, api } from "@/utils";
import { useMemberTier } from "@/hooks";
import { Icon, Button } from "@/atoms";
import { PinnedActivity } from "./mission/index";
import { ACTIVITY_SlUG, DEF_PUBLISH_STATUS } from "@/utils/interface/activity";
import { TopMemberInfoBox } from "./TopMemberInfoBox";

const ActivityPage = () => {
	// 常駐活動
	const [pinnedActivity, setPinnedActivity] = useState(null);
	// 限時活動
	const [limitedTimeActivity, setLimitedTimeActivity] = useState([]);

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	const { renderMemberTierInfo } = useMemberTier();
	const navigate = useNavigate();

	const getActivityList = async () => {
		liffActions.setShowFullPageLoader(true);

		try {
			const res = await api().get("/get_activity_list");
			console.log(res.data);
			setLimitedTimeActivity(
				res.data
					.filter((item) => item.slug !== ACTIVITY_SlUG.PINNED_ACTIVITY)
					.filter((item) => item.status === DEF_PUBLISH_STATUS.PUBLISH),
			);

			const act = res.data.find(
				(item) => item.slug === ACTIVITY_SlUG.PINNED_ACTIVITY,
			);
			if (act !== undefined && act.status === DEF_PUBLISH_STATUS.PUBLISH) {
				setPinnedActivity(act);
			}
		} catch (error) {
			console.error("Get Activity List Failed", error);
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	const onNavigateToMission = (type, slug, url) => {
		console.log(url);
		if (type === "url") {
			// 全新網址於liff開啟
			liff.openWindow({
				url,
				external: false, // Liff内打開
			});
		} else {
			// 將slug塞進路由
			navigate(`/activity/${slug}`);
		}
	};

	const getButtonText = (activity) => {
		if(activity.is_completed === false){
			return "去完成";
		}
		if(activity.slug === ACTIVITY_SlUG.MGM_FIRST){
			if(activity.rewards !== 0 && activity.rewards[0].is_completed === false){
				return "去領獎";
			}
		}
		return "已完成";
	}

	const getButtonIsCompleted = (activity) => {
		if(activity.slug === ACTIVITY_SlUG.MGM_FIRST){
			if(activity.rewards !== 0 && activity.rewards[0].is_completed === false){
				return false;
			}
		}
		else if(activity.is_completed === false){
			return false;
		}
		return true;
	}


	useEffect(() => {
		getActivityList();
	}, []);

	return (
		<div className="h-screen w-full overflow-y-scroll bg-activityPageBg bg-cover bg-scroll text-white">
			{/* Top */}
			<TopMemberInfoBox tierCode={liffState?.memberData?.tierCode} />

			{/* 常駐任務 */}
			{pinnedActivity && (
				<PinnedActivity
					pinnedActivity={pinnedActivity}
					getActivityList={getActivityList}
				/>
			)}

			{/* 限時任務 */}
			<div className="mb-[75px] min-h-full w-full rounded-lg bg-black/50 p-3" style={{display : limitedTimeActivity.length > 0 ? 'block' : 'none'}}>
				<div>
					<div className="flex border-b-2 border-white text-white">
						<div className="mb-2 border-r-2 pr-3 font-bold">DARE TO CLIMB</div>
						<div className="ml-3 font-bold">探險始於大膽</div>
					</div>
					{/* activity card */}
					{limitedTimeActivity.map((activity) => (
						<div
							className="mt-5 overflow-hidden rounded-b-lg rounded-t-none bg-white p-3"
							key={activity.id}
						>
							{/* 期間限定 */}
							<div className="absolute left-[-39px] top-[-19px] z-10 rotate-[-35deg] bg-[#F8B14F] px-8 pb-px pt-8 text-xs">
								期間限定
							</div>
							{/* 活動圖 */}
							<div className="mb-2 h-[30vw] w-full">
								<img
									className="h-full w-full rounded-t-lg object-cover"
									src={activity.image_url}
									alt="活動"
								/>
							</div>
							{/* 活動名稱、說明 */}
							<div className="flex justify-between space-x-3 text-blackT80">
								<div className="mr-3 flex flex-col">
									<span className="text-[3.5vw] font-black">
										{activity.name}
									</span>
									<span className="mt-px whitespace-pre-wrap text-[2.6vw] font-semibold text-blackT50">
										{activity.info}
									</span>
									{/* progress */}
									{activity.missions.length > 0 && (
										<div className="mt-2 flex justify-between">
											{activity.missions.map((mission, index) => (
												<div
													key={index}
													className={classNames(
														"mx-1 h-1.5 flex-1",
														{ "bg-black": mission.is_completed === true },
														{
															"bg-customGray": mission.is_completed === false,
														},
													)}
												></div>
											))}
										</div>
									)}
								</div>
								<div className="flex flex-col justify-between">
									<div className="flex justify-end">
										<Icon
											iconName={"Gift"}
											className="h-[4.2vw] w-[4.2vw]"
										></Icon>
										<span className="text-[3.2vw]">
											{activity?.rewards[0]?.name}
										</span>
									</div>
									<Button
										type="button"
										theme="active"
										activityCompleted={getButtonIsCompleted(activity)}
										withArrow={!activity.is_completed}
										text={getButtonText(activity)}
										color="black"
										contentColor="white"
										onClick={() =>
											onNavigateToMission(
												activity.type,
												activity.slug,
												activity.custom_data,
											)
										}
									></Button>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ActivityPage;
