import classNames from "classnames";
import dayjs from "dayjs";
import { Tooltip } from "@/atoms";

/**
 * PointHistoryList
 * @param {Object} item
 * @param {string} activeTab
 * @param {string} title
 * @returns jsx
 */
const PointHistoryList = ({ item, activeTab, title }) => {
	// 判斷是否過期
	const isExpired = item.invalidTime
		? dayjs().isAfter(dayjs(item.invalidTime))
		: false;
	// 格式化日期（去除時間）
	const dateString = item.invalidTime
		? dayjs(item.invalidTime).format("YYYY/MM/DD")
		: "";

	return (
		<>
			<div className="flex items-center justify-between px-4 py-2">

				<div className="my-1.5 flex flex-col">
					<span className={classNames({
						"text-blackT80": activeTab === "used",
						"text-gray-400": isExpired && activeTab !== "used",
					})} style={{display : (item.operationType === 100000000 || item.operationType === 100000001 ) && !!item.desc ? 'flex' : 'none'}}>
						{item?.desc}
					</span>
					<span
						className={classNames({
							"text-blackT80": activeTab === "used",
							"text-gray-400": isExpired && activeTab !== "used",
						})}
					>
						{title}
					</span>

					{activeTab === "used" && (
						<span className="text-sm text-gray-400">{item.validTime}</span>
					)}

					{activeTab === "acquired" && (
						<Tooltip
							label={isExpired ? "已過期" : `於 ${dateString} 過期`}
							isExpired={isExpired}
						>
							<span className="whitespace-nowrap">
								生效時間: {item.validTime}
							</span>
							<br />
							<span className="whitespace-nowrap">
								過期時間: {item.invalidTime}
							</span>
						</Tooltip>
					)}
				</div>
				<span
					className={classNames({
						"text-blackT80": activeTab === "used",
						"text-gray-400": isExpired && activeTab !== "used",
					})}
				>
					{`${activeTab === "acquired" ? "+" : ""
						}${item.originalPoint.toLocaleString()}`}
				</span>
			</div>
			<hr />
		</>
	);
};

export default PointHistoryList;
