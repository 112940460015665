/* eslint-disable no-unused-vars */
import classNames from "classnames";
/**
 * Button
 * @param {'button' | 'submit'} type
 * @param {'default' | 'active' | 'admin'} [theme='default'] 按鈕主題： 點數商城 | 任務牆 | 後台系統
 * @param {string} text
 * @param {void} onClick
 * @param {boolean} disabled
 * @param {boolean} showBtnLoader
 * @param {'black' | 'white'} [color='black']
 * @param {boolean} withArrow
 * @param {boolean} activityCompleted 任務是否完成
 * @param {boolean} [borderOnly = false] 是否有邊框
 * @param {'white' | 'black'} contentColor 內容（文字或Icon）顏色
 * @param {string} className
 * @param {boolean} [widthFull = true] 是否滿版
 * @returns jsx
 */
const Button = ({
	type,
	theme = "default",
	text,
	onClick,
	disabled,
	showBtnLoader = false,
	color = "black",
	withArrow = false,
	activityCompleted,
	borderOnly = false,
	contentColor,
	className,
	widthFull = true,
	children,
	size = "default",
}) => {
	let btnTheme = "";
	switch (theme) {
		case "active":
			btnTheme = "rounded px-3 py-1 text-sm font-medium";
			break;
		case "admin":
			btnTheme = "rounded-lg text-sm font-medium";
			break;
		case "default":
		default:
			btnTheme = "p-4 font-medium leading-4";
			break;
	}

	// admin btn 大小
	let btnSize = "";
	if (theme === "admin") {
		switch (size) {
			case "sm":
				btnSize = "py-1.5 px-3";
				break;
			case "default":
			default:
				btnSize = "py-3 px-4";
				break;
		}
	}

	let btnStyle = "";
	if (borderOnly) {
		// 只有邊框
		switch (color) {
			case "white":
				btnStyle = "border border-white bg-transparent text-white";
				break;
			case "black":
			default:
				btnStyle = "border border-black bg-white text-black";
				break;
		}
	} else {
		// 無邊框
		switch (color) {
			case "white":
				btnStyle = "bg-white text-black";
				break;
			case "black":
			default:
				btnStyle = "bg-black text-white";
				break;
		}
	}

	return (
		<button
			type={type}
			className={classNames(
				"flex items-center justify-center whitespace-nowrap",
				btnTheme,
				btnStyle,
				btnSize,
				className,
				{
					"w-full": widthFull,
				},
				{
					"cursor-not-allowed bg-blackT10": showBtnLoader || disabled,
				},
				{
					"bg-customGray text-white": activityCompleted,
				},
			)}
			onClick={onClick}
			disabled={disabled || showBtnLoader}
		>
			{!showBtnLoader && <span>{text}</span>}

			{showBtnLoader && (
				<span className="inline-block h-5 w-5 animate-spin rounded-full border-[3px] border-current border-t-transparent text-white"></span>
			)}

			{withArrow && (
				<div
					className={classNames(
						"ml-2 h-0 w-0 border-y-6 border-l-6 border-y-transparent",
						{
							"border-l-white": theme === "active" && contentColor === "white",
						},
						{
							"border-l-black": theme === "active" && contentColor === "black",
						},
					)}
				></div>
			)}

			{children}
		</button>
	);
};

export default Button;
