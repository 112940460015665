import { useEffect, useState } from "react";
import classNames from "classnames";
import style from "./liffTab.module.scss";

import { Icon } from "@/atoms";
import V0Icon from "@/assets/img/liff/level-system/v0.webp";
import V1Icon from "@/assets/img/liff/level-system/v1.webp";
import V2Icon from "@/assets/img/liff/level-system/v2.webp";
import V3Icon from "@/assets/img/liff/level-system/v3.webp";
import V4Icon from "@/assets/img/liff/level-system/v4.webp";

/**
 * LiffTab
 * @param {'lg' | 'sm'} [tabStyle="sm"]
 * @param {Object[]} options
 * @param {string} activeTab
 * @param {void} setActiveTab
 * @param {boolean} isSticky
 * @param {boolean} withBorder
 * @param {Object} sortOrder  { key: string, sort: 'asc' | 'desc' }
 * @param {boolean} withIcon
 * @returns jsx
 */
const LiffTab = ({
	tabStyle = "sm",
	options,
	activeTab,
	setActiveTab,
	isSticky = false,
	withBorder,
	sortOrder,
	withIcon,
}) => {
	const [openTierList, setOpenTierList] = useState(true);

	const toggleTierList = () => {
		if (activeTab === "vip_code") {
			setOpenTierList(!openTierList);
		}
	};

	useEffect(() => {
		if (activeTab === "vip_code") {
			setOpenTierList(true);
		} else {
			setOpenTierList(false);
		}
	}, [activeTab]);

	const showTierList =
		activeTab === "vip_code" && openTierList ? "active" : "hidden";

	const highlightTab = sortOrder?.value ? false : true;

	return (
		<div className="sticky top-0 z-10">
			<div
				className={classNames(
					"flex border-b border-gray-200 bg-white py-2 text-sm font-medium text-[#00000099]",
					{
						"divide-x divide-gray-200": withBorder,
					},
					{
						"sticky top-0 z-10": isSticky,
					},
				)}
			>
				{options.map((item) => {
					return (
						<button
							className={classNames(
								"flex w-full flex-1 items-center justify-center",
								{
									"px-4 py-1": tabStyle === "lg",
								},
								{
									[style.tabActive]: activeTab === item.key,
								},
							)}
							key={item.key}
							onClick={() => {
								setActiveTab(item.key);
								toggleTierList();
							}}
						>
							{item.value}
							{withIcon &&
							activeTab === item.key &&
							activeTab !== "vip_code" ? (
								<div
									className={classNames(
										"h-5",
										{
											[style.sortDesc]: sortOrder.sort === "desc",
										},
										{
											[style.sortAsc]: sortOrder.sort === "asc",
										},
									)}
								>
									<Icon iconName={"UnfoldMore"} fontSize={20}></Icon>
								</div>
							) : (
								item.key === "vip_code" && (
									<div
										className="triangle-down"
										style={
											activeTab === "vip_code"
												? { borderTop: "6px solid black" }
												: {}
										}
									></div>
								)
							)}
						</button>
					);
				})}
			</div>
			{activeTab === "vip_code" && (
				<div
					className={`absolute bottom-0 z-[1] w-full translate-y-full ${showTierList}`}
				>
					<div className="flex h-[17.65vw] w-full bg-white px-[3.9vw] py-[2.97vw] shadow-lg">
						<div
							className="flex h-[11.71vw] w-[18.43vw] cursor-pointer items-center 
							justify-center border-r-[1.5px] border-[#E5E7EB]"
							onClick={() => setActiveTab("vip_code", "V0")}
						>
							<img
								className="w-[7.81vw]"
								style={
									highlightTab || sortOrder.value === "V0"
										? { opacity: 1 }
										: { opacity: 0.25 }
								}
								src={V0Icon}
								alt="V0"
							/>
						</div>
						<div
							className="flex h-[11.71vw] w-[18.43vw] cursor-pointer items-center 
							justify-center border-r-[1.5px] border-[#E5E7EB]"
							onClick={() => setActiveTab("vip_code", "V1")}
						>
							<img
								className="w-[7.81vw]"
								style={
									highlightTab || sortOrder.value === "V1"
										? { opacity: 1 }
										: { opacity: 0.25 }
								}
								src={V1Icon}
								alt="V1"
							/>
						</div>
						<div
							className="flex h-[11.71vw] w-[18.43vw] cursor-pointer items-center 
							justify-center border-r-[1.5px] border-[#E5E7EB]"
							onClick={() => setActiveTab("vip_code", "V2")}
						>
							<img
								className="w-[7.81vw]"
								style={
									highlightTab || sortOrder.value === "V2"
										? { opacity: 1 }
										: { opacity: 0.25 }
								}
								src={V2Icon}
								alt="V2"
							/>
						</div>
						<div
							className="flex h-[11.71vw] w-[18.43vw] cursor-pointer items-center 
							justify-center border-r-[1.5px] border-[#E5E7EB]"
							onClick={() => setActiveTab("vip_code", "V3")}
						>
							<img
								className="w-[7.81vw]"
								style={
									highlightTab || sortOrder.value === "V3"
										? { opacity: 1 }
										: { opacity: 0.25 }
								}
								src={V3Icon}
								alt="V3"
							/>
						</div>
						<div
							className="flex h-[11.71vw] w-[18.43vw] cursor-pointer items-center justify-center"
							onClick={() => setActiveTab("vip_code", "V4")}
						>
							<img
								className="w-[7.81vw]"
								style={
									highlightTab || sortOrder.value === "V4"
										? { opacity: 1 }
										: { opacity: 0.25 }
								}
								src={V4Icon}
								alt="V4"
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default LiffTab;
