import { memo } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";

/**
 * Textarea
 * @param {string} name
 * @param {string} label 顯示名稱
 * @param {boolean} required 是否必填
 * @param {RegExp} pattern
 * @param {number} maxLength
 * @param {number} minLength
 * @param {boolean} isDisabled 禁用
 * @param {boolean} isReadOnly
 * @param {boolean} requiredMark 是否顯示必填＊
 * @param {string} placeholder
 * @param {React.ReactNode} children
 * @param {string} errMsg 沒有label時的錯誤訊息
 * @param {'default' | 'admin'} theme 主題：前台 | 後台
 * @param {boolean} [resize = false] 是否允許改變大小
 * @returns jsx
 */

const Textarea = ({
	name,
	label,
	required,
	pattern,
	maxLength,
	minLength,
	isDisabled,
	isReadOnly,
	requiredMark = true,
	placeholder,
	children,
	errMsg,
	theme,
	resize = false,
}) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	return (
		<>
			{label && (
				<label
					htmlFor={name}
					className={classNames(
						"mb-2.5 block text-sm font-medium text-[#333333]",
						{
							"text-red-600": !!errors[name]?.message,
						},
					)}
				>
					{label}
					{requiredMark && <span className="text-red-600">*</span>}
				</label>
			)}

			<div>
				<textarea
					id={name}
					name={name}
					className={classNames(
						{
							"w-full border-2 border-customGray bg-transparent p-3.5 text-sm leading-[14px] selection:bg-transparent focus:border-black focus:outline-none":
								theme === "default",
						},
						{
							"block w-full rounded-lg px-4 py-3 text-sm outline outline-1 outline-customGray focus:outline-blue-500 focus:ring-blue-500":
								theme === "admin",
						},
						{
							"border-red-600 focus:border-red-600":
								!!errors[name]?.message && theme === "default",
						},
						{
							"outline-red-600 focus:outline-red-600":
								!!errors[name]?.message && theme === "admin",
						},
						{
							"min-h-[100px] resize-none": !resize,
						},
						{
							"text-blackT30": isDisabled,
							"text-blackT30 focus:border-customGray": isReadOnly,
						},
					)}
					placeholder={placeholder}
					readOnly={isReadOnly}
					{...register(name, {
						disabled: isDisabled,
						required: {
							value: required,
							message: label ? `${label}為必填` : errMsg,
						},
						pattern: { value: pattern, message: `${label}格式不正確` },
						maxLength: {
							value: maxLength,
							message: `長度不可以大於${maxLength}個字元`,
						},
						minLength: {
							value: minLength,
							message: `長度不可以小於${minLength}個字元`,
						},
					})}
				/>
				{children}
			</div>
			<span className="block text-xs text-red-600">
				{errors[name]?.message}
			</span>
		</>
	);
};

export default memo(Textarea);
