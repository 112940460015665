import { memo, Fragment } from "react";
import { useFormContext } from "react-hook-form";

/**
 * Radio
 * @param {string} label
 * @param {string} name
 * @param {Object[]} options
 * @param {boolean} required
 * @param {boolean} [requiredMark = true] 是否顯示必填＊
 * @returns jsx
 */
const Radio = ({ label, name, options, required, requiredMark = true }) => {
	const { register } = useFormContext();
	return (
		<>
			<div>
				<span className="text-sm font-medium text-[#333333]">{label}</span>
				{requiredMark && <span className="text-red-600">*</span>}
			</div>
			<div className="flex items-center">
				{options.map((item) => {
					return (
						<Fragment key={item.key}>
							<div className="h-10 w-10 p-2">
								<input
									type="radio"
									id={item.key}
									name={name}
									className="h-full w-full accent-black"
									value={item.key}
									{...register(name, {
										required: {
											value: required,
										},
									})}
								/>
							</div>
							<label htmlFor={name}>{item.value}</label>
						</Fragment>
					);
				})}
			</div>
		</>
	);
};

export default memo(Radio);
