import { useContext } from "react";
import { LiffContext, api } from "@/utils";

const useGetPointInfo = () => {
	// reducer
	const { liffActions } = useContext(LiffContext);
	const getPointInfo = async () => {
		try {
			const res = await api(10).get("/get_crm_member_point_info");
			liffActions.setPointInfo(res.data);
		} catch (error) {
			console.error("Get Point Info Failed", error);
		}
	};
	return { getPointInfo };
};

export default useGetPointInfo;
