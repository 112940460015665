import { useRef, useContext } from "react";
import { Link } from "react-router-dom";

import QRCode from "react-qr-code";

import { Icon } from "@/atoms";
import { Popup, LiffAvatar } from "@/components";
import { LiffContext } from "@/utils";
import tabButtonBg from "@/assets/img/liff/tab-button-bg.png";
import "./index.scss";

const LiffFooter = () => {
	// qr code彈窗
	const qrCodePopupRef = useRef();
	// reducer
	const { liffState } = useContext(LiffContext);
	return (
		<>
			<div className="fixed inset-x-0 bottom-0 z-20">
				<div className="absolute inset-x-0 bottom-5 top-0 z-30 flex items-center justify-center">
					<Link
						to={"/activity"}
						className="flex size-20 flex-col items-center justify-center bg-cover  bg-center p-4"
					>
						<img
							className="scale-down absolute size-full"
							src={tabButtonBg}
							alt=""
						/>
						<Icon className="rotating-box size-9" iconName={"Mission"}></Icon>
						<span className="fade-out text-xs">任務牆</span>
					</Link>
				</div>
				<div className="flex h-[75px] w-full justify-between border-t-[0.1px] border-solid border-gray-200 bg-white">
					<Link
						to={"/redeem"}
						className="z-50 flex w-[25vw] flex-col items-center justify-center px-[1.8vw]"
					>
						<Icon iconName={"Redeem"}></Icon>
						<span className="pt-1 text-xs text-blackT60">點數商城</span>
					</Link>
					<Link
						to={"/coupon"}
						className="z-50 flex w-[25vw] flex-col items-center justify-center px-[1.8vw]"
					>
						<Icon iconName={"Coupon"}></Icon>
						<span className="pt-1 text-xs text-blackT60">我的優惠券</span>
					</Link>
					<div className="w-[17vw] px-5"></div>

					<div
						className="z-50 flex w-[25vw] flex-col items-center justify-center px-[1.8vw]"
						onClick={() => qrCodePopupRef.current.showPopup()}
					>
						<Icon iconName={"QrCode"} fontSize="24px"></Icon>
						<span className="inline pt-1 text-xs text-blackT60">會員條碼</span>
					</div>
					<Link
						className="z-50 flex w-[25vw] flex-col items-center justify-center px-[1.8vw]"
						to={"/"}
					>
						<Icon iconName={"Person"}></Icon>
						<span className="pt-1 text-xs text-blackT60">會員中心</span>
					</Link>
				</div>
			</div>
			<Popup ref={qrCodePopupRef}>
				<div className="flex items-center">
					{/* avatar / name */}
					<LiffAvatar></LiffAvatar>
					{/* 姓名、會員ID */}
					<div className="ml-2 flex flex-col text-base font-medium">
						<span>{liffState.me?.line_name}</span>
						<span className="break-all text-sm text-gray-500">
							{liffState.memberData?.cardNumber}
						</span>
					</div>
				</div>
				<div className="mt-10 flex justify-center">
					<QRCode
						size={172}
						value={
							liffState.memberData?.cardNumber && liffState.memberData?.tierCode
								? `${liffState.memberData.cardNumber}|${liffState.memberData.tierCode}`
								: ""
						}
					></QRCode>
				</div>
			</Popup>
		</>
	);
};

export default LiffFooter;
