import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LiffTab, Skeleton } from "@/components";
import { LiffContext } from "@/utils";
import { SHIPPING_STATUS } from "@/utils/interface/exchange";
import { useGetUserExchangeList } from "@/hooks";

const filterItems = [
	{ key: "ALL", value: "全部" },
	{ key: "SHIPPING", value: "已寄送" },
	{ key: "WAITING", value: "待寄送" },
];

const ExchangeHistoryPage = () => {
	const [activeTab, setActiveTab] = useState(filterItems[0].key);
	const { getUserExchangeList, exchangeData, isLoading } =
		useGetUserExchangeList();
	// reducer
	const { liffActions } = useContext(LiffContext);
	const navigate = useNavigate();

	// 獲取寄送狀態文字
	const getStatusText = (status) => {
		switch (status) {
			case SHIPPING_STATUS.SHIPPING:
				return "已寄送";
			case SHIPPING_STATUS.WAITING:
				return "待寄送";
			default:
				return "";
		}
	};

	useEffect(() => {
		liffActions.setShowFullPageLoader(false);
		getUserExchangeList();
	}, []);

	return (
		<>
			<div className="mb-[75px] flex size-full max-h-full flex-none flex-col">
				<LiffTab
					tabStyle="lg"
					options={filterItems}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					withBorder={true}
					isSticky={true}
				></LiffTab>

				{isLoading ? (
					<>
						{/* Skeleton */}
						<div className="mx-auto w-full max-w-sm animate-pulse space-y-4 border-b p-3">
							<Skeleton
								type="rectangular"
								widthClass="w-full"
								heightClass="h-4"
							></Skeleton>
							<div className="flex gap-3">
								<Skeleton
									type="rectangular"
									widthClass="w-20"
									heightClass="h-20"
									className="shrink-0"
								></Skeleton>
								<div className="mr-4 flex w-full flex-col justify-center space-y-2">
									<Skeleton type="rectangular" heightClass="h-4"></Skeleton>
									<Skeleton type="rectangular" heightClass="h-4"></Skeleton>
									<Skeleton type="rectangular" heightClass="h-4"></Skeleton>
								</div>
							</div>
							<Skeleton
								type="rectangular"
								widthClass="w-full"
								heightClass="h-4"
							></Skeleton>
						</div>
						<div className="mx-auto w-full max-w-sm animate-pulse space-y-4 border-b p-3">
							<Skeleton
								type="rectangular"
								widthClass="w-full"
								heightClass="h-4"
							></Skeleton>
							<div className="flex gap-3">
								<Skeleton
									type="rectangular"
									widthClass="w-20"
									heightClass="h-20"
									className="shrink-0"
								></Skeleton>
								<div className="mr-4 flex w-full flex-col justify-center space-y-2">
									<Skeleton type="rectangular" heightClass="h-4"></Skeleton>
									<Skeleton type="rectangular" heightClass="h-4"></Skeleton>
									<Skeleton type="rectangular" heightClass="h-4"></Skeleton>
								</div>
							</div>
							<Skeleton
								type="rectangular"
								widthClass="w-full"
								heightClass="h-4"
							></Skeleton>
						</div>
					</>
				) : (
					exchangeData
						.filter((item) =>
							activeTab === "ALL" ? true : item.shipping_status === activeTab,
						)
						.map((item) => {
							const statusText = getStatusText(item.shipping_status);
							return (
								<div key={item.id} className="w-full overflow-y-scroll">
									<div className="w-full text-black">
										<div
											className="space-y-2 border-b p-3"
											onClick={() => navigate(`/exchange/${item.product_id}`)}
										>
											{/* 寄送狀態 */}
											<div className="flex justify-between">
												<span className="font-medium">{`#${item.ref_id}`}</span>
												<span>{statusText}</span>
											</div>
											{/* 商品資訊 */}
											<div className="flex justify-between gap-4">
												<div className="flex gap-2">
													<div className="size-20">
														<img
															src={item.product_images[0].url}
															alt="商品圖"
														/>
													</div>
													<span>{item.product_name}</span>
												</div>
												<div className="text-sm text-gray-400">{`x ${item.quantity}`}</div>
											</div>
											{/* 數量 */}
											<div className="text-right font-medium">{`總計：${item.quantity}`}</div>
										</div>
									</div>
								</div>
							);
						})
				)}
			</div>
		</>
	);
};

export default ExchangeHistoryPage;
