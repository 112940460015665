import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import TablePagination from "@mui/material/TablePagination";
import toast from "react-hot-toast";

import { AdminPopup, Dropdown } from "@/components";
import { Button, Checkbox, Checkboxes, Form, Select, InputDate } from "@/atoms";
import { LiffContext, api } from "@/utils";
import {
	useCheckboxSelection,
	usePagination,
	useFormatAsLocalTime,
} from "@/hooks";
import { SHIPPING_STATUS } from "@/utils/interface/exchange";

dayjs.extend(utc);

const shippingStatusOptions = [
	{ key: "ALL", value: "全部" },
	{ key: "SHIPPING", value: "已出貨" },
	{ key: "WAITING", value: "待出貨" },
];

const ExchangeListPage = () => {
	// 顯示的兌換紀錄列表
	const [showExchangeList, setShowExchangeList] = useState([]);
	// table核取方塊子層列表
	const [childCheckboxes, setChildCheckboxes] = useState([]);
	// 開始日期
	const [exchangeStartDate, setExchangeStartDate] = useState(null);

	const [exchangeFilterData] = useState({
		startDate: null,
		endDate: null,
		shipping_status: shippingStatusOptions[0].key,
	});
	// 現在彈窗的內容
	const [nowPopupContent, setNowPopupContent] = useState(null);

	// reducer
	const { liffActions } = useContext(LiffContext);
	// checkbox選取
	const { selectedId, onSelectedItem, setSelectedId } =
		useCheckboxSelection(showExchangeList);

	// 分頁
	const {
		handleChangePage,
		handleChangeRowsPerPage,
		pageSize,
		currentPage,
		setCurrentPage,
		totalItems,
		setTotalItems,
	} = usePagination(5, "exch");
	const { formatAsLocalTime } = useFormatAsLocalTime();

	const navigate = useNavigate();
	const tableForm = useRef(null);
	const confirmPopup = useRef(null);

	// 獲取兌換紀錄列表
	const getExchangeList = async (filterData) => {
		liffActions.setShowFullPageLoader(true);

		try {
			const res = await api().get("/admin/get_user_exchange_list", {
				params: {
					page: currentPage + 1,
					pageSize: pageSize,
					sortOrder: "desc",
					startDate: filterData ? filterData.startDate : null,
					endDate: filterData ? filterData.endDate : null,
					shipping_status: filterData
						? filterData.shipping_status
						: shippingStatusOptions[0].key,
				},
			});

			const { ref_list, current_page, total } = res.data;

			setShowExchangeList(ref_list);
			setChildCheckboxes(ref_list.map((item) => `${item.ref_id}`));
			setCurrentPage(current_page - 1);
			setTotalItems(total);
		} catch (error) {
			console.error("Get Exchange List Failed");

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 獲取寄送狀態文字
	const getStatusText = (shippingStatus) => {
		switch (shippingStatus) {
			case SHIPPING_STATUS.SHIPPING:
				return "已出貨";
			case SHIPPING_STATUS.WAITING:
				return "待出貨";
			default:
				return "";
		}
	};

	const filterExchangeList = async (data) => {
		// 轉換UTC時間
		const formattedStartDate = data.startDate
			? dayjs(data.startDate).utc().format()
			: null;
		const formattedExchangeDateGte = data.endDate
			? dayjs(data.endDate).utc().format()
			: null;

		const filterData = {
			...data,
			startDate: formattedStartDate,
			endDate: formattedExchangeDateGte,
		};

		await getExchangeList(filterData);
	};

	// 批次設定為已出貨 or 待出貨
	const batchSetShippingStatus = async (status) => {
		try {
			const res = await api().post(
				"admin/batch_user_exchange_action_shipping_status",
				{
					userExchangeActionIdList: selectedId,
					shipping_status: status,
				},
			);

			if (res.data === "success") {
				confirmPopup.current.closePopup();
				setSelectedId([]);
				tableForm.current.reset();
				toast.success("設定成功");
				getExchangeList();
			}
		} catch (error) {
			console.error("Batch Set Shipping Status Failed");

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		}
	};

	// 批量刪除商品 or 帶入單一商品ID刪除
	const batchDeleteExchangeList = async () => {
		try {
			const res = await api().post("/admin/batch_delete_user_exchange_action", {
				userExchangeActionIdList: selectedId,
			});

			if (res.data === "success") {
				confirmPopup.current.closePopup();
				toast.success("刪除成功");
				setSelectedId([]);
				getExchangeList();
			}
		} catch (error) {
			console.error("Batch Delete Exchange List Failed");

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
						break;
				}
			}
		}
	};
	
	const downloadExchangeList = async () => {
    try {
			const res = await api().get("/admin/download_user_exchange_list", {
				params: {
					ids: selectedId,
					sortOrder: 'desc',
					shipping_status: exchangeFilterData.shipping_status
				},
				responseType: 'blob'
			});

			const url = window.URL.createObjectURL(res);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "user-exchange-actions.xlsx");
			document.body.appendChild(link);
			link.click();
    } catch (error) {
			console.error("Download Exchange List Failed");
			toast.error("下載報表失敗，請再試一次。");
    }
	};

	const onSubmit = () => {};

	useEffect(() => {
		getExchangeList();
	}, [pageSize, currentPage]);

	return (
		<>
			<div className="space-y-6">
				{/* Filter */}
				<Form onSubmit={filterExchangeList} defaultValues={exchangeFilterData}>
					<div className="space-y-6 md:flex md:items-end md:gap-x-2">
						{/* 開始日期 */}
						<div>
							<InputDate
								theme="admin"
								name="startDate"
								required={false}
								type="date"
								label="開始日期"
								requiredMark={false}
								onChange={(e) => {
									setExchangeStartDate(e.target.value);
								}}
							></InputDate>
						</div>
						{/* 結束日期 */}
						<div>
							<InputDate
								theme="admin"
								name="endDate"
								required={false}
								type="date"
								label="結束日期"
								requiredMark={false}
								minDate={exchangeStartDate}
							></InputDate>
						</div>
						{/* 出貨狀態 */}
						<div>
							<span className="mb-2.5 block text-sm font-medium text-[#333333]">
								出貨狀態
							</span>
							<div>
								<Select
									name="shipping_status"
									placeholder="請選擇出貨狀態"
									required={false}
									selectOptions={shippingStatusOptions}
									icon="ArrowDropDown"
									theme="admin"
									requiredMark={false}
								></Select>
							</div>
						</div>
						{/* btn */}
						<div>
							<Button type="submit" theme="admin" text="搜尋"></Button>
						</div>
					</div>
				</Form>

				{/* Table */}
				<Form onSubmit={onSubmit} ref={tableForm}>
					<Checkboxes childCheckboxes={childCheckboxes}>
						<div className="inline-block w-full bg-white align-middle">
							<div className="w-full divide-y divide-gray-100 rounded-lg border">
								<div
									className={classNames("flex h-16 px-4 py-3", {
										"justify-between": selectedId.length > 0,
										"justify-end": selectedId.length === 0,
									})}
								>
									{selectedId.length > 0 && (
										<span className="self-end text-sm text-blue-600">
											{`已選取${selectedId.length}項商品`}
										</span>
									)}
									<div className="flex gap-4">
										{/* 批次調整 */}
										<Dropdown
											text="批次調整"
											isDisabled={selectedId.length === 0}
										>
											<li
												className="cursor-pointer whitespace-nowrap px-4 py-2 hover:rounded-lg hover:bg-gray-100"
												onClick={() => {
													setNowPopupContent({
														text: `是否確定將${selectedId.length}筆資料設定為已出貨？`,
														action: () => batchSetShippingStatus("SHIPPING"),
													});
													confirmPopup.current.showPopup();
												}}
											>
												設定為已出貨
											</li>
											<li
												className="cursor-pointer px-4 py-2 hover:rounded-lg hover:bg-gray-100"
												onClick={() => {
													setNowPopupContent({
														text: `是否確定將${selectedId.length}筆資料設定為待出貨？`,
														action: () => batchSetShippingStatus("WAITING"),
													});
													confirmPopup.current.showPopup();
												}}
											>
												設定為待出貨
											</li>
										</Dropdown>

										{/* 更多動作 */}
										<Dropdown
											text="更多動作"
											isDisabled={selectedId.length === 0}
										>
											<li
												className="cursor-pointer whitespace-nowrap px-4 py-2 hover:rounded-lg hover:bg-gray-100"
												onClick={()=>downloadExchangeList()}
											>
												下載報表
											</li>
											<li
												className="cursor-pointer px-4 py-2 hover:rounded-lg hover:bg-gray-100"
												onClick={() => {
													setNowPopupContent({
														text: `是否確定刪除${selectedId.length}個紀錄？\n一經刪除即永久無法復原`,
														action: batchDeleteExchangeList,
													});
													confirmPopup.current.showPopup();
												}}
											>
												刪除
											</li>
										</Dropdown>
									</div>
								</div>
								<div className="overflow-x-auto">
									<table className="min-h-[150px] min-w-full divide-y divide-gray-100">
										<thead className="bg-gray-100">
											<tr>
												<th
													scope="col"
													className="sticky left-0 z-[2] bg-gray-100 px-4 py-3"
												>
													<div className="flex items-center whitespace-nowrap">
														<Checkbox
															required={false}
															name="selectedAll"
															isSelectAll={true}
															onChange={(e) => onSelectedItem(e, "selectedAll")}
														></Checkbox>
													</div>
												</th>

												<th
													scope="col"
													className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
												>
													兌換單號
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
												>
													兌換日期
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
												>
													商品名稱
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
												>
													TNF會員編號
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
												>
													手機號碼
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-6 py-3 text-center font-medium uppercase text-blackT80"
												>
													出貨狀態
												</th>
												<th
													scope="col"
													className="sticky right-0 z-[2] bg-gray-100 px-4 py-3"
												></th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-100">
											{showExchangeList.length > 0 ? (
												showExchangeList.map((item) => {
													return (
														<Fragment key={item.ref_id}>
															<tr className="group hover:bg-gray-50">
																<td className="sticky left-0 z-[2] bg-white px-4 py-3 group-hover:bg-gray-50">
																	<div className="flex h-5 items-center">
																		<Checkbox
																			name={item.ref_id}
																			onChange={(e) =>
																				onSelectedItem(e, item.id)
																			}
																		></Checkbox>
																	</div>
																</td>
																{/* 兌換單號 */}
																<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																	{item.ref_id}
																</td>
																{/* 兌換日期 */}
																<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																	{formatAsLocalTime(
																		item.exchange_at,
																		"YYYY/MM/DD",
																	)}
																</td>
																{/* 商品名稱 */}
																<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																	{item.exchange_products.map(
																		(product, index) => (
																			<div
																				key={index}
																			>{`${product.display_name} *${product.quantity}`}</div>
																		),
																	)}
																</td>
																{/* TNF會員編號 */}
																<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																	{item.tnf_id}
																</td>
																{/* 手機號碼 */}
																<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																	{item.receive_mobile}
																</td>
																{/* 出貨狀態 */}
																<td className="whitespace-nowrap px-6 py-4 text-center text-blackT80">
																	{getStatusText(item.shipping_status)}
																</td>
																{/* 修改按鈕 */}
																<td className="sticky right-0 z-[2] bg-white px-4 py-3 group-hover:bg-gray-50">
																	<div className="flex items-center gap-2">
																		<Button
																			type="button"
																			theme="admin"
																			size="sm"
																			text="編輯"
																			widthFull={false}
																			borderOnly={true}
																			onClick={() =>
																				navigate(
																					`/admin/exchangeList/edit/${item.ref_id}`,
																				)
																			}
																		></Button>
																		<Button
																			type="button"
																			theme="admin"
																			size="sm"
																			text="刪除"
																			widthFull={false}
																			onClick={() => {
																				setNowPopupContent({
																					text: `是否確定刪除【${item.ref_id}】？\n一經刪除即永久無法復原`,
																					action: () =>
																						batchDeleteExchangeList(
																							item.ref_id,
																						),
																				});
																				confirmPopup.current.showPopup();
																			}}
																		></Button>
																	</div>
																</td>
															</tr>
														</Fragment>
													);
												})
											) : (
												<tr>
													<td
														colSpan="100%"
														className="py-4 text-center font-medium"
													>
														查無資料
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								{/* Pagination */}
								<TablePagination
									component="div"
									count={totalItems}
									page={currentPage}
									rowsPerPage={pageSize}
									onPageChange={(event, newPage) => handleChangePage(newPage)}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="每頁行數"
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
								></TablePagination>
							</div>
						</div>
					</Checkboxes>
				</Form>
			</div>

			<AdminPopup
				ref={confirmPopup}
				onConfirm={nowPopupContent ? nowPopupContent.action : null}
			>
				<p className="mt-6 block whitespace-pre-wrap text-center">
					{nowPopupContent && nowPopupContent.text}
				</p>
			</AdminPopup>
		</>
	);
};

export default ExchangeListPage;
