import { useImperativeHandle, forwardRef, useState, useRef } from "react";
import { Button } from "@/atoms";
import { useOutsideClick } from "@/hooks";

// TODO: 將Popup功能抽出來，並且將樣式抽出來，樣式分為前台，後台，活動頁面
/**
 * Popup
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode} props.btnContext
 * @param {string} [props.title=null]
 * @param {boolean} [props.hasBtn=true]
 * @param {React.Ref} ref
 * @param {function} [onClose]
 * @param {'default' | 'active'} [type === "default"] 彈窗樣式：點數商城 | 活動頁面
 * @param {string} [closeBtnText="確認"] 關閉按鈕文字
 * @returns jsx
 */
const Popup = forwardRef(
	(
		{
			children,
			btnContext,
			title = null,
			hasBtn = true,
			onClose,
			type = "default",
			closeBtnText = "確認",
			processClosePopupAction = null,
		},
		ref,
	) => {
		//  是否顯示彈窗
		const [isShowPopup, setIsShowPopup] = useState(false);
		// 是否點擊外部關閉彈窗
		const [activateClickOutside, setActivateClickOutside] = useState(false);

		const innerRef = useRef();

		// 開啟彈窗
		const onShowPopup = () => {
			setIsShowPopup(true);
			setTimeout(() => setActivateClickOutside(true), 0);
		};

		// 關閉彈窗
		const onClosePopup = () => {
			setIsShowPopup(false);
			setActivateClickOutside(false);

			if (onClose) {
				onClose();
			}

			if(processClosePopupAction){
				// if has set processClosePopupAction, execute it
				processClosePopupAction();
			}
		};

		useOutsideClick(innerRef, onClosePopup, activateClickOutside);

		useImperativeHandle(ref, () => {
			return {
				showPopup: () => onShowPopup(), // 開啟彈窗
				closePopup: () => onClosePopup(), // 關閉彈窗
			};
		});

		return (
			isShowPopup && (
				<div className="fixed inset-0 z-100 h-screen w-full bg-[#00000080]">
					{type === "default" && (
						<div
							className="fixed left-1/2 top-1/2 z-200 w-[311px] -translate-x-1/2 -translate-y-1/2 bg-white p-6"
							ref={innerRef}
						>
							{/* title */}
							{title && <div className="pb-6 text-xl font-medium">{title}</div>}

							{/* content */}
							{children}

							{/* button */}
							{hasBtn && (
								<div className="mt-10 flex gap-2">
									<Button
										type="button"
										text={closeBtnText}
										theme="default"
										color="black"
										onClick={() => {
											onClosePopup();
										}}
									></Button>
									{btnContext}
								</div>
							)}
						</div>
					)}

					{type === "active" && (
						<div className="fixed left-1/2 top-1/2 z-200 w-[240px] -translate-x-1/2 -translate-y-1/2 rounded-md border-2 border-white bg-transparent p-6">
							{children}
						</div>
					)}
					{type === "active-black" && (
						<div className="fixed left-1/2 top-1/2 z-200 w-[240px] -translate-x-1/2 -translate-y-1/2 rounded-md border-2 border-white bg-[#00000090] p-6">
							{children}
						</div>
					)}
					{type === "active" || type === "active-black" && (
						<Button
							type="button"
							theme="active"
							color="white"
							text="確認"
							withArrow={true}
							contentColor="black"
							className="absolute left-1/2 top-[63.5%] w-32 -translate-x-1/2"
							onClick={() => {
								onClosePopup();
							}}
							widthFull={false}
						></Button>
					)}
				</div>
			)
		);
	},
);

Popup.displayName = "Popup";

export default Popup;
