import { useContext, useEffect, useRef, useState } from "react";

import toast from "react-hot-toast";

import {
	Form,
	Input,
	PhoneNumberField,
	Checkbox,
	Button,
	Select,
} from "@/atoms";
import {
	Counter,
	LiffAgreementPopup,
	MembershipTerms,
	PrivacyPolicy,
	Popup,
} from "@/components";
import { AreaData, ZipCodes } from "../data/index";
import { AppError, LiffContext, api } from "@/utils";

const DeliveryForm = ({ onPopupClose, setSuccessInfo }) => {
	const [districtOptions, setDistrictOptions] = useState([]);
	// 檢查表單是否有效
	const [isFormValid, setIsFormValid] = useState(false);
	// 兌換點數總計
	const [redeemTotalPoint, setRedeemTotalPoint] = useState(0);
	// 兌換數量總計
	const [redeemTotalQuantity, setRedeemTotalQuantity] = useState(1);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	// 儲存後端回傳的收件人資訊
	const [userAddressData, setUserAddressData] = useState({
		receiveName: null,
		city: null,
		district: null,
		receiveAddress: null,
		receiveMobile: null,
	});

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);

	// 會員權益聲明彈窗
	const membersPopupRef = useRef(null);
	// 隱私權政策彈窗
	const privacyPopupRef = useRef(null);
	// 送出表單彈窗
	const submitPopup = useRef(null);
	// 點數扣除失敗彈窗
	const pointDeductionFailedPopup = useRef(null);
	// 收件人資訊表單
	const deliverForm = useRef(null);

	const getUserAddress = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/get_user_address");
			const userAddress = res.data;

			let city = "";
			let district = "";
			let address = "";
			if (userAddress.receive_address) {
				const parts = userAddress?.receive_address.split(" ");
				city = parts[1];
				district = parts[2];
				address = parts[3];
			}

			if (userAddress) {
				setUserAddressData({
					receiveName: userAddress?.receive_name,
					city,
					district,
					receiveAddress: address,
					receiveMobile: `${userAddress?.receive_mobile}`,
				});

				// 獲取區域下拉選單資料
				city && getDistrictOptions(city, district);
			}
		} catch (error) {
			console.error("Get User Address Failed", error);
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 獲取縣市下拉選單資料
	const cityOptions = AreaData.map((item) => ({
		key: item.city,
		value: item.city,
	}));

	// 獲取區域下拉選單資料
	const getDistrictOptions = (city, district) => {
		const districtOptions = AreaData.find(
			(item) => item.city === city,
		)?.district?.map((item) => ({
			key: item,
			value: item,
		}));

		// 獲取區域下拉選單資料
		setDistrictOptions(districtOptions);

		// 如果已選擇的區域不在新的區域選項中，清空已選擇區域下拉選單
		if (!districtOptions.find((item) => item.key === district)) {
			setUserAddressData((pre) => ({
				...pre,
				district: null,
			}));
			deliverForm.current.setValue("district", null);
		}
	};

	// 送出兌換資料
	const createExchangeProduct = async (formValues) => {
		setIsBtnLoading(true);
		try {
			const res = await api(10).post("/exchange_product", formValues);
			if (res.data) {
				submitPopup.current.showPopup();

				setSuccessInfo(res.data);
			}
		} catch (error) {
			console.error("Create Exchange Product Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.NO_FOUND:
						toast.error("很抱歉，找不到商品。");
						break;
					case AppError.NOT_ENOUGH_POINTS:
						toast.error("點數不足，無法兌換。");
						break;
					case AppError.EXCHANGE_IS_FULL:
						toast.error("很抱歉，此商品已兌換完畢。");
						break;
					case AppError.THE_EXCHANGE_QUOTA_FOR_ONE_USER_IS_FULL:
						toast.error("很抱歉，您已達兌換上限。");
						break;
					case AppError.EXCHANGE_DATE_ERROR:
						toast.error("很抱歉，此商品已兌換結束。");
						break;
					case AppError.NOT_ENOUGH_TIER:
						toast.error("很抱歉，您的會員等級無法兌換此商品。");
						break;
					// AppError.FIND_EXCHANGE_REF_ID_ERROR：api retry
					case AppError.CRM_POINT_REDEEM_ERROR_CODE:
						pointDeductionFailedPopup.current.showPopup();
						break;
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			setIsBtnLoading(false);
		}
	};

	const onSubmit = (data) => {
		// 點數不足，禁止兌換
		if (redeemTotalPoint + liffState?.pointInfo?.availablePoint < 0) {
			toast.error("點數不足，無法兌換。");
			return;
		}

		// 獲取郵遞區號
		const zipCode = ZipCodes[data.city]?.[data.district];
		const formValues = {
			productId: liffState?.productIntro?.id,
			quantity: redeemTotalQuantity,
			receiveName: data.receiveName,
			receiveAddress: `${zipCode} ${data.city} ${data.district} ${data.receiveAddress}`,
			receiveMobile: data.receiveMobile,
			isSave: data.isSave,
		};
		console.log(formValues);
		createExchangeProduct(formValues);
	};

	const handlePopupClose = () => {
		if (onPopupClose) {
			onPopupClose();
		}
	};

	const onCityChange = (e) => {
		const city = e.target.value;

		// 更新city欄位值
		deliverForm.current.setValue("city", city);

		setUserAddressData((pre) => ({
			...pre,
			city: city,
		}));

		// 獲取區域下拉選單資料
		getDistrictOptions(city, userAddressData.district);
	};

	useEffect(() => {
		getUserAddress();
	}, []);

	return (
		<>
			{!liffState.showFullPageLoader && (
				<>
					<div className="mb-[75px] w-full space-y-2 bg-white p-5">
						<Form
							onSubmit={onSubmit}
							defaultValues={userAddressData}
							ref={deliverForm}
							onValidChange={setIsFormValid}
						>
							<div className="space-y-4">
								{/* 商品資訊 */}
								<div className="flex justify-between gap-4">
									<div className="flex gap-2">
										{/* 商品圖 */}
										<div className="size-20">
											<img
												className="size-full object-contain"
												src={liffState?.productIntro?.images[0]?.url}
												alt=""
											/>
										</div>
										{/* 商品名稱 */}
										<span className="text-sm">
											{liffState?.productIntro?.display_name}
										</span>
									</div>
									{/* 點數 */}
									<span className="text-sm font-medium">{`${liffState?.productIntro?.point}點`}</span>
								</div>

								{/* 選擇兌換數量 */}
								<div className="flex justify-between">
									<div className="flex flex-col">
										<span className="font-medium">請選擇兌換份數</span>
										<span className="text-xs text-red-500">{`每位會員限兌換${liffState?.productIntro?.limited}份`}</span>
									</div>
									{/* counter */}
									<Counter
										initNum={1}
										limit={liffState?.productIntro?.limited}
										onChange={(num) => {
											setRedeemTotalPoint(
												num * liffState?.productIntro?.point * -1,
											);
											setRedeemTotalQuantity(num);
										}}
									></Counter>
								</div>

								{/* 目前點數 */}
								<div className="flex justify-between text-sm">
									<span>目前點數：</span>
									<span>{`${liffState?.pointInfo?.availablePoint}點`}</span>
								</div>

								{/* 兌換點數總計 */}
								<div className="flex justify-between text-sm">
									<span>兌換點數總計：</span>
									<div className="flex flex-col items-end text-red-600">
										<span>{`${redeemTotalPoint} 點`}</span>
										{redeemTotalPoint + liffState?.pointInfo?.availablePoint <
										0 ? (
											<span>點數不足</span>
										) : null}
									</div>
								</div>

								{/* Form */}
								<div>
									<span className="font-medium">請輸入收件人資訊</span>
								</div>

								{/* 姓名 */}
								<div>
									<Input
										name="receiveName"
										label="收件人"
										type="text"
										required={true}
										placeholder="請輸入收件人姓名"
										minLength={1}
										maxLength={100}
									></Input>
								</div>

								{/* 手機 */}
								<div>
									<PhoneNumberField
										name="receiveMobile"
										label="手機"
										type="tel"
										required={true}
										placeholder="請輸入手機號碼"
										requiredMark={true}
									></PhoneNumberField>
								</div>

								{/* 收件地址 */}
								<div>
									<div className="mb-2.5 block text-sm font-medium text-[#333333]">
										收件地址
										<span className="text-red-600">*</span>
									</div>
									{/* 縣市下拉選單 */}
									<div className="mb-4 flex gap-4">
										<div className="w-1/2">
											<Select
												name="city"
												required={true}
												icon="ArrowDropDown"
												placeholder="請選擇縣市"
												selectOptions={cityOptions}
												onChange={(e) => onCityChange(e)}
											></Select>
										</div>
										<div className="w-1/2">
											<Select
												name="district"
												required={true}
												icon="ArrowDropDown"
												placeholder="請選擇區域"
												selectOptions={districtOptions}
											></Select>
										</div>
									</div>
									{/* 地址input */}
									<Input
										name="receiveAddress"
										type="text"
										required={true}
										errMsg="請填寫收件地址"
										minLength={1}
										maxLength={200}
									></Input>
								</div>

								{/* 儲存為預設收件人資訊 */}
								<div>
									<Checkbox name="isSave" required={false}>
										<span className="ml-3 block text-sm">
											儲存為預設收件人資訊
										</span>
									</Checkbox>
								</div>

								{/* 注意事項 */}
								<div className="py-3">
									<span className="mb-3 block font-medium">注意事項</span>
									<ul className="list-disc pl-[40px] text-sm">
										<li>
											兌換後恕無法修改收件資料，務必於兌換頁面詳細確認收件資料。
										</li>

										<li>
											以會員點數兌換之禮品或票券，恕無法退/換貨，亦不能轉售。
										</li>

										<li>
											以會員點數兌換之禮品或票券，不得以任何理由，要求台灣威富返還已進行禮品或票券兌換之會員點數或額外給予其他補償。
										</li>
										<li>
											台灣威富保有最終決定取消、修改、暫停兌換活動之權利。
										</li>
									</ul>
								</div>

								{/* 同意聲明 */}
								<div>
									<Checkbox name="agreement" required={true} errMsg="請勾選">
										<div className="ml-3 text-sm">
											<span>
												我接受 THE NORTH FACE 的
												<button
													type="button"
													className="underline"
													onClick={() => membersPopupRef.current.showPopup()}
												>
													會員權益聲明
												</button>
												、
												<button
													type="button"
													className="underline"
													onClick={() => privacyPopupRef.current.showPopup()}
												>
													隱私權及網站使用條款
												</button>
												。
											</span>
										</div>
									</Checkbox>
								</div>

								<div>
									<Button
										type="submit"
										text="確認送出"
										theme="default"
										color="black"
										disabled={
											!isFormValid ||
											redeemTotalPoint + liffState?.pointInfo?.availablePoint <
												0
										}
										showBtnLoader={isBtnLoading}
									></Button>
								</div>
							</div>
						</Form>
					</div>
					{/* 隱私權彈窗 */}
					<LiffAgreementPopup ref={membersPopupRef} title="會員權益聲明">
						<MembershipTerms></MembershipTerms>
					</LiffAgreementPopup>
					<LiffAgreementPopup
						ref={privacyPopupRef}
						title="隱私權及網站使用條款"
					>
						<PrivacyPolicy></PrivacyPolicy>
					</LiffAgreementPopup>
					{/* 確認送出兌換資料提醒彈窗 */}
					<Popup ref={submitPopup} onClose={handlePopupClose}>
						<div className="flex justify-center">
							<span className="text-center">
								提醒您：
								<br />
								兌換後無法更改收件資料，
								<br />
								亦無法退還點數。
							</span>
						</div>
					</Popup>
					<Popup ref={pointDeductionFailedPopup}>
						<div className="flex flex-col items-center">
							<span className="pb-6 text-xl font-medium">很抱歉</span>
							<span className="text-center">點數扣除失敗，請聯繫客服。</span>
						</div>
					</Popup>
				</>
			)}
		</>
	);
};

export default DeliveryForm;
