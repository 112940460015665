/* eslint-disable no-unused-vars */
import { memo, useEffect } from "react";
import { useFormContext } from "react-hook-form";

const Switch = ({ name, onChange }) => {
	const { register } = useFormContext();

	return (
		<>
			<input
				type="checkbox"
				id={name}
				className="h-6 w-11 shrink-0 cursor-pointer appearance-none rounded-full border-2 border-transparent
               bg-gray-200 ring-1 ring-transparent ring-offset-white transition-colors duration-200 ease-in-out 
               before:inline-block before:h-5 before:w-5 before:translate-x-0 before:rounded-full before:bg-white before:shadow before:ring-0 before:transition before:duration-200 before:ease-in-out
              checked:bg-blue-600 checked:bg-none checked:before:translate-x-full checked:before:bg-blue-200
              focus:border-blue-600 focus:outline-none focus:ring-blue-600"
				{...register(name, {
					onChange: onChange,
				})}
			/>
			<label htmlFor={name} className="ms-3 text-sm text-gray-500"></label>
		</>
	);
};

export default memo(Switch);
