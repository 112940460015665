import { useImperativeHandle, forwardRef, useState, useRef } from "react";
import { Button } from "@/atoms";
import { useOutsideClick } from "@/hooks";

/**
 * Popup
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode} props.btnContext
 * @param {string} [props.title=null]
 * @param {boolean} [props.hasBtn=true]
 * @param {React.Ref} ref
 * @param {function} [onClose]
 * @returns jsx
 */
const AdminPopup = forwardRef(
	({ children, title = null, onClose, onConfirm = () => {} }, ref) => {
		//  是否顯示彈窗
		const [isShowPopup, setIsShowPopup] = useState(false);
		// 是否點擊外部關閉彈窗
		const [activateClickOutside, setActivateClickOutside] = useState(false);

		const innerRef = useRef();

		// 開啟彈窗
		const onShowPopup = () => {
			setIsShowPopup(true);
			setTimeout(() => setActivateClickOutside(true), 0);
		};

		// 關閉彈窗
		const onClosePopup = () => {
			setIsShowPopup(false);
			setActivateClickOutside(false);

			if (onClose) {
				onClose();
			}
		};

		useOutsideClick(innerRef, onClosePopup, activateClickOutside);

		useImperativeHandle(ref, () => {
			return {
				showPopup: () => onShowPopup(), // 開啟彈窗
				closePopup: () => onClosePopup(), // 關閉彈窗
			};
		});

		return (
			isShowPopup && (
				<div className="fixed inset-0 z-100 h-screen w-full bg-[#00000080]">
					<div
						className="fixed left-1/2 top-1/2 z-200 w-[311px] -translate-x-1/2 -translate-y-1/2 rounded bg-white p-6"
						ref={innerRef}
					>
						{/* title */}
						{title && (
							<div className="pb-6 text-center text-xl font-medium">
								{title}
							</div>
						)}

						{/* content */}
						{children}

						{/* button */}
						<div className="mt-10 flex gap-2">
							<Button
								type="button"
								text="返回"
								theme="admin"
								color="black"
								borderOnly={true}
								onClick={() => {
									onClosePopup();
								}}
							></Button>

							<Button
								type="submit"
								onClick={() => {
									onConfirm();
								}}
								text="確定"
								theme="admin"
								color="black"
							></Button>
						</div>
					</div>
				</div>
			)
		);
	},
);

AdminPopup.displayName = "AdminPopup";

export default AdminPopup;
