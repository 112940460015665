// svg
import { ReactComponent as Redeem } from "@/assets/icon/liff/home/redeem.svg";
import { ReactComponent as Coupon } from "@/assets/icon/liff/home/coupon.svg";
import { ReactComponent as Mission } from "@/assets/icon/liff/home/mission.svg";
import { ReactComponent as Vip1 } from "@/assets/icon/liff/home/vip1.svg";
import { ReactComponent as Vip2 } from "@/assets/icon/liff/home/vip2.svg";
import { ReactComponent as PointList } from "@/assets/icon/liff/home/pointList.svg";
import { ReactComponent as Profile } from "@/assets/icon/liff/home/profile.svg";
import { ReactComponent as RedeemCheck } from "@/assets/icon/liff/redeem/check.svg";
import { ReactComponent as Gift } from "@/assets/icon/liff/mission/icon-gift.svg";
import { ReactComponent as GiftWhite } from "@/assets/icon/liff/mission/icon_gift_white.svg";
// mui
import LocalMallIcon from "@mui/icons-material/LocalMall";
import QrCodeIcon from "@mui/icons-material/QrCode";
import PersonIcon from "@mui/icons-material/Person";
import StarsIcon from "@mui/icons-material/Stars";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import HelpIcon from "@mui/icons-material/Help";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import EditIcon from "@mui/icons-material/Edit";

/**
 * IconType
 * @param {*} component Icon Component
 * @param {'svg' | 'mui'} type Type
 */
class IconType {
	constructor(component, type) {
		this.component = component;
		this.type = type;
	}
}

export const Icons = {
	// liff
	Redeem: new IconType(Redeem, "svg"), // 點數商城
	Mall: new IconType(LocalMallIcon, "mui"), // 線上商城
	Coupon: new IconType(Coupon, "svg"), // 優惠券
	QrCode: new IconType(QrCodeIcon, "mui"), // QrCode
	Person: new IconType(PersonIcon, "mui"), // 會員中心
	Mission: new IconType(Mission, "svg"), // 任務牆
	Vip1: new IconType(Vip1, "svg"), // 會員中心進度條icon
	Vip2: new IconType(Vip2, "svg"), // 會員中心進度條icon
	PointList: new IconType(PointList, "svg"), // 點數歷程
	Profile: new IconType(Profile, "svg"), // 基本資料
	Star: new IconType(StarsIcon, "mui"), //會員權益
	ArrowRight: new IconType(KeyboardArrowRightIcon, "mui"), //右箭頭
	Store: new IconType(StorefrontRoundedIcon, "mui"), // 門市資訊
	About: new IconType(HelpIcon, "mui"), // 關於我們
	ArrowBack: new IconType(ArrowBackIosIcon, "mui"), // 返回
	ArrowDropDown: new IconType(ArrowDropDownIcon, "mui"), // select箭頭
	Check: new IconType(CheckRoundedIcon, "mui"), // 勾勾
	Close: new IconType(CloseIcon, "mui"), // X
	Refresh: new IconType(RefreshIcon, "mui"), // 重新整理
	UnfoldMore: new IconType(UnfoldMoreIcon, "mui"), // 排序
	Info: new IconType(InfoOutlinedIcon, "mui"), // 資訊
	Remove: new IconType(RemoveRoundedIcon, "mui"), // -
	Add: new IconType(AddRoundedIcon, "mui"), // +
	Help: new IconType(HelpOutlineOutlinedIcon, "mui"), // ?
	RedeemCheck: new IconType(RedeemCheck, "svg"), // 兌換成功
	Gift: new IconType(Gift, "svg"), // 禮物
	GiftWhite: new IconType(GiftWhite, "svg"), // 禮物(白)

	// admin
	Logout: new IconType(LogoutIcon, "mui"), // 登出
	Menu: new IconType(MenuIcon, "mui"), // 選單
	ArrowForward: new IconType(ArrowForwardIosIcon, "mui"), // 向右箭頭
	KeyboardArrowDown: new IconType(KeyboardArrowDownIcon, "mui"), // 向下箭頭
	SwapVert: new IconType(SwapVertIcon, "mui"), // 排序
	Edit: new IconType(EditIcon, "mui"), // 編輯
};

const Icon = ({ iconName, fontSize = 24, className = "w-6 h-6", onClick }) => {
	const icon = Icons[iconName];
	const IconComponent = icon.component;
	if (icon.type === "mui") {
		return (
			<IconComponent
				sx={{ fontSize: fontSize }}
				className={className}
				onClick={onClick}
			/>
		);
	}
	return <IconComponent className={className} />;
};

export default Icon;
