import classNames from "classnames";

/**
 * Skeleton
 * @param {circular | rectangular |rounded } type circular：圆形 rectangular：矩形 rounded：圆角矩形
 * @param {string} widthClass
 * @param {string} heightClass
 * @param {string} className 自定義class
 * @returns jsx
 */
const Skeleton = ({ type, widthClass, heightClass, className }) => {
	return (
		<div
			className={classNames(
				"bg-gray-100",
				widthClass,
				heightClass,
				className,
				{
					"rounded-full": type === "circular",
				},
				{
					"rounded-lg": type === "rounded",
				},
			)}
		></div>
	);
};

export default Skeleton;
