import { useContext } from "react";
import { LiffContext } from "@/utils";
import { TierStyle } from "@/utils/tierStyles";

const ProfileCardMask = ({ currentTierCode, batchTierStyle }) => {
	const { liffState } = useContext(LiffContext);
	const batchTierSeq = batchTierStyle.tierSeq;
	const batchTierCode = batchTierStyle.tierCode;

	const getMaskMessage = () => {
		const tierStyle = new TierStyle();
		const batchTierStyle = tierStyle.getTierStyleByCode(currentTierCode);

		if (batchTierStyle.tierSeq < batchTierSeq) {
			return "尚未解鎖此階級";
		}
		if (batchTierStyle.tierSeq > batchTierSeq) {
			return `已升級成 ${batchTierStyle.tierName}`;
		}

		return null;
	};

	const message = getMaskMessage();

	return message !== null ? (
		<div className="profile-card-mask">
			<label>{message}</label>
			<button onClick={() => liffState.memberBenefitPopup.open(batchTierCode)}>
				<span>查看會員權益</span>
				<div className="triangle-right black" />
			</button>
		</div>
	) : null;
};

export { ProfileCardMask };
