import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import {
	Form,
	Input,
	Radio,
	PhoneNumberField,
	Textarea,
	Button,
} from "@/atoms";
import { LiffContext, api } from "@/utils";
import { useFormatAsLocalTime } from "@/hooks";
import { AdminPopup } from "@/components";

const shippingStatusOptions = [
	{ key: "SHIPPING", value: "已出貨" },
	{ key: "WAITING", value: "待出貨" },
];

const ExchangeListDetailPage = () => {
	// 單筆兌換紀錄
	const [exchangeData, setExchangeData] = useState();

	const { liffActions, liffState } = useContext(LiffContext);
	const { formatAsLocalTime } = useFormatAsLocalTime();
	const { refId } = useParams();
	const navigate = useNavigate();
	const editForm = useRef(null);
	const confirmPopup = useRef(null);

	// 獲取單筆兌換紀錄
	const getUserExchangeData = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/admin/get_user_exchange_by_id", {
				params: {
					ref_id: refId,
				},
			});

			setExchangeData(res.data);
		} catch (error) {
			console.error(error);
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 更新兌換紀錄
	const updateUserExchangeData = async (updateData) => {
		try {
			const res = await api().post(
				"/admin/update_user_exchange_by_id",
				updateData,
			);

			if (res.data === "success") {
				confirmPopup.current.closePopup();
				toast.success("更新成功");
				navigate("/admin/exchangeList");
			}
		} catch (error) {
			console.error("Update user exchange data failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	const onSubmit = () => {
		confirmPopup.current.showPopup();
	};

	useEffect(() => {
		getUserExchangeData();
	}, []);

	return (
		<>
			{!liffState.showFullPageLoader && (
				<>
					<div className="space-y-9">
						{/* 單號 */}
						<span className="block border-b border-gray-200 pb-6 text-xl font-medium">
							{`兌換單號 # ${exchangeData?.ref_id}`}
						</span>
						<div className="flex flex-col space-y-4 border-b border-gray-200 pb-6">
							{/* 會員資訊 */}
							<div className="flex flex-col gap-3 sm:flex-row">
								<span className="whitespace-nowrap font-medium text-blackT50">
									TNF 會員編號
								</span>
								<span>{exchangeData?.tnf_id}</span>
							</div>
							{/* 兌換日期 */}
							<div className="flex flex-col gap-3 sm:flex-row">
								<span className="font-medium text-blackT50">兌換日期 </span>
								<span>
									{formatAsLocalTime(exchangeData?.exchange_at, "YYYY/MM/DD")}
								</span>
							</div>
						</div>

						{/* 商品資訊 */}
						<div className="inline-block w-full align-middle ">
							<div className="overflow-x-auto rounded-lg border shadow">
								<table className="min-h-[150px] min-w-full divide-y divide-gray-100">
									<thead className="bg-gray-100">
										<tr>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
											>
												SKU
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
											>
												名稱
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
											>
												點數
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
											>
												數量
											</th>
											<th
												scope="col"
												className="whitespace-nowrap px-6 py-3 text-start font-medium uppercase text-blackT80"
											>
												總計
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-100 bg-white">
										{exchangeData?.exchange_products?.length > 0 ? (
											exchangeData.exchange_products.map((product, index) => {
												return (
													<Fragment key={index}>
														<tr>
															{/* SKU */}
															<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																{`SKU${product.sku_id || ""}`}
															</td>
															{/* 名稱 */}
															<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																{product.display_name}
															</td>
															{/* 點數 */}
															<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																{product.point.toLocaleString()}
															</td>
															{/* 數量 */}
															<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																{product.quantity}
															</td>
															{/* 總計 */}
															<td className="whitespace-nowrap px-6 py-4 text-start text-blackT80">
																{(
																	product.point * product.quantity
																).toLocaleString()}
															</td>
														</tr>
													</Fragment>
												);
											})
										) : (
											<tr>
												<td
													colSpan="100%"
													className="py-4 text-center font-medium"
												>
													查無資料
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>

						<Form
							onSubmit={onSubmit}
							ref={editForm}
							defaultValues={exchangeData}
						>
							<div className="space-y-6 sm:flex sm:flex-col sm:items-center">
								<div className="space-y-6 sm:flex sm:w-2/3 sm:space-y-0">
									{/* 出貨狀態 */}
									<div className="sm:flex-1">
										<span className="mb-2.5 block text-sm font-medium">
											出貨狀態
										</span>
										<Radio
											name="shipping_status"
											options={shippingStatusOptions}
											requiredMark={false}
										></Radio>
									</div>

									{/* 貨運單號 */}
									<div className="sm:flex-1">
										<Input
											theme="admin"
											name="delivery_id"
											required={false}
											type="text"
											requiredMark={false}
											placeholder="請輸入貨運單號"
											label="貨運單號"
										></Input>
									</div>
								</div>

								{/* 收件人資料 */}
								<div className="space-y-6 sm:flex sm:w-2/3 sm:flex-col">
									<div className="space-y-6 sm:flex sm:gap-2 sm:space-y-0">
										{/* 姓名 */}
										<div className="sm:flex-1">
											<Input
												theme="admin"
												name="receive_name"
												required={false}
												type="text"
												label="收件人姓名"
												requiredMark={false}
												placeholder="請輸入收件人姓名"
											></Input>
										</div>

										{/* 手機號碼 */}
										<div className="sm:flex-1">
											<PhoneNumberField
												theme="admin"
												name="receive_mobile"
												required={false}
												type="text"
												label="手機號碼"
												requiredMark={false}
												placeholder="請輸入手機號碼"
											></PhoneNumberField>
										</div>
									</div>

									{/* 地址 */}
									<div>
										<Input
											theme="admin"
											name="receive_address"
											required={false}
											type="text"
											label="收件人地址"
											requiredMark={false}
											placeholder="請輸入地址"
											maxlength={200}
										></Input>
									</div>
								</div>

								{/* 備註 */}
								<div className="sm:flex sm:w-2/3 sm:flex-col">
									<Textarea
										theme="admin"
										name="remark"
										required={false}
										requiredMark={false}
										label="備註"
										maxlength={300}
									></Textarea>
								</div>

								{/* Button */}
								<div className="flex justify-end gap-5 sm:w-2/3">
									<Button
										type="button"
										theme="admin"
										text="返回"
										borderOnly={true}
										widthFull={false}
										onClick={() => navigate("/admin/exchangeList")}
									></Button>
									<Button
										type="submit"
										theme="admin"
										color="black"
										text="儲存"
										widthFull={false}
									></Button>
								</div>
							</div>
						</Form>
					</div>
				</>
			)}
			<AdminPopup
				ref={confirmPopup}
				onConfirm={async () => {
					const data = editForm.current.getValues();

					const {
						shipping_status,
						delivery_id,
						receive_name,
						receive_mobile,
						receive_address,
						remark,
						ref_id,
					} = data;

					const updateData = {
						ref_id,
						shipping_status,
						delivery_id,
						receive_name,
						receive_mobile,
						receive_address,
						remark,
					};

					await updateUserExchangeData(updateData);
				}}
			>
				{
					<span className="my-8 block whitespace-pre-wrap text-center">
						{`是否確定要儲存\n此次的更改？`}
					</span>
				}
			</AdminPopup>
		</>
	);
};

export default ExchangeListDetailPage;
