import { memo } from "react";
import { useFormContext } from "react-hook-form";

/**
 * Input
 * @param {string} name
 * @param {string} label 顯示名稱
 * @param {boolean} required 是否必填
 * @param {boolean} isDisabled 禁用
 * @param {boolean} isReadOnly
 * @param {boolean} requiredMark 是否顯示必填＊
 * @param {string} placeholder
 * @param {React.ReactNode} children
 * @param {string} errMsg 沒有label時的錯誤訊息
 * @param {'default' | 'admin'} theme 主題：前台 | 後台
 * @param {boolean} isMultiple 是否多選
 * @returns jsx
 */
const InputFile = ({
	name,
	label,
	required,
	isDisabled,
	isReadOnly,
	errMsg,
	onChange,
	isMultiple,
}) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	return (
		<>
			<label
				htmlFor={name}
				className="rounded-lg border border-black bg-white px-3 py-1.5 text-sm font-medium text-black"
			>
				<input
					type="file"
					multiple={isMultiple}
					id={name}
					name={name}
					className="hidden"
					readOnly={isReadOnly}
					{...register(name, {
						disabled: isDisabled,
						onChange: onChange,
						required: {
							value: required,
							message: label ? `${label}為必填` : errMsg,
						},
					})}
				/>

				<span>上傳圖片</span>
			</label>

			<span className="block text-xs text-red-600">
				{errors[name]?.message}
			</span>
		</>
	);
};

export default memo(InputFile);
