import React from "react";
import Store from "@/assets/img/liff/home/store.png";
import Consultant from "@/assets/img/liff/home/consultant.png";

const StoreConsultant = ({ memberConsultant }) => {
	const bindingUrl =
		"https://linewith.me/liff/cl93l9njp0567o8lnpeqmyohb/tnf/store-picker";

	const storeName = memberConsultant?.storeName || "未綁定";
	const staffDisplayName = memberConsultant?.staffDisplayName || "未綁定";

	const getTitleStyle = (name) => {
		if (name.length > 8) {
			return {
				marginBottom: "0.5em",
			};
		}
	};

	const getTextStyle = (name) => {
		if (name.length > 6) {
			return {
				fontSize: "3em",
				lineHeight: "1.3",
			};
		}
	};

	return (
		<div className="store-consultant">
			<div className="top-bar">
				<label>專屬門市顧問</label>
				<button onClick={() => (window.location.href = bindingUrl)}>
					<span className="border-bottom">更換綁定門市</span>
					<span>{" >"}</span>
				</button>
			</div>
			<div className="consultant-list">
				{/* 綁定門市 */}
				<div className="consultant">
					<img
						className="consultant-img"
						src={memberConsultant?.storeLogo || Store}
						alt=""
					/>
					<div className="consultant-info">
						<label className="title" style={getTitleStyle(storeName)}>
							綁定門市：
						</label>
						<label className="name" style={getTextStyle(storeName)}>
							{storeName}
						</label>
					</div>
				</div>
				{/* 門市顧問 */}
				<div className="consultant">
					<img
						className="consultant-img"
						src={memberConsultant?.staffAvatar || Consultant}
						alt=""
					/>
					<div className="consultant-info">
						<label className="title" style={getTitleStyle(staffDisplayName)}>
							門市顧問：
						</label>
						<label className="name" style={getTextStyle(staffDisplayName)}>
							{staffDisplayName}
						</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export { StoreConsultant };
