import dayjs from "dayjs";

// 生成年
export const generateYears = function (startYear) {
	if (startYear === undefined) {
		startYear = 1900;
	}

	var endYear = new Date().getFullYear() - 1;
	var years = [];

	for (var i = startYear; i <= endYear; i++) {
		years.unshift({ key: i, value: i });
	}

	return years;
};

// 生成月
export const generateMonths = () => {
	return Array.from({ length: 12 }, (_, i) => ({
		key: i + 1,
		value: i + 1,
	}));
};

// 生成日
export const generateDays = (year, month) => {
	if (!year || !month || isNaN(year) || isNaN(month)) {
		return [];
	}
	const daysInMonth = dayjs(`${year}-${month}`).daysInMonth();
	return Array.from({ length: daysInMonth }, (_, i) => ({
		key: i + 1,
		value: i + 1,
	}));
};
