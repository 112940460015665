import { useEffect, useRef, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import classNames from "classnames";
import toast from "react-hot-toast";

import {
	Icon,
	Form,
	Input,
	Radio,
	Select,
	Button,
	PhoneNumberField,
} from "@/atoms";
import { api, LiffContext, AppError } from "@/utils";
import { LiffAvatar } from "@/components";

import {
	generateYears,
	generateMonths,
	generateDays,
	interestOptions,
} from "./data/index";

import PassLogo from "@/assets/img/liff/profile/TNFPass_Logo.png";

const radioOptions = [
	{
		key: "MALE",
		value: "男",
	},
	{
		key: "FEMALE",
		value: "女",
	},
];

const ProfilePage = () => {
	// 儲存後端回傳的會員基本資料
	const [profileData, setProfileData] = useState({
		phone: null,
		name: null,
		email: null,
		gender: "MALE",
		birthdayYear: null,
		birthdayMonth: null,
		birthdayDay: null,
		interests: [],
	});
	const [years] = useState(generateYears());
	const [months] = useState(generateMonths());
	const [days, setDays] = useState([]);

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	// 會員註冊表單ref
	const profileForm = useRef(null);
	const navigate = useNavigate();

	// 格式化月日
	const padToTwoDigits = (num) => num.toString().padStart(2, "0");

	// 初始化取得會員資料
	const getCrmMember = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api(10).get("/get_crm_member");
			const memberData = res.data;

			let interestsKeys = [];
			// 解析 JSON 字串獲得興趣愛好
			if (memberData.interests && typeof memberData.interests === "string") {
				const interestsObject = JSON.parse(memberData.interests);
				interestsKeys = Object.keys(interestsObject).filter(
					(key) => interestsObject[key] === 1,
				);
			}

			let year, month, day;
			// 格式化出生年月日
			if (memberData.birthDate) {
				const dateParts = memberData.birthDate.split("/");
				if (dateParts.length === 3) {
					[year, month, day] = dateParts.map((part) => parseInt(part, 10));
				}
			}

			setProfileData((prevData) => ({
				phone: `+${memberData.mobilePhone}`,
				name: memberData.fullName,
				gender: memberData.gender || prevData.gender,
				birthdayYear: year,
				birthdayMonth: month,
				birthdayDay: day,
				email: memberData.email,
				interests: interestsKeys,
			}));
			setDays(generateDays(year, month));
			liffActions.setMemberData(memberData);
		} catch (error) {
			console.error("Get Crm Member Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.CRM_FAILED:
						toast.error("發生錯誤，請再試一次。");
						break;

					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 儲存選擇的興趣愛好
	const onInterestSelect = (interest) => {
		let newInterests;
		if (profileData.interests.includes(interest)) {
			// 已被選取，移除
			newInterests = profileData.interests.filter((i) => i !== interest);
		} else if (profileData.interests.length < 4) {
			// 新增興趣，並確保不超過四個
			newInterests = [...profileData.interests, interest];
		} else if (profileData.interests.length >= 4) {
			toast.error("最多選擇 4 個，可移除後再新增。");
			return;
		}
		setProfileData({ ...profileData, interests: newInterests });
	};

	const onBirthdayChange = () => {
		const year = profileForm.current.getValues("birthdayYear");
		const month = profileForm.current.getValues("birthdayMonth");
		if (year && month) {
			setDays(generateDays(year, month));
			profileForm.current.setValue("birthdayDay");
		}
	};

	const onSubmit = async (data) => {
		const formattedYear = data.birthdayYear;
		const formattedMonth = padToTwoDigits(data.birthdayMonth);
		const formattedDay = padToTwoDigits(data.birthdayDay);
		if (profileData.interests.length === 0) {
			toast.error("請選擇興趣愛好。");
			return;
		} else if (profileData.interests.length < 4) {
			toast.error("請選擇四個興趣愛好。");
			return;
		}
		const interestsForBackend = profileData.interests.map((interest) => ({
			name: interest,
			selected: true,
		}));

		const formValues = {
			birthday: `${formattedYear}/${formattedMonth}/${formattedDay}`,
			gender: data.gender,
			email: data.email,
			interests: interestsForBackend,
			name: data.name,
		};
		await UpdateCrmMember(formValues);
	};

	// 更新表單資料
	const UpdateCrmMember = async (formValues) => {
		try {
			await api().post("/update_crm_member", formValues);
			toast.success("更新成功！");
			setTimeout(() => {
				navigate("/");
			}, 2000);
		} catch (error) {
			console.error("Updata Crm Member Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_FORMAT:
						toast.error("未填寫完整。");
						break;
					case AppError.CRM_FAILED:
						toast.error("發生錯誤，請再試一次。");
						break;

					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	useEffect(() => {
		getCrmMember();
	}, []);

	return (
		<>
			{!liffState.showFullPageLoader && (
				<div className="flex w-full flex-col space-y-4 p-4">
					{/* 返回按鈕 */}
					<div className="flex">
						<Link to="/" className="flex items-center px-2 py-1.5">
							<Icon iconName={"ArrowBack"} fontSize={20}></Icon>
							<span className="ml-2 text-sm font-medium text-black">返回</span>
						</Link>
					</div>
					<div className="grid grid-cols-3">
						{/* avatar */}
						<div className="col-start-2">
							<LiffAvatar size="lg"></LiffAvatar>
						</div>
						{/* logo */}
						<div className="col-start-3 w-24">
							<img
								src={PassLogo}
								alt=""
								className="size-full object-contain text-center"
							/>
						</div>
					</div>
					<div className="p-2 text-center text-blackT80">
						<span className="block text-xl font-medium">
							完成會員資料，獲得 1,000 會員點數
						</span>
						<span>填寫完整資料，會員點數即領即用！</span>
					</div>
					<Form
						ref={profileForm}
						onSubmit={onSubmit}
						defaultValues={profileData}
					>
						<div className="space-y-6 px-2">
							{/* 手機號碼 */}
							<div>
								<PhoneNumberField
									name="phone"
									label="手機號碼"
									type="tel"
									required={false}
									isReadOnly={true}
								>
									<div className="absolute right-0 top-1/2 -translate-y-1/2 pr-3.5">
										<div className="flex h-8 min-w-[115px] items-center justify-center bg-[#979797] px-1.5 py-1 text-xs font-medium text-white">
											已驗證
										</div>
									</div>
								</PhoneNumberField>
							</div>

							{/* 姓名 */}
							<div>
								<Input
									name="name"
									label="姓名"
									type="text"
									required={true}
								></Input>
							</div>

							{/* 性別 */}
							<div>
								<Radio
									label="性別"
									name="gender"
									options={radioOptions}
								></Radio>
							</div>

							{/* 出生日期 */}
							<div>
								<div className="mb-2.5 block text-sm font-medium text-[#333333]">
									出生日期
									<span className="text-red-600">*</span>
								</div>
								<div className="grid grid-cols-3 gap-x-2">
									<div>
										<Select
											id="birth"
											name="birthdayYear"
											required={true}
											icon="ArrowDropDown"
											placeholder="年"
											selectOptions={years}
											onChange={onBirthdayChange}
											disabled={!!profileData.birthdayYear}
										></Select>
									</div>
									<div>
										<Select
											id="birth"
											name="birthdayMonth"
											required={true}
											icon="ArrowDropDown"
											placeholder="月"
											selectOptions={months}
											onChange={onBirthdayChange}
											disabled={!!profileData.birthdayMonth}
										></Select>
									</div>
									<div>
										<Select
											id="birth"
											name="birthdayDay"
											required={true}
											icon="ArrowDropDown"
											placeholder="日"
											selectOptions={days}
											disabled={!!profileData.birthdayDay}
										></Select>
									</div>
								</div>
								<span className="mt-2 block text-sm font-medium text-red-600">
									*「生日禮」現生日當月領取，一個帳號限領一次。資料一但送出，將無法更改。
								</span>
							</div>

							{/* Email */}
							<div>
								<Input
									name="email"
									label="Email 地址"
									type="text"
									required={true}
									pattern={/^\S+@\S+$/}
								></Input>
							</div>

							{/* 興趣愛好 */}
							<div>
								<div className="mb-2.5 block text-sm font-medium text-[#333333]">
									興趣愛好（應選4個）
									<span className="text-red-600">*</span>
								</div>
								<div className="flex flex-wrap justify-start gap-1.5">
									{interestOptions.map((item) => {
										const isSelected = profileData.interests.includes(item.key);
										return (
											<button
												key={item.key}
												type="button"
												className={classNames(
													"h-8 whitespace-nowrap px-3 text-sm",
													{
														"bg-black text-white": isSelected,
														"bg-[#00000014] text-blackT80": !isSelected,
													},
												)}
												onClick={() => onInterestSelect(item.key)}
											>
												{item.value}
											</button>
										);
									})}
								</div>
							</div>

							<Button
								type="submit"
								theme="default"
								color="black"
								text="儲存"
							></Button>
						</div>
					</Form>
				</div>
			)}
		</>
	);
};

export default ProfilePage;
