import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import toast from "react-hot-toast";

import { Button, Form, Input, InputDate, Switch } from "@/atoms";
import { AppError, LiffContext, api } from "@/utils";
import { AdminPopup } from "@/components";
import { useFormatAsLocalTime } from "@/hooks";

dayjs.extend(utc);

const TextCarouselListDetailPage = () => {
	// switch開關
	const [switchState, setSwitchState] = useState({
		is_launch: false,
	});
	const [startDate, setStartDate] = useState(null);
	// 根據路徑判斷是否為编辑頁
	const [isEditPage, setIsEditPage] = useState(false);
	// id不存在
	const [isError, setIsError] = useState(false);
	const [textCarouselData, setTextCarouselData] = useState({
		text: null,
		start_date: null,
		end_date: null,
		is_launch: false,
	});

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	const { formatAsLocalTime } = useFormatAsLocalTime();
	const { bannerId } = useParams();
	const editCreateForm = useRef(null);
	const confirmPopup = useRef(null);
	const navigate = useNavigate();

	// 新增
	const addNewMarquee = async (data) => {
		try {
			const res = await api().post("/admin/add_new_marquee", {
				...data,
				show_type: "marquee",
			});

			if (res.data === "success") {
				toast.success("新增成功");
				navigate("/admin/displayManagement/textCarouselList");
			}
		} catch (error) {
			console.error("Add New Banner Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	// 進入編輯頁面，獲取單筆資料
	const getBannerData = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/admin/get_banner_by_id", {
				params: {
					id: Number(bannerId),
					show_type: "marquee",
				},
			});
			const editTextCarousel = res.data;

			setTextCarouselData({
				...editTextCarousel,
				start_date: formatAsLocalTime(
					editTextCarousel?.start_date,
					"YYYY-MM-DDTHH:mm",
				),
				end_date: formatAsLocalTime(
					editTextCarousel?.end_date,
					"YYYY-MM-DDTHH:mm",
				),
			});

			// 變更switch狀態
			setSwitchState({
				is_launch: !!editTextCarousel?.is_launch,
			});
		} catch (error) {
			console.error("Get Banner Data Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.NO_FOUND:
					case AppError.INVALID_FORMAT:
						break;

					default:
						toast.error("發生錯誤，請再試一次。");
				}

				setIsError(true);
			}
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 編輯文字輪播
	const updateMarquee = async (data) => {
		console.log(data);
		try {
			const res = await api().post("/admin/update_banner_by_id", {
				...data,
				id: Number(bannerId),
				show_type: "marquee",
			});

			if (res.data === "success") {
				toast.success("新增成功");
				navigate("/admin/displayManagement/textCarouselList/");
			}
		} catch (error) {
			console.error("Update Banner Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	// 提交編輯 / 新增
	const submitBannerData = async (data) => {
		const { is_launch, start_date, end_date, text } = data;
		// 轉換UTC時間
		const formattedStartDate = start_date
			? dayjs(start_date).utc().format()
			: null;
		const formattedEndDate = end_date ? dayjs(end_date).utc().format() : null;

		const formValues = {
			is_launch: !!is_launch,
			start_date: formattedStartDate,
			end_date: formattedEndDate,
			text: text,
		};

		// 編輯頁面
		if (isEditPage) {
			await updateMarquee(formValues);
		} else {
			// 新增頁面
			await addNewMarquee(formValues);
		}
	};

	const onSubmit = () => {
		confirmPopup.current.showPopup();
	};

	useEffect(() => {
		const editPage = location.pathname.includes("/edit");
		setIsEditPage(editPage);
	}, [location]);

	useEffect(() => {
		if (isEditPage) {
			getBannerData();
		} else {
			liffActions.setShowFullPageLoader(false);
		}
	}, [isEditPage]);

	useEffect(() => {
		// 如果id不存在，則返回列表頁
		if (isError) {
			navigate("/admin/displayManagement/textCarouselList");
		}
	}, [isError]);

	return (
		<>
			{!liffState.showFullPageLoader && (
				<>
					<Form
						onSubmit={onSubmit}
						defaultValues={textCarouselData}
						ref={editCreateForm}
					>
						<div className="space-y-6 sm:flex sm:flex-col sm:items-center">
							{/* 輪播文案 */}
							<div className="sm:w-2/3">
								<Input
									name="text"
									type="text"
									label="輪播文案"
									theme="admin"
									required={true}
								></Input>
							</div>

							{/* 上線日期 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<span className="mb-2.5 block text-sm font-medium text-[#333333]">
									上線日期
								</span>

								<div className="space-y-3 sm:flex sm:gap-4 sm:space-y-0">
									<Switch
										name="is_launch"
										onChange={(e) =>
											setSwitchState((prevState) => ({
												...prevState,
												is_launch: e.target.checked,
											}))
										}
									></Switch>
									{switchState.is_launch && (
										<>
											{/* 開始日期 */}
											<InputDate
												theme="admin"
												name="start_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												minDate={dayjs().format("YYYY-MM-DDTHH:mm")}
												onChange={(e) => setStartDate(e.target.value)}
											></InputDate>

											{/* 結束日期 */}
											<InputDate
												theme="admin"
												name="end_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												minDate={
													startDate || dayjs().format("YYYY-MM-DDTHH:mm")
												}
											></InputDate>
										</>
									)}
								</div>
							</div>

							{/* Button */}
							<div className="flex justify-end gap-5 sm:w-2/3">
								<Button
									type="button"
									theme="admin"
									borderOnly={true}
									widthFull={false}
									text="返回"
									onClick={() =>
										navigate("/admin/displayManagement/textCarouselList")
									}
								></Button>
								<Button
									type="submit"
									theme="admin"
									widthFull={false}
									text="儲存"
								></Button>
							</div>
						</div>
					</Form>

					<AdminPopup
						ref={confirmPopup}
						onConfirm={async () => {
							const data = editCreateForm.current.getValues();

							await submitBannerData(data);
						}}
					>
						{isEditPage ? (
							<span className="my-8 block whitespace-pre-wrap text-center">
								{`是否確定要儲存\n此次的更改？`}
							</span>
						) : (
							<span className="my-8 block whitespace-pre-wrap text-center">{`是否確定新增？`}</span>
						)}
					</AdminPopup>
				</>
			)}
		</>
	);
};

export default TextCarouselListDetailPage;
