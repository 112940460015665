const interestOptions = [
	{
		key: "mountaineering",
		value: "登山",
	},
	{
		key: "trekking",
		value: "徒步",
	},
	{
		key: "orienteering",
		value: "定向越野",
	},
	{
		key: "climbing",
		value: "攀岩",
	},
	{
		key: "skiing",
		value: "滑雪",
	},
	{
		key: "downhillskiing",
		value: "高山滑雪",
	},
	{
		key: "caving",
		value: "探洞",
	},
	{
		key: "surfing",
		value: "衝浪",
	},
	{
		key: "mountainBiking",
		value: "登山車",
	},
	{
		key: "diving",
		value: "潛水",
	},
	{
		key: "fishing",
		value: "釣魚",
	},
	{
		key: "swimming",
		value: "游泳",
	},
	{
		key: "camping",
		value: "露營",
	},
];

export default interestOptions;
