const Triangle = (props) => {
	const { color, className } = props;

	const borderColor = color === 'black' ? 'border-l-black' : 'border-l-white';

	return (
		<div className={`w-0 h-0 ${className} -mb-0.5
			border-t-[6px] border-t-transparent
			border-l-[8px] ${borderColor}
			border-b-[6px] border-b-transparent inline-block`} />
	);
};

export default Triangle;
