/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import { Button, Icon } from "@/atoms";
import { useOutsideClick } from "@/hooks";

/**
 * Dropdown
 * @param {ReactNode} children
 * @param {string} text
 * @param {boolean} isDisabled
 * @returns jsx
 */
const Dropdown = ({ children, text, isDisabled }) => {
	// 是否顯示Dropdown
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	// 是否點擊外部關閉
	const [activateClickOutside, setActivateClickOutside] = useState(false);
	const innerRef = useRef();

	// 開啟Dropdown
	const toggleDropDown = () => {
		setIsDropdownOpen(!isDropdownOpen);
		setActivateClickOutside(true);
	};

	// 關閉Dropdown
	const onCloseDropdown = () => {
		setIsDropdownOpen(false);
		setActivateClickOutside(false);
	};

	useOutsideClick(innerRef, onCloseDropdown, activateClickOutside);

	return (
		<div className="z-10 flex" ref={innerRef}>
			<Button
				type="button"
				theme="admin"
				widthFull={false}
				size="sm"
				text={text}
				disabled={isDisabled}
				onClick={toggleDropDown}
			>
				<Icon iconName={"ArrowDropDown"} fontSize={20}></Icon>
			</Button>

			{/* 下拉選單 */}
			{isDropdownOpen && (
				<ul className="text-nowrap absolute top-full mt-2 rounded-lg border border-gray-200 bg-white p-2 shadow-md">
					{children}
				</ul>
			)}
		</div>
	);
};

export default Dropdown;
