import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { LiffContext, api, AppError } from "@/utils";
import { LiffTab } from "@/components";
import { EXCHANGE_STATUS_CODES } from "@/utils/interface/exchange";
import { PointsOverview } from "./PointsOverview";
import { TierStyle } from "@/utils/tierStyles";
import classNames from "classnames";
import toast from "react-hot-toast";
import ReactGA from "react-ga4";
import style from "./redeemPage.module.scss";
import "./index.sass";
import "swiper/css";
import "swiper/css/pagination";

const sortItems = [
	{ key: "point", value: "點數" },
	{ key: "vip_code", value: "等級查詢" },
];

const RedeemPage = () => {
	const navigate = useNavigate();
	const [productList, setProductList] = useState([]);
	const [activeTab, setActiveTab] = useState(sortItems[0].key);
	const [textBannerList, setTextBannerList] = useState([]);
	const [sortOrder, setSortOrder] = useState({
		key: sortItems[0].key,
		sort: "asc",
		value: null,
	});
	const { liffActions, liffState } = useContext(LiffContext);

	const formatNumberWithCommas = (number) => {
		return new Intl.NumberFormat().format(Math.floor(number));
	};

	const getProductList = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/get_product_list", {
				params: {
					page: 1,
					pageSize: 50,
					orderBy: sortOrder.key,
					sortOrder: sortOrder.sort,
					filterValue: sortOrder.value,
				},
			});
			setProductList(res.data.products);
		} catch (error) {
			console.error("Get Product List Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.INVALID_FORMAT:
						toast.error("發生錯誤，請再試一次。");
						break;
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 點擊Tab切換排序
	const onTabClickSort = (key, value = null) => {
		setActiveTab(key);

		if (key === "vip_code") {
			if (value == null) {
				return;
			}

			if (sortOrder.key === key && sortOrder.value === value) {
				setSortOrder((pre) => {
					return {
						...pre,
						value: null,
					};
				});
				return;
			}

			setSortOrder((pre) => {
				return {
					...pre,
					key,
					value,
				};
			});

			return;
		}

		setSortOrder((pre) => {
			if (key === pre.key) {
				// 如果點擊的是同一個Tab，則切換排序
				return {
					...pre,
					sort: pre.sort === "asc" ? "desc" : "asc",
					value: null,
				};
			} else {
				// 如果點擊的是不同的Tab，則預設為asc
				return {
					key,
					sort: "asc",
					value: null,
				};
			}
		});
	};

	// 取得兌換狀態文字
	const getStatusText = (status) => {
		switch (status) {
			case EXCHANGE_STATUS_CODES.OFF_SALE:
				return "草稿";
			case EXCHANGE_STATUS_CODES.BEFORE_SALE:
				return "未上線";
			case EXCHANGE_STATUS_CODES.AFTER_SALE:
				return "已結束";
			case EXCHANGE_STATUS_CODES.ON_SALE:
				return "可兌換";
			case EXCHANGE_STATUS_CODES.NO_STOCK:
				return "敬請期待";
			case EXCHANGE_STATUS_CODES.FINISHED:
				return "兌換完畢";
			case EXCHANGE_STATUS_CODES.BEFORE_START:
				return "即將開始";
			case EXCHANGE_STATUS_CODES.AFTER_END:
				return "已結束";
			default:
				return "";
		}
	};

	// 點擊商品導向商品介紹頁
	const onNavigateToIntro = (status, id) => {
		navigate(`/redeem/${id}`);
	};

	const getTextBanner = async () => {
		const res = await api().get("/get_ad_text_banner");
		if (res.data) {
			setTextBannerList(res.data);
		}
	};

	useEffect(() => {
		getProductList();
	}, [sortOrder]);

	useEffect(() => {
		getTextBanner();
		ReactGA.event("page_redeem");
	}, []);

	return (
		<>
			<div className="cRedeemPage h-screen w-full overflow-x-hidden overflow-y-scroll">
				{/* Top */}
				<PointsOverview
					pointInfo={liffState.pointInfo}
					tierCode={liffState.memberData?.tierCode}
				/>

				{/* Tabs */}
				<LiffTab
					options={sortItems}
					isSticky={true}
					activeTab={activeTab}
					setActiveTab={onTabClickSort}
					withBorder={true}
					sortOrder={sortOrder}
					withIcon={true}
				></LiffTab>

				{/*Text Banner*/}
				<div style={{ display: textBannerList.length > 0 ? "block" : "none" }}>
					<Swiper
						pagination={false}
						modules={[Autoplay, Pagination]}
						loop={true}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						className="cSwiper"
					>
						{textBannerList.map((row) => (
							<SwiperSlide key={row.id}>{row.text_value}</SwiperSlide>
						))}
					</Swiper>
				</div>

				{/* Product list */}
				<div className="mb-[75px] flex flex-wrap">
					{productList.map((item) => {
						const statusText = getStatusText(item.exchange_status);
						const isNotRedeemable =
							item.exchange_status !== EXCHANGE_STATUS_CODES.ON_SALE;
						const tierRedeemableList =
							new TierStyle().getTierRedeemableListByCode(
								item.min_redeemable_tier_code,
							);

						return (
							<div
								key={item.id}
								onClick={() => onNavigateToIntro(item.exchange_status, item.id)}
								className={classNames(
									"w-1/2 border-b border-l border-[#F9F9F9]",
									{
										[style.mask]: isNotRedeemable,
									},
								)}
							>
								<div className="flex flex-col justify-start space-y-2 p-3 text-black no-underline">
									<div className="m-auto flex size-[43.2vw] cursor-pointer place-content-center items-center">
										<img
											className="w-full"
											style={{ objectFit: "contain" }}
											src={item?.images[0]?.url}
											alt=""
										/>
										<div className="absolute bottom-0 right-0 z-[1] m-[1.5vw] flex align-middle">
											{tierRedeemableList.map((tierStyle) => (
												<img
													key={tierStyle.tierCode}
													className="m-[1vw] w-[4.31vw]"
													src={tierStyle.vipIcon}
													alt=""
												/>
											))}
										</div>
									</div>
									<div className="space-y-6">
										<span className="line-clamp-2 h-10 text-sm">
											{item.display_name}
										</span>
										<span className="block text-right text-xs">{`${formatNumberWithCommas(item.point)}點`}</span>
									</div>
								</div>
								{/* 不能兌換原因 */}
								{isNotRedeemable && (
									<div className="absolute bottom-4 left-4 z-[1] text-sm font-bold text-black">
										{statusText}
									</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default RedeemPage;
