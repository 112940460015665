/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext, useRef } from "react";

import { Icon } from "@/atoms";
import { LiffTab, Popup, LiffAvatar } from "@/components";
import { LiffContext, api } from "@/utils";
import { PointHistoryList, NoRecords } from "./pointHistoryItem/index";

const tabOptions = [
	{ key: "acquired", value: "已獲得" },
	{ key: "used", value: "已使用" },
];

const pointTransactionTypes = {
	100000000: "消費給點",
	100000001: "消費給點",
	100000002: "商店給點",
	100000003: "商店扣點",
	100000004: "兌換禮品",
	100000005: "兌換折價券",
	100000006: "商店給點",
	100000007: "商店扣點",
	100000008: "點數回收",
	100000009: "點數歸還",
	100000010: "點數逾期",
};

const PointHistoryPage = () => {
	const [activeTab, setActiveTab] = useState(tabOptions[0].key);
	const [pointHistoryList, setPointHistoryList] = useState([]);

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);

	// help popup
	const acquiredHelpPopup = useRef();

	// 獲取點數歷程列表
	const getPointHistoryList = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api(10).get("/get_crm_member_point_list", {
				params: { page: 1, pageSize: 50 },
			});
			setPointHistoryList(res.data.rows);
		} catch (error) {
			console.error("Get Point History List Failed", error);
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 篩選已獲得點數
	const acquiredPoints = pointHistoryList.filter(
		(item) => item.operationType === 100000006
			|| item.operationType === 100000000
			|| item.operationType === 100000001
			|| item.operationType === 100000002,
	);

	// 篩選已使用點數
	const usedPoints = pointHistoryList.filter(
		(item) =>
			item.operationType === 100000004
			|| item.operationType === 100000003
			|| item.operationType === 100000005
			|| item.operationType === 100000007
			|| item.operationType === 100000010,
	);

	useEffect(() => {
		getPointHistoryList();
	}, []);

	return (
		<>
			<div className="mb-[75px] flex h-full w-full flex-col">
				{/* Top */}
				<div className="flex w-full flex-none flex-col items-center space-y-4 bg-[#C3C4C5] bg-gradient-to-bl from-[#ffffff80] to-[#00000044] py-6">
					<div className="flex flex-col items-center space-y-1">
						{/* avatar / name */}
						<LiffAvatar></LiffAvatar>
						<span>{liffState.me?.line_name}</span>
					</div>
					<div className="flex w-full divide-x-2 divide-solid divide-gray-300">
						<div className="flex-1 px-2 text-center">
							<div className="flex flex-col items-center">
								<div className="text-lg">
									{liffState?.pointInfo?.availablePoint}
								</div>
								<div className="text-sm">我的點數</div>
							</div>
						</div>
						<div className="flex-1 px-2 text-center">
							<div className="flex flex-col items-center">
								<div className="text-lg">
									{liffState?.pointInfo?.aboutToExpire}
								</div>
								<div className="text-sm">3 個月內即將到期點數</div>
							</div>
						</div>
					</div>
				</div>

				{/* Content */}
				<div className="flex w-full flex-1 flex-col">
					{/* Tab */}
					<LiffTab
						tabStyle="lg"
						options={tabOptions}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						withBorder={false}
					></LiffTab>

					<div className="h-[calc(100vh-315px)] overflow-y-auto">
						{/* List */}
						{/* 已獲得 */}
						{activeTab === "acquired" && (
							<>
								{acquiredPoints.map((item, index) => {
									return (
										<PointHistoryList
											key={index}
											item={item}
											activeTab="acquired"
											title={pointTransactionTypes[item.operationType]}
										></PointHistoryList>
									);
								})}
								{/* 無紀錄顯示 */}
								{acquiredPoints.length === 0 && (
									<NoRecords title="尚未獲得點數" text="立即開始累積點數">
										<Icon
											iconName={"Help"}
											fontSize={20}
											className="text-gray-500"
											onClick={() => acquiredHelpPopup.current.showPopup()}
										></Icon>
									</NoRecords>
								)}
							</>
						)}

						{/* 已使用 */}
						{activeTab === "used" && (
							<>
								{usedPoints.map((item, index) => {
									const title = pointTransactionTypes[item.operationType];
									return (
										<PointHistoryList
											key={index}
											item={item}
											activeTab="used"
											title={title}
										></PointHistoryList>
									);
								})}

								{/* 無紀錄顯示 */}
								{usedPoints.length === 0 && (
									<NoRecords
										title="目前無點數使用紀錄"
										text="點數使用後即會顯示在此"
									>
										<Icon
											iconName={"Help"}
											fontSize={20}
											className="text-gray-500"
											onClick={() => acquiredHelpPopup.current.showPopup()}
										></Icon>
									</NoRecords>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			{/* 已獲得區塊help popup */}
			<Popup
				ref={acquiredHelpPopup}
				title={
					activeTab === "acquired" ? "點數沒有顯示？" : "目前無點數使用紀錄"
				}
				hasBtn={false}
			>
				{activeTab === "acquired" && (
					<span>
						若超過一日仍呈現相關紀錄，請與我們聯繫，我們將協助您確認。
					</span>
				)}
				{activeTab === "used" && (
					<>
						<span>若您剛使用點數，請於數小時後再次確認。</span>
						<span>
							若超過一日仍未呈現相關紀錄，請與我們聯繫，我們將協助您確認。
						</span>
					</>
				)}
			</Popup>
		</>
	);
};

export default PointHistoryPage;
