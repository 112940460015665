import { Link } from "react-router-dom";
import { Icon } from "@/atoms";

const LiffListItemBtn = ({ titleIcon, title, linkUrl }) => {
	return (
		<Link to={linkUrl} className="flex w-full items-center justify-start py-4">
			{/* icon */}
			<div className="mr-4">
				<Icon iconName={titleIcon}></Icon>
			</div>
			{/* title */}
			<div className="my-1 flex">
				<span className="block text-blackT80">{title}</span>
			</div>
			{/* 右箭頭icon */}
			<div className="ml-auto">
				<Icon iconName={"ArrowRight"}></Icon>
			</div>
		</Link>
	);
};

export default LiffListItemBtn;
