import { useState } from "react";

const usePagination = (defaultPageSize, tableName) => {
	const storedPageSize = parseInt(
		localStorage.getItem(`${tableName}_pageSize`),
		10,
	);
	// 每頁行數
	const [pageSize, setPageSize] = useState(
		!isNaN(storedPageSize) ? storedPageSize : defaultPageSize,
	);

	// 當前頁碼，mui的page是從0開始
	const [currentPage, setCurrentPage] = useState(0);
	// 總筆數
	const [totalItems, setTotalItems] = useState(0);

	// 換頁
	const handleChangePage = (newPage) => {
		setCurrentPage(newPage);
	};

	// 換每頁行數
	const handleChangeRowsPerPage = (event) => {
		const newPageSize = parseInt(event.target.value, 10);
		setPageSize(newPageSize);

		localStorage.setItem(`${tableName}_pageSize`, newPageSize);

		setCurrentPage(0);
	};
	return {
		handleChangePage,
		handleChangeRowsPerPage,
		pageSize,
		setPageSize,
		currentPage,
		setCurrentPage,
		totalItems,
		setTotalItems,
	};
};

export default usePagination;
