import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Progress } from "@/components";
import { LiffContext } from "@/utils";
import { useMemberTier } from "@/hooks";
import { TierStyle } from "@/utils/tierStyles";
import Avatar from "@/assets/img/liff/avatar.webp";
import PointIcon from "@/assets/img/liff/level-system/point.png";
import PassLogo from "@/assets/img/liff/mission/TNFPass_logo_white.svg";
import "./index.sass";

const TopMemberInfoBox = ({ tierCode }) => {
	const { renderMemberTierInfo } = useMemberTier();
	const { liffState } = useContext(LiffContext);
	const avatarUrl = liffState.me?.avatar_url || Avatar;
	const availablePoint = liffState.pointInfo?.availablePoint;
	const tierStyle = new TierStyle().getTierStyleByCode(tierCode);
	const navigate = useNavigate();

	const formatNumberWithCommas = (number) => {
		return new Intl.NumberFormat().format(Math.floor(number));
	};

	return (
		<div className="cTopMemberInfoBox">
			<img src={PassLogo} className="pass-logo" alt="" onClick={()=>{navigate('/')}}/>
			<div className="flex justify-between">
				<div
					className="comp-user-avatar"
					style={{ outlineColor: tierStyle.color }}
				>
					<img src={avatarUrl} alt="" />
				</div>
			</div>
			<div className="user-profile">
				<div className="profile-info">
					<img src={tierStyle.vipIcon}></img>
					<label>{tierStyle.tierName}</label>
					<label>{liffState.me?.line_name || ""}</label>
					<button
						onClick={() =>
							liffState.memberBenefitPopup.open(tierStyle.tierCode)
						}
					>
						<span>查看會員權益</span>
						<div className="triangle-right" />
					</button>
				</div>
			</div>
			<div className="progress-bar">
				<Progress
					color={tierStyle.color}
					barColor="rgba(0, 0, 0, 0.3)"
				></Progress>
			</div>
			<div className="member-tier-info">
				<label className="info-message">{renderMemberTierInfo()}</label>
				<div className="member-point">
					<img src={PointIcon}></img>
					<label>點數 : {formatNumberWithCommas(availablePoint)}</label>
				</div>
			</div>
		</div>
	);
};

export { TopMemberInfoBox };
