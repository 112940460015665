/* eslint-disable no-unused-vars */
import axios from "axios";
import axiosRetry from "axios-retry";
import liff from "@line/liff";

import { AppError } from "@/utils";

function createApi(retries = 0, isPrcocessInvalidAppToken = true) {
	// 創建axios
	// eslint-disable-next-line no-undef
	const API_URL = process.env.REACT_APP_API_URL;
	const instance = axios.create({
		baseURL: API_URL,
	});

	const isBackend = () => {
		const path = window.location.pathname;
		return path.startsWith('/admin') || path.includes('/admin/');
	}

	// 設置攔截
	const setupInterceptors = () => {
		// 請求攔截器
		instance.interceptors.request.use(
			(config) => {
				// 在每次請求中帶上token
				const token = localStorage.getItem("api_token");
				if (token) {
					config.headers["Authorization"] = `Bearer ${token}`;
				}

				return config;
			},
			(error) => {
				// Do something with request error
				return Promise.reject(error);
			},
		);

		// 回應攔截器
		instance.interceptors.response.use(
			(res) => {
				// Do something with response data
				return res.data;
			},
			(error) => {
				if (
					error.response &&
					error.response.data.error.code === AppError.INVALID_API_LOGIN_TOKEN
					&& isPrcocessInvalidAppToken
				) {
					// 如果token失效，登出
					// 是否在liff環境中
					localStorage.removeItem("api_token");
					if (liff.isInClient()) {
						liff.logout();
						liff.login({ redirectUri: window.location.href });
					} else {
						if (isBackend()) {
							window.location.href = "/admin";
						} else {
							window.location.href = `https://liff.line.me/${process.env.REACT_APP_LIFF_ID}`;
						}
					}
				}
				return Promise.reject(error);
			},
		);
	};

	setupInterceptors();

	// 設定axios-retry
	if (retries > 0) {
		axiosRetry(instance, {
			retries: retries,
			retryCondition: (error) => {
				return (
					// 在網路或伺服器端或客戶端回報701錯誤CRM_FAILED時retry
					axiosRetry.isNetworkError(error) ||
					(error.response && error.response.status >= 500) ||
					(error.response &&
						error.response.status === 400 &&
						error.response?.data?.error?.code === AppError.CRM_FAILED) ||
					error.response?.data?.error?.code ===
					AppError.FIND_EXCHANGE_REF_ID_ERROR
				);
			},
		});
	}

	return instance;
}

export const api = createApi;
