import { useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import toast from "react-hot-toast";

import { Button, Form, InputDate, Switch } from "@/atoms";
import { AdminPopup, ImageUploader } from "@/components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppError, LiffContext, api } from "@/utils";
import { useFormatAsLocalTime } from "@/hooks";

const ImgCarouselListDetailPage = () => {
	// 預覽圖片
	const [previewUrls, setPreviewUrls] = useState([]);
	// switch開關
	const [switchState, setSwitchState] = useState({
		is_launch: false,
	});
	// 開始時間
	const [startDate, setStartDate] = useState(null);
	// 根據路徑判斷是否為编辑頁
	const [isEditPage, setIsEditPage] = useState(false);
	// id不存在
	const [isError, setIsError] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [imgCarouselData, setImgCarouselData] = useState({
		imgFile: [],
		start_date: null,
		end_date: null,
		is_launch: false,
	});

	// reducer
	const { liffActions, liffState } = useContext(LiffContext);
	const { formatAsLocalTime } = useFormatAsLocalTime();
	const navigate = useNavigate();
	const { bannerId } = useParams();
	const location = useLocation();
	const confirmPopup = useRef(null);
	const editForm = useRef(null);

	// 計算最小可選擇日期
	const getMinDate = (isEditing) => {
		// 如果是編輯頁面，則可以選擇小於等於今天的日期
		return isEditing ? null : dayjs().format("YYYY-MM-DDTHH:mm");
	};

	// 進入編輯頁面，獲取單筆資料
	const getBannerData = async () => {
		liffActions.setShowFullPageLoader(true);
		try {
			const res = await api().get("/admin/get_banner_by_id", {
				params: {
					id: Number(bannerId),
					show_type: "banner",
				},
			});

			const { start_date, end_date, is_launch, images } = res.data;

			setImgCarouselData({
				...res.data,
				start_date: formatAsLocalTime(start_date, "YYYY-MM-DDTHH:mm"),
				end_date: formatAsLocalTime(end_date, "YYYY-MM-DDTHH:mm"),
			});

			// 變更switch狀態
			setSwitchState({
				is_launch: !!is_launch,
			});

			// 預覽圖片
			setPreviewUrls(images);
		} catch (error) {
			console.error("Get Banner Data Failed", error);

			setIsError(true);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.NO_FOUND:
					case AppError.INVALID_FORMAT:
						setIsError(true);
						break;

					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			liffActions.setShowFullPageLoader(false);
		}
	};

	// 上傳圖片
	const uploadImage = async () => {
		// 過濾出新上傳的圖片
		const newImageUpload = previewUrls.filter((file) => !file.id);

		if (newImageUpload.length === 0) {
			return previewUrls[0]?.id;
		}

		const formData = new FormData();
		newImageUpload.forEach((file) => {
			formData.append("image", file.file);
		});

		try {
			const res = await api().post("admin/upload_banner_image", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			const { id } = res.data;

			return id;
		} catch (error) {
			console.error("Upload Image Failed", error);
		}
	};

	// 編輯圖片輪播
	const updateBanner = async (data) => {
		try {
			const res = await api().post("admin/update_banner_by_id", {
				...data,
				id: Number(bannerId),
				show_type: "banner",
			});

			if (res.data === "success") {
				toast.success("更新成功");
				navigate("/admin/displayManagement/imgCarouselList");
			}
		} catch (error) {
			console.error("Update Banner Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	// 新增
	const addNewBanner = async (data) => {
		try {
			const res = await api().post("/admin/add_new_banner", {
				...data,
				show_type: "banner",
			});

			if (res.data === "success") {
				toast.success("新增成功");
				navigate("/admin/displayManagement/imgCarouselList");
			}
		} catch (error) {
			console.error("Add New Banner Failed", error);

			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		}
	};

	// 提交編輯 / 新增
	const submitBannerData = async (data) => {
		const { start_date, end_date, is_launch } = data;
		// 轉換UTC時間
		const formattedStartDate = start_date
			? dayjs(start_date).utc().format()
			: null;
		const formattedEndDate = end_date ? dayjs(end_date).utc().format() : null;

		// 上傳圖片
		const imgId = await uploadImage();

		const formValues = {
			is_launch: !!is_launch,
			start_date: formattedStartDate,
			end_date: formattedEndDate,
			image_id: imgId,
		};

		// 編輯頁面
		if (isEditPage) {
			await updateBanner(formValues);
		} else {
			// 新增頁面
			await addNewBanner(formValues);
		}
	};

	const onSubmit = () => {
		// 圖片未上傳，不可提交
		if (previewUrls.length === 0) {
			return;
		}
		confirmPopup.current.showPopup();
	};

	useEffect(() => {
		const editPage = location.pathname.includes("/edit");
		setIsEditPage(editPage);
	}, [location]);

	useEffect(() => {
		if (isEditPage) {
			getBannerData();
		} else {
			liffActions.setShowFullPageLoader(false);
		}
	}, [isEditPage]);

	useEffect(() => {
		// 如果id不存在，則返回列表頁
		if (isError) {
			navigate("/admin/displayManagement/imgCarouselList");
		}
	}, [isError]);

	useEffect(() => {
		// console.log(switchState);
	}, [switchState]);

	return (
		<>
			{!liffState.showFullPageLoader && (
				<>
					<Form
						onSubmit={onSubmit}
						ref={editForm}
						defaultValues={imgCarouselData}
					>
						<div className="space-y-6 sm:flex sm:flex-col sm:items-center">
							{/* 上傳圖片 */}
							<div className="sm:w-2/3">
								<ImageUploader
									isMultiple={false}
									name="imgFile"
									setPreviewUrls={setPreviewUrls}
									previewUrls={previewUrls}
								></ImageUploader>
							</div>

							{/* 上線日期 */}
							<div className="sm:flex sm:w-2/3 sm:flex-col">
								<span className="mb-2.5 block text-sm font-medium text-[#333333]">
									上線日期
								</span>

								<div className="space-y-3 sm:flex sm:gap-4 sm:space-y-0">
									<Switch
										name="is_launch"
										onChange={(e) =>
											setSwitchState((prevState) => ({
												...prevState,
												is_launch: e.target.checked,
											}))
										}
									></Switch>
									{switchState.is_launch && (
										<>
											{/* 開始日期 */}
											<InputDate
												theme="admin"
												name="start_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												minDate={getMinDate(isEditPage)}
												onChange={(e) => setStartDate(e.target.value)}
											></InputDate>

											{/* 結束日期 */}
											<InputDate
												theme="admin"
												name="end_date"
												required={false}
												type="datetime-local"
												requiredMark={false}
												minDate={startDate || getMinDate(isEditPage)}
											></InputDate>
										</>
									)}
								</div>
							</div>

							{/* Button */}
							<div className="flex justify-end gap-5 sm:w-2/3">
								<Button
									type="button"
									theme="admin"
									borderOnly={true}
									widthFull={false}
									text="返回"
									onClick={() =>
										navigate("/admin/displayManagement/imgCarouselList")
									}
								></Button>
								<Button
									type="submit"
									theme="admin"
									widthFull={false}
									text="儲存"
								></Button>
							</div>
						</div>
					</Form>
					<AdminPopup
						ref={confirmPopup}
						onConfirm={async () => {
							const data = editForm.current.getValues();

							await submitBannerData(data);
						}}
					>
						{isEditPage ? (
							<span className="my-8 block whitespace-pre-wrap text-center">
								{`是否確定要儲存\n此次的更改？`}
							</span>
						) : (
							<span className="my-8 block whitespace-pre-wrap text-center">{`是否確定新增？`}</span>
						)}
					</AdminPopup>
				</>
			)}
		</>
	);
};

export default ImgCarouselListDetailPage;
