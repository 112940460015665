import { memo } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import dayjs from "dayjs";

/**
 * Input
 * @param {string} name
 * @param {string} label 顯示名稱
 * @param { datetime-local | date } type 類型
 * @param {boolean} required 是否必填
 * @param {boolean} isDisabled 禁用
 * @param {boolean} isReadOnly
 * @param {boolean} requiredMark 是否顯示必填＊
 * @param {string} placeholder
 * @param {React.ReactNode} children
 * @param {string} errMsg 沒有label時的錯誤訊息
 * @param {'default' | 'admin'} theme 主題：前台 | 後台
 * @param {string} minDate 最小日期
 * @returns jsx
 */

const InputDate = ({
	name,
	label,
	type,
	required,
	isDisabled,
	isReadOnly,
	requiredMark = true,
	placeholder,
	children,
	errMsg,
	theme = "default",
	minDate,
	onChange,
}) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	const dateFormat = {
		date: "YYYY-MM-DD",
		"datetime-local": "YYYY-MM-DDTHH:mm",
	};

	return (
		<>
			{label && (
				<label
					htmlFor={name}
					className={classNames(
						"mb-2.5 block text-sm font-medium text-[#333333]",
						{
							"text-red-600": !!errors[name]?.message,
						},
					)}
				>
					{label}
					{requiredMark && <span className="text-red-600">*</span>}
				</label>
			)}

			<div>
				<input
					type={type}
					id={name}
					name={name}
					className={classNames(
						{
							"w-full border-2 border-customGray bg-transparent p-3.5 text-sm leading-[14px] selection:bg-transparent focus:border-black focus:outline-none":
								theme === "default",
						},
						{
							"block h-[46px] w-full rounded-lg px-4 py-3 text-sm outline outline-1 outline-customGray focus:outline-blue-500 focus:ring-blue-500":
								theme === "admin",
						},
						{
							"border-red-600  focus:border-red-600":
								!!errors[name]?.message && theme === "default",
						},
						{
							"outline-red-600 focus:outline-red-600":
								!!errors[name]?.message && theme === "admin",
						},
						{
							"text-blackT30": isDisabled,
							"text-blackT30 focus:border-customGray": isReadOnly,
						},
					)}
					placeholder={placeholder}
					readOnly={isReadOnly}
					min={minDate ? dayjs(minDate).format(dateFormat[type]) : null}
					{...register(name, {
						disabled: isDisabled,
						required: {
							value: required,
							message: label ? `${label}為必填` : errMsg,
						},
						onChange: onChange,
					})}
				/>
				{children}
			</div>
			<span className="block text-xs text-red-600">
				{errors[name]?.message}
			</span>
		</>
	);
};

export default memo(InputDate);
