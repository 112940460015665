import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@/atoms";

import HomeLogo from "@/assets/img/liff/tnf-logo.svg";
import Gift from "@/assets/img/liff/gift.svg";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const BindingSuccessPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		// 如果不是從綁定頁面進來的，就導回首頁
		if (!location.state?.fromBinding) {
			navigate("/");
		}
		ReactGA.event('page_binding_success')
	}, []);
	return (
		<>
			<div className="flex h-full w-full flex-col">
				<header className="flex items-center justify-between border-b border-solid border-b-gray-200 p-5">
					<div className="flex-none">
						<p className="text-2xl font-medium">綁定成功</p>
						<p className="text-base text-[#666666]">
							綁定 LINE 官方帳號 享受個人化服務
						</p>
					</div>
					<div className="flex flex-1 items-center pl-4">
						<img src={HomeLogo} alt="" className="h-12 w-16" loading="lazy" />
					</div>
				</header>
				<div className="space-y-10 p-6 py-16">
					<div className="flex flex-col items-center justify-center space-y-8">
						<img src={Gift} alt="" />
						<div className="p-2 text-center">
							<span className="text-lg font-medium">
								完成會員資料，獲得 1,000 會員點數
							</span>
							<br />
							<span>填寫完整資料，會員點數即領即用！</span>
						</div>
						<Button
							type="button"
							text="前往填寫會員資料"
							theme="default"
							color="black"
							onClick={() => navigate("/profile")}
						></Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default BindingSuccessPage;
